import { createTheme } from "@material-ui/core/styles";
import { PaletteColor as DefaultPaletteColor } from "@material-ui/core/styles/createPalette";

const defaultTheme = createTheme();
// TODO  https://stackoverflow.com/questions/61120760/how-to-extend-material-ui-theme-with-typescript
const customTheme = {
  palette: {
    primary: null,
    secondary: null,
    competitors: [
      "#0042ed",
      "#27AE60",
      "#E74C3C",
      "#654321",
      "#9B59B6",
      "#48C9B0",
      "#A93226",
      "#808080",
      "#add8e6",
      "#F1C40F",
      "#E67E22",
      "#234116",
      "#C24E47",
      "#2F3A8E",
      "#C34B75",
      "#D9A18C",
      "#5E3CB4",
    ],
    vpo: [
      "#343090",
      "#5f59f7",
      "#8c61ff",
      "#6592fd",
      "#44c2fd",
      "#48d6d2",
      "#81e9e6",
      "#8dd1e1",
      "#82ca9d",
      "#a4de6c",
       "#ffcb00"
    ],
    brandOrder: [
      "purple",
      "pink",
      "red",
      "blue",
      "teal",
      "seaGreen",
      "green",
      "yellow",
      "orange",
      "gray",
    ],
    charts: {
      purple: [
        "#310c40",
        "#4a135f",
        "#62197f",
        "#7b1f9f",
        "#8835a9",
        "#954cb2",
        "#a362bc",
        "#b079c5",
        "#bd8fcf",
        "#caa5d9",
      ],
      blue: [
        "#001a5f",
        "#00288e",
        "#002ea6",
        "#0042ed",
        "#1a55ef",
        "#3368f1",
        "#4d7bf2",
        "#668ef4",
        "#80a1f6",
        "#99b3f8",
      ],
      teal: [
        "#1c4452",
        "#29677a",
        "#30788f",
        "#3789a3",
        "#3e9ab8",
        "#45abcc",
        "#58b3d1",
        "#6abcd6",
        "#8fcde0",
        "#a2d5e6",
      ],
    },
    black: {
      main: "#040115",
    },
    blue: {
      main: "#0042ED",
      light: "#006aed",
      highlight: "#E5EDFF",
    },
    teal: {
      main: "#45ABCC",
      highlight: "#D3EBF3",
    },
    purple: {
      main: "#7B1F9F",
      highlight: "#F4E1FB",
    },
    red: {
      dark: "#D32E2E",
      main: "#F75050",
      highlight: "#FDCECE",
    },
    pink: {
      main: "#fb8da0",
    },
    yellow: {
      dark: "#DDB400",
      main: "#FFCC33",
      highlight: "#FFF5c8",
    },
    green: {
      dark: "#019704",
      main: "#00BB04",
      highlight: "#EBFFE5",
    },
    seaGreen: {
      main: "#009688",
    },
    orange: {
      main: "#ff9800",
    },
    gray: {
      main: "#8D8D8D",
      dark: "#4D4D4D",
      light: "#E8E8E8",
    },
    neutrals: {
      100: "#FFFFFF",
      96: "#F4F4F4",
      89: "#E0E0E0",
      80: "#CDCDCD",
      75: "#bfbfbf",
      70: "#AFAFAF",
      56: "#8D8D8D",
      43: "#6D6D6D",
      30: "#4D4D4D",
      13: "#222222",
    },
  },
  typography: {
    fontFamily: "\"Open Sans\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightBlack: 900,
    h1: {
      fontWeight: 100,
      fontSize: "96px",
    },
    h2: {
      fontWeight: 100,
      fontSize: "60px",
    },
    h3: {
      fontWeight: 300,
      fontSize: "48px",
    },
    h4: {
      fontWeight: 300,
      fontSize: "34px",
    },
    h5: {
      fontWeight: 300,
      fontSize: "24px",
    },
    h6: {
      fontWeight: 400,
      fontSize: "20px",
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: "16px",
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "14px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 300,
    },
    body2: {
      fontSize: "14px",
      fontWeight: 300,
    },
    caption: {
      fontSize: "12px",
      fontWeight: 300,
    },
  },
  // shadows: [
  //   "none",
  //   "0px 1px 3px 0px #00000033, 0px 2px 1px -1px #0000001F, 0px 1px 1px 0px #00000024",
  //   "0px 4px 2px 0px #3A3A4433, 0px 4px 8px 0px #5A5B6A33",
  //   "0px 4px 8px 0px #3A3A4429, 0px 8px 16px 0px #5A5B6A29",
  //   "0px 8px 16px 0px #3A3A441F, 0px 16px 32px 0px #5A5B6A1F",
  //   "0px 12px 24px 0px #3A3A4414, 0px 24px 48px 0px #5A5B6A14",
  // ],
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: ".875em",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          "0px 1px 3px 0px #00000033, 0px 2px 1px -1px #0000001F, 0px 1px 1px 0px #00000024",
      },
      elevation2: {
        boxShadow: "0px 4px 2px 0px #3A3A4433, 0px 4px 8px 0px #5A5B6A33",
      },
      elevation3: {
        boxShadow: "0px 4px 8px 0px #3A3A4429, 0px 8px 16px 0px #5A5B6A29",
      },
      elevation4: {
        boxShadow: "0px 8px 16px 0px #3A3A441F, 0px 16px 32px 0px #5A5B6A1F",
      },
      elevation5: {
        boxShadow: "0px 12px 24px 0px #3A3A4414, 0px 24px 48px 0px #5A5B6A14",
      },
    },
    MuiSlider: {
      thumb: {
        height: "16px",
        width: "16px",
        marginTop: "-6px",
      },
      rail: {
        color: "#c4c4c4",
        height: "4px",
      },
      track: {
        height: "4px",
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    MuiDrawer: {
      root: {
        zIndex: 1200,
      },
    },
    MuiChip: {
      deletable: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: "0px 8px 16px 0px #3A3A441F, 0px 16px 32px 0px #5A5B6A1F",
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  defaultMargin: {
    margin: defaultTheme.spacing(1, 3),
    [defaultTheme.breakpoints.down("sm")]: {
      margin: defaultTheme.spacing(0.5, 1),
    },
    [defaultTheme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
};

type CustomPalette = {
  competitors: string[],
  brandOrder: string[],
  charts: {
    [key: string]: string[]
  }
  neutrals: Record <number, string>
  blue: DefaultPaletteColor,
  teal: DefaultPaletteColor,
  purple: DefaultPaletteColor,
  red: DefaultPaletteColor,
  pink: DefaultPaletteColor,
  yellow: DefaultPaletteColor,
  green: DefaultPaletteColor,
  seaGreen: DefaultPaletteColor,
  orange: DefaultPaletteColor,
  gray: DefaultPaletteColor
}

type CustomWeights = {
  fontWeightSemiBold: React.CSSProperties["fontWeight"];
  fontWeightBlack: React.CSSProperties["fontWeight"];
}

type cPaletteColor = {
  highlight: string
}

declare module "@material-ui/core/styles/createPalette" {
  type Palette = CustomPalette
  type PaletteColor = cPaletteColor
}

declare module "@material-ui/core/styles/createTypography" {
  type FontStyle = CustomWeights
}

customTheme.palette.primary = customTheme.palette.blue;
customTheme.palette.primary.highlight = customTheme.palette.blue.highlight;
customTheme.palette.secondary = customTheme.palette.purple;
customTheme.palette.secondary.highlight = customTheme.palette.purple.highlight;

export const valorMUITheme = createTheme(customTheme);
export default createTheme(valorMUITheme);
