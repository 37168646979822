/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
// import amplitude from "amplitude-js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  makeStyles, useTheme, Theme, createStyles,
} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Chip,
  Modal,
  Tooltip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoIcon from "@material-ui/icons/Info";
import SearchBar from "../SearchBar";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& .MuiChip-deletable:focus": {
      backgroundColor: "none !important",
    },
  },
  topBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  edit: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    color: theme.palette.neutrals["56"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
  },
  titleIcon: {
    width: "1em",
    height: "1em",
    marginLeft: theme.spacing(1),
    color: theme.palette.neutrals["56"],
  },
  helpText: {
    color: theme.palette.neutrals["30"],
  },
  paper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "640px",
    height: "100%",
    maxHeight: "660px",
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(
      1.5,
    )}px`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    boxShadow: theme.shadows[5],
  },
  upperModalContainer: {
    display: "flex",
    flexDirection: "column",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitle: {
    marginBottom: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
  },
  barContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  helpIcon: {
    height: "13px",
    borderColor: theme.palette.neutrals["89"],
  },
  helpContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  searchBar: {
    width: "70%",
    maxWidth: "400px",
  },
  cancelButton: {
    color: theme.palette.red.main,
  },
  upperKeywordContainer: {
    display: "inline-flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    alignItems: "center",
    color: theme.palette.neutrals["43"],
  },
  modalKeywordContainer: {
    display: "inline-flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    alignItems: "center",
    maxHeight: "440px",
  },
  modalButtonContainer: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "flex-end",
    justifyContent: "flex-end",
    justifySelf: "flex-end",
    width: "100%",
  },
  chips: {
    fontWeight: theme.typography.fontWeightBold,
    userSelect: "none",
  },
  dropContainer: {
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    width: "100%",
    height: "80px",
    padding: theme.spacing(2),
    overflowY: "scroll",
    border: `1px solid ${theme.palette.neutrals["80"]}`,
    borderRadius: theme.spacing(1),
  },
  moreKeywords: {
    color: theme.palette.neutrals["43"],
  },
}));

interface KeywordChipProps {
  type: "bannedKeywords" | "requiredKeywords" | "keywords"
  keyword: string
  handleDelete: (keyword: string, type: string) => void
  hash: string
}
export function KeywordChip(props: KeywordChipProps) {
  const {
    keyword, handleDelete, type, hash,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const style = {
    bannedKeywords: {
      color: theme.palette.neutrals["30"],
      backgroundColor: theme.palette.red.highlight,
      border: `1px solid ${theme.palette.neutrals["80"]}`,
    },
    requiredKeywords: {
      color: theme.palette.neutrals["30"],
      backgroundColor: theme.palette.blue.highlight,
      border: `1px solid ${theme.palette.neutrals["80"]}`,
    },
    keywords: {
      color: theme.palette.neutrals["30"],
      backgroundColor: theme.palette.neutrals["89"],
      border: `1px solid ${theme.palette.neutrals["80"]}`,
    },
  };
  return (
    <Chip
      key={hash}
      variant="outlined"
      className={classes.chips}
      label={keyword}
      style={style[type]}
      onDelete={() => handleDelete && handleDelete(keyword, type)}
    />
  );
}

interface Keywords {
  keywords: string[],
  bannedKeywords: string[],
  requiredKeywords: string[]
}

interface KeywordEditorProps {
  onSubmit: (keyword: Keywords) => void;
  searchKeywords: (query: string) => {keyword: string}[];
  keywords?: Keywords;
}
export function KeywordEditor(props: KeywordEditorProps) {
  const {
    onSubmit,
    keywords = {
      keywords: [],
      bannedKeywords: [],
      requiredKeywords: [],
    },
    searchKeywords,
  } = props;
  const title = "Keywords";
  const [modalKeywords, setModalKeywords] = useState();

  const isSameKeywordList = JSON.stringify(modalKeywords) === JSON.stringify(keywords);
  const [openModal, setModalOpen] = useState(false);
  const classes = useStyles();

  const getTotalKeywords = () => {
    const arrays = Object.keys(keywords)?.map((type) => keywords[type]?.length);

    if (arrays.length) {
      return arrays?.reduce((acc, curr) => acc + curr) || 0;
    }
    return 0;
  };

  const totalKeyWords = getTotalKeywords();

  const handleDelete = (keywordToDelete, type) => () => {
    // amplitude
    // .getInstance()
    // .logEvent("DISCOVER delete keyword", { type, keyword: keywordToDelete });
    setModalKeywords((prevKeywords) => ({
      ...prevKeywords,
      [type]: prevKeywords[type].filter((x) => x !== keywordToDelete),
    }));
  };

  const handleSearch = async (query) => {
    const results = await searchKeywords(query);
    // amplitude.getInstance().logEvent("DISCOVER drag sayt begin", { query });
    const currentKeywords = Object.keys(modalKeywords)
      .map((type) => modalKeywords[type])
      .flat();
    return results
      .filter((x) => currentKeywords.indexOf(x.keyword) === -1)
      .slice(0, 8);
  };

  const handleClearAll = () => {
    // amplitude.getInstance().logEvent("DISCOVER clear all keywords");
    setModalKeywords({
      keywords: [],
      requiredKeywords: [],
      bannedKeywords: [],
    });
  };

  const onOpenModal = () => {
    setModalKeywords(JSON.parse(JSON.stringify(keywords)));
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalKeywords(JSON.parse(JSON.stringify(keywords)));
    setModalOpen(false);
  };

  const submitKeywords = () => {
    onSubmit(modalKeywords);
    setModalOpen(false);
  };

  const onCustomKeyword = (customTerm) => {
    // amplitude
    //   .getInstance()
    //   .logEvent("DISCOVER custom keyword", { keyword: customTerm });
    setModalKeywords((prevState) => ({
      ...prevState,
      keywords: [customTerm, ...prevState.keywords],
    }));
  };
  const onSelectSearchedKeyword = ({ keyword }) => {
    // amplitude.getInstance().logEvent("DISCOVER sayt select", { keyword });
    setModalKeywords((prevState) => ({
      ...prevState,
      keywords: [keyword, ...prevState.keywords],
    }));
  };

  const renderUpperKeywordContainer = () => {
    if (totalKeyWords === 0) return <Typography>No keywords set</Typography>;

    if (totalKeyWords) {
      const renderOrder = ["requiredKeywords", "bannedKeywords", "keywords"];
      const chips = renderOrder
        .map((type) => keywords[type].map((keyword, index) => (
          <KeywordChip
            key={keyword}
            keyword={keyword}
            type={type}
            hash={`${type}${keyword}${index}`}
          />
        )))
        .flat();
      return (
        <>
          {chips.slice(0, 8)}
          {chips.length > 8 && (
            <Typography component="p" className={classes.moreKeywords}>
              +
              {totalKeyWords - 8}
              {" "}
              More
            </Typography>
          )}
        </>
      );
    }
    return null;
  };

  const renderDropAndDrags = (type) => (
    <Droppable droppableId={type} direction="horizontal">
      {(dropProvided) => (
        <Box
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
          className={classes.dropContainer}
        >
          {modalKeywords[type]?.map((keyword, index) => (
            <Draggable key={keyword} draggableId={keyword} index={index}>
              {(dragProvided) => (
                <Box
                  ref={dragProvided.innerRef}
                  {...dragProvided.draggableProps}
                  {...dragProvided.dragHandleProps}
                >
                  <KeywordChip
                    keyword={keyword}
                    handleDelete={handleDelete}
                    type={type}
                  />
                </Box>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </Box>
      )}
    </Droppable>
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const {
      draggableId: word,
      source: { droppableId: source },
      destination: { droppableId: destination, index },
    } = result;

    if (source === destination) {
      return;
    }
    // amplitude
    //   .getInstance()
    //   .logEvent("DISCOVER drag keyword", { source, destination, word });

    setModalKeywords((prevState) => ({
      ...prevState,
      [source]: [...prevState[source]].filter((x) => x !== word),
      [destination]: [
        ...prevState[destination].slice(0, index),
        word,
        ...prevState[destination].slice(index),
      ],
    }));
  };
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.topBox}>
          <Typography className={classes.title}>
            {title.toUpperCase()}
          </Typography>
          <Button
            onClick={onOpenModal}
            className={classes.edit}
            color="primary"
          >
            EDIT
          </Button>
        </Box>
        <Box className={classes.upperKeywordContainer}>
          {renderUpperKeywordContainer()}
        </Box>
      </Box>
      <Modal open={openModal} onClose={onCloseModal} className={classes.modal}>
        <Box className={classes.paper}>
          <Box className={classes.upperModalContainer}>
            <Typography variant="h6" className={classes.modalTitle}>
              Edit
              {" "}
              {title}
            </Typography>
            <Typography className={classes.title}>ADD NEW</Typography>
            <Box className={classes.barContainer}>
              <Box className={classes.searchBar}>
                <SearchBar
                  showSAYT
                  search={handleSearch}
                  entity="keyword"
                  selectTerm={onSelectSearchedKeyword}
                  placeholder="Enter Keywords"
                  onCustomResult={onCustomKeyword}
                  allowCustomResults
                />
              </Box>
              <Button className={classes.cancelButton} onClick={handleClearAll}>
                CLEAR ALL
              </Button>
            </Box>
            <Box className={classes.helpContainer}>
              <InfoOutlinedIcon className={classes.helpIcon} />
              <Typography variant="body2" className={classes.helpText}>
                Drag keywords to change their grouping
              </Typography>
            </Box>
          </Box>
          <Box className={classes.modalKeywordContainer}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Tooltip title="These are the main keywords that will be used to search for other companies">
                <Box display="flex" alignItems="center">
                  <Typography className={classes.title}>
                    STANDARD KEYWORDS
                  </Typography>
                  <InfoIcon className={classes.titleIcon} />
                </Box>
              </Tooltip>
              {renderDropAndDrags("keywords")}
              <Tooltip title="These keywords will be given priority, only companies with one of these keywords will be scored">
                <Box display="flex" alignItems="center">
                  <Typography className={classes.title}>
                    PRIORITY KEYWORDS
                  </Typography>
                  <InfoIcon className={classes.titleIcon} />
                </Box>
              </Tooltip>
              {renderDropAndDrags("requiredKeywords")}
              <Tooltip title="These keywords will be excluded, companies with one of these keywords will not be scored">
                <Box display="flex" alignItems="center">
                  <Typography className={classes.title}>
                    EXCLUDED KEYWORDS
                  </Typography>
                  <InfoIcon className={classes.titleIcon} />
                </Box>
              </Tooltip>
              {renderDropAndDrags("bannedKeywords")}
            </DragDropContext>
          </Box>
          <Box className={classes.modalButtonContainer}>
            <Button color="primary" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={isSameKeywordList}
              onClick={submitKeywords}
            >
              Accept
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default KeywordEditor;
