/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  Grid,
  TextField,
  Theme,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  subSection: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "left",
  },
  text: {
    width: "100%",
    "& > div > textarea": {
      height: theme.spacing(4),
      fontSize: theme.spacing(3),
    },
  },
}));

interface Props {
  questionId: string,
}

export function DollarEstimate(props: Props) {
  const { questionId } = props;
  const [notApplicable, setNotApplicable] = useState(false);
  const classes = useStyles();

  const validator = (value: string) => {
    if (!value) {
      return "Required";
    }
    if (value !== "N/A" && Number.isNaN(Number(value))) {
      return "Invalid Input Numbers / Commas Only unless N/A";
    }
    return undefined;
  };

  const onCheckChange = (event, onChange) => {
    const { checked } = event.target;
    setNotApplicable(checked);
    if (checked) {
      onChange("N/A");
    } else {
      onChange("");
    }
  };

  // returns string with commas and $
  const formatter = (value: string) => {
    if (!value) return "";
    if (value !== "N/A" && !Number.isNaN(Number(value))) {
      return `$${Number(value).toLocaleString("en-US")}`;
    }
    return value;
  };

  // returns without commas or $
  const parser = (value: string) => {
    const parsedValue = value.replaceAll(",", "").replaceAll("$", "");
    if (!parsedValue) {
      return "";
    }
    if (parsedValue === "N/A" || Number.isNaN(Number(parsedValue))) {
      return parsedValue;
    }

    return String(Number(parsedValue));
  };
  return (
    <Grid item xs={12}>
      <Typography className={classes.subSection} variant="body2">
        Numbers and Commas Only
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={8}>
        <Field
          name={`${questionId}.answerData`}
          validate={validator}
          parse={parser}
          format={formatter}
        >
          {({ input, meta }) => (
            <>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={notApplicable}
                      value={notApplicable}
                      onClick={(event) => onCheckChange(event, input.onChange)}
                    />
                  )}
                  label="Not Applicable"
                />
              </FormGroup>
              <TextField
                {...input}
                data-formstate={(meta?.error) ? "error" : ""}
                className={classes.text}
                disabled={notApplicable}
                minRows={1}
                multiline
                // type="number"
                variant="outlined"
                onChange={(event) => input.onChange(event.target.value)}
                error={!!(meta.submitFailed && meta.error)}
                label={meta.submitFailed ? meta.error : ""}
              />
            </>

          )}
        </Field>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.subSection} style={{ height: '42px' }}variant="body2">
            COMMENTS
          </Typography>
          <Field
            name={`${questionId}.commentary`}
          >
            {({ input }) => (
                <TextField
                  {...input}
                  className={classes.text}
                  minRows={1}
                  multiline
                  // type="number"
                  variant="outlined"
                  onChange={(event) => input.onChange(event.target.value)}
                />
            )}
          </Field>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DollarEstimate;
