import React from "react";
import {
  Box, Typography, Theme, createStyles,
} from "@material-ui/core";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  messageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "72px",
    height: "72px",
    margin: theme.spacing(2),
    backgroundColor: theme.palette.neutrals["89"],
    borderRadius: "100%",
  },
  titleText: {
    margin: theme.spacing(1),
    color: theme.palette.neutrals["30"],
    textAlign: "center",
  },
  descriptionText: {
    color: theme.palette.neutrals["30"],
    textAlign: "center",
  },
}));

interface Props {
  Icon?: React.ReactElement;
  title: string;
  message?: React.ReactElement;
}

export function ErrorMessage(props: Props) {
  const { Icon = <PriorityHighIcon />, title, message = null } = props;
  const classes = useStyles();

  return (
    <Box className={classes.messageContainer}>
      <Box className={classes.iconContainer}>{Icon}</Box>
      <Typography className={classes.titleText} variant="h4">
        {title}
      </Typography>
      <Typography className={classes.descriptionText} variant="subtitle1">
        {message}
      </Typography>
    </Box>
  );
}

export default ErrorMessage;
