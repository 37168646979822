import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function ShareIcon() {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M12,1L8,5H11V14H13V5H16M18,23H6C4.89,23 4,22.1 4,21V9A2,2 0 0,1 6,7H9V9H6V21H18V9H15V7H18A2,2 0 0,1 20,9V21A2,2 0 0,1 18,23Z" />
    </SvgIcon>
  );
}

export default ShareIcon;
