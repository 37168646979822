import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {
  Box, Typography, ButtonBase, Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import DownloadMenu from "../../atoms/DownloadMenu";
import SearchBar from "../SearchBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    backgroundColor: "white",
    borderBottom: `1px solid ${theme.palette.neutrals["43"]}`,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  backButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(4),
    padding: theme.spacing(1),
    color: theme.palette.blue.main,
    borderRadius: theme.shape.borderRadius,
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.neutrals["30"],
  },
  iconButtons: {
    height: "36px",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.neutrals["30"],
    border: "1px solid rgb(0,0,0,0.38)",
    borderRadius: theme.shape.borderRadius,
  },
  subTitleBox: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.neutrals["43"],
  },
  metaData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchContainers: {
    display: "flex",
    alignItems: "center",
  },
}));

export function MarketMapHeader(props) {
  const {
    title,
    lastUpdated,
    editor,
    onSearch,
    onSelect,
    exportToExcel,
    exportAsPNG,
    onList,
  } = props;
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  return (
    <Box className={classes.root}>
      <Box display="flex">
        <Box className={classes.titleBox}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Box className={classes.subTitleBox}>
            <Box className={classes.metaData}>
              {lastUpdated && (
                <Typography variant="subtitle1">
                  Last Updated
                  {" "}
                  {lastUpdated.format("MM/DD/YYYY")}
                </Typography>
              )}
              {lastUpdated && editor && bull}
              <Typography variant="subtitle1">{editor}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.searchContainers}>
        <SearchBar
          showSAYT
          search={onSearch}
          entity="company"
          selectTerm={onSelect}
          placeholder="Search in Map"
        />
        <Hidden xsDown>
          {onList && (
            <ButtonBase className={classes.iconButtons} onClick={onList}>
              <ListIcon color="inherit" />
            </ButtonBase>
          )}
          <DownloadMenu options={[{ label: 'Export to Excel', onClick: exportToExcel}, { label: 'Export as PNG', onClick: exportAsPNG}]}/>
        </Hidden>
      </Box>
    </Box>
  );
}

MarketMapHeader.propTypes = {
  title: PropTypes.string.isRequired,
  lastUpdated: PropTypes.instanceOf(dayjs),
  editor: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  exportToExcel: PropTypes.func.isRequired,
  exportAsPNG: PropTypes.func.isRequired,
  onList: PropTypes.func,
};

MarketMapHeader.defaultProps = {
  lastUpdated: dayjs(),
  editor: null,
  onList: null,
};

export default MarketMapHeader;
