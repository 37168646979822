/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme, Theme } from "@material-ui/core/styles";
import { Box, Chip } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle, theme, isHorizontal) => ({
  userSelect: "none",
  padding: theme.spacing(2),
  margin: isHorizontal
    ? `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`
    : `0 0 ${theme.spacing(1)}px 0`,
  // change background colour if dragging
  background: isDragging
    ? theme.palette.primary.main
    : theme.palette.secondary.main,
  ...draggableStyle,
});

const getListStyle = (theme: Theme, isHorizontal: boolean) => ({
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: isHorizontal ? "row" : "column",
  width: "100%",
});

interface ChipItem {
  name?: string,
  id?: string
}

interface Props {
  values: ChipItem[];
  isHorizontal?: boolean;
  onClick?(item: ChipItem): void;
  onFinishDrag?(items: ChipItem[]): void;
  onDelete?(item: ChipItem): void;
}

export function DraggableChipBag(props: Props) {
  const {
    values, isHorizontal, onClick, onFinishDrag, onDelete,
  } = props;
  const [items, setItems] = useState([]);
  const theme = useTheme();

  useEffect(() => setItems(values), [values]);

  const reorder = (list: ChipItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );
    onFinishDrag(reorderedItems);
    setItems(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        direction={isHorizontal ? "horizontal" : "vertical"}
      >
        {(dropProvided) => (
          <Box
            {...dropProvided.droppableProps}
            ref={dropProvided.innerRef}
            display="flex"
            flexDirection="column"
          >
            <Box style={getListStyle(theme, isHorizontal)}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(dragProvided, dragSnapshot) => (
                    <Chip
                      innerRef={dragProvided.innerRef}
                      color="primary"
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      style={getItemStyle(
                        dragSnapshot.isDragging,
                        dragProvided.draggableProps.style,
                        theme,
                        isHorizontal,
                      )}
                      onClick={() => onClick(item)}
                      label={item.name}
                      onDelete={() => onDelete(item.name)}
                    />
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </Box>
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
DraggableChipBag.defaultProps = {
  isHorizontal: false,
  onClick: () => {},
  onFinishDrag: () => {},
  onDelete: null,
};

export default DraggableChipBag;
