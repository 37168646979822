import React from "react";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import startCase from "lodash.startcase";
import MarketMapCell from "../../atoms/MarketMapCell";
import MarketMapItem from "../../atoms/MarketMapItem";

// One day it may make sense to use Recoil.js to manage the highlighting as the size of maps grows.

const useStyles = makeStyles(() => createStyles({
  grid: (props) => {
    const rowLength = props?.row?.values.length;
    const colLength = props?.col?.values.length;
    const calcedExpression = `calc(${
      834 / (props?.row?.values.length || 1)
    }px)`;

    return {
      display: "grid",
      gridTemplateColumns: `32px repeat(${colLength}, 1fr)`,
      gridTemplateRows: `32px repeat(${rowLength}, minmax(0, ${calcedExpression}))`,
    };
  },
  verticalTitle: {
    writingMode: "vertical-lr",
    textAlign: "center",
    transform: "rotate(180deg)",
  },
  root: {
    display: "flex",
    flexDirection: "row",
  },
}));

export function MarketMapGrid(props) {
  const {
    row,
    col,
    memberCells,
    highlightedCompanyId = null,
    setHighlightedCompanyId = null,
    onClick = null,
    style = null,
    id,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const onItemMouseEnter =  (id) => {
    if (id) {
      setHighlightedCompanyId(id);
    }
  };

  const onItemMouseLeave = () => {
    setHighlightedCompanyId(null);
  };

  const getCompaniesForCell = (rowId, colId) => memberCells
    .filter((cell) => cell.rowDimensionValueId === rowId && cell.colDimensionValueId === colId)
    .map((company) => (
      <MarketMapItem
        key={`${company.valorId}-${rowId}-${colId}`}
        onClick={onClick}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
        id={company.valorId}
        logoUrl={company.logoUrl}
        name={company.name}
        deEmphasize={highlightedCompanyId && company.valorId !== highlightedCompanyId}
      />
    ));

  const buildCells = () => row.values.map((rowValue, i) => col.values.map((colValue, j) => (
    <div style={{ gridRow: i + 2, gridColumn: j + 2 }}>
      <MarketMapCell
        key={`${rowValue.id}-${colValue.id}`}
      >
        {getCompaniesForCell(rowValue.id, colValue.id)}
      </MarketMapCell>
    </div>
  )));

  const labelStyles = (gridColumn, gridRow) => ({
    gridColumn,
    gridRow,
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  });

  const buildColumnTitles = () => col.values.map((value, index) => (
    <div
      style={{
        ...labelStyles(index + 2, 1),
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        border: `1px solid ${theme.palette.neutrals["89"]}`,
      }}
      key={value.name}
    >
      <Typography
        style={{ fontWeight: theme.typography.fontWeightBold, whiteSpace: "normal" }}
        variant="h6"
      >
        {value.name}
      </Typography>
    </div>
  ));

  const buildRowTitles = () => row.values.map((value, index) => {
    const baseStyle = {
      ...labelStyles(1, index + 2),
      width: "32px",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      border: `1px solid ${theme.palette.neutrals["89"]}`,
    };
    return (
      <div style={baseStyle} key={value.name}>
        <Typography
          style={{ transform: "rotate(-90deg)", fontWeight: theme.typography.fontWeightBold }}
          variant="h6"
        >
          {value.name}
        </Typography>
      </div>
    );
  });

  const topLeft = () => (
    <div style={{ backgroundColor: "transparent" }} />
  );

  return (
    <div id={id} className={classes.root} style={style ? { width: "1450px" } : {}}>
      <div className={classes.verticalTitle}>
        <Typography variant="h5">
          {startCase(row.name)}
        </Typography>
      </div>
      <Box style={style || { width: "100%" }}>
        <Typography align="center" variant="h5">
          {startCase(col.name)}
        </Typography>
        <Box className={classes.grid}>
          {topLeft()}
          {buildColumnTitles()}
          {buildRowTitles()}
          {buildCells()}
        </Box>
      </Box>
    </div>
  );
}

export default MarketMapGrid;
