/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { useField } from "react-final-form";
import {
  makeStyles, useTheme, Theme, createStyles,
} from "@material-ui/core/styles";
import {
  Typography,
  Slider,
  Input,
  Box,
  Grid,
  TextField,
  Hidden,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
  },
  returnRange: {
    color: theme.palette.neutrals["30"],
  },
  pctInput: {
    width: theme.spacing(5),
    color: theme.palette.neutrals["13"],
  },
  totalBox: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: theme.typography.fontWeightBold,
  },
  subSection: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "left",
  },
  text: {
    width: "100%",
  },
  errorText: {
    color: theme.palette.red.main,
  },
}));

interface SingleDistributionProps {
  name: string,
  value: number,
  setValue: (num: number) => void
}

export function SingleDistribution(props: SingleDistributionProps) {
  const { name, value, setValue } = props;
  const classes = useStyles();
  const [sliderVal, setSliderVal] = useState(value);
  const [inputVal, setInputVal] = useState(`${value}%`);

  const cleanInput = (stringVal) => {
    let val = stringVal.replaceAll("%", "");
    val = val === "" ? "" : Number(val);
    val = Number.isNaN(val) ? 0 : val;
    return val;
  };
  const handleSliderChange = (_, newValue) => {
    setSliderVal(newValue);
    setInputVal(`${newValue}%`);
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const eventVal = event.target.value;
    const val = cleanInput(eventVal);
    if (eventVal.indexOf("%") === -1) {
      setSliderVal(val);
      setInputVal(eventVal);
      return;
    }

    setSliderVal(val);
    setInputVal(val === "" ? "" : `${val}%`);
  };

  const handleBlur = () => {
    const val = cleanInput(inputVal);
    if (val < 0 || val === "") {
      setInputVal("0%");
      setSliderVal(0);
      return setValue(0);
    }
    if (val > 100) {
      setInputVal("100%");
      setSliderVal(100);
      return setValue(100);
    }
    setSliderVal(val);
    setInputVal(`${val}%`);
    return setValue(val);
  };

  return (
    <div style={{ width: "400px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={3}>
          <Typography className={classes.returnRange} gutterBottom>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Input
            className={classes.pctInput}
            value={inputVal}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={6}>
            <Slider
              value={typeof sliderVal === "number" ? sliderVal : 0}
              step={5}
              min={0}
              max={100}
              onChange={handleSliderChange}
            />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

const DEFAULT_DISTRO = {
  "0x": 0,
  "1x": 0,
  "1-2x": 0,
  "2-3x": 0,
  "3-5x": 0,
  "5-7x": 0,
  "7-10x": 0,
  "10x plus": 0,
};

interface Answer {
    "0x"?: number,
    "1x"?: number,
    "1-2x"?: number,
    "2-3x"?: number,
    "3-5x"?: number,
    "5-7x"?: number,
    "7-10x"?: number,
    "10x plus"?: number
  }

interface ReturnDistributionProps {
  questionId: string,
}

export function ReturnDistribution(props: ReturnDistributionProps) {
  const {
    questionId,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const distroValidate = (value) => {
    const obj = JSON.parse(value);
    const total = Object.values(obj).reduce((acc, curr) => acc + curr, 0);
    return total === 100 ? undefined : "Total must equal 100%";
  };

  const { input: distributionInput, meta: distributionMeta } = useField(`${questionId}.answerData`, { initialValue: JSON.stringify(DEFAULT_DISTRO), validate: distroValidate });
  const { input: commentaryInput } = useField(`${questionId}.commentary`);

  const [distributions, setDistributions] = useState<Answer>(
    DEFAULT_DISTRO,
  );
  const sum = Object.values(distributions).reduce((acc, curr) => acc + curr);

  const handleChange = (key, val) => {
    const copy = { ...distributions };
    copy[key] = val;
    setDistributions(copy);
    distributionInput.onChange(JSON.stringify(copy));
  };

  const getSumColor = () => {
    if (sum === 0) {
      return theme.palette.neutrals["30"];
    }
    if (sum < 100) {
      return theme.palette.yellow.main;
    }
    if (sum > 100) {
      return theme.palette.red.main;
    }
    return theme.palette.green.dark;
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} lg={4}>
        <Typography data-formstate={(distributionMeta?.error) ? "error" : ""} className={classes.errorText}>
          {distributionMeta.submitFailed && distributionMeta.error }
        </Typography>
        {Object.entries(distributions).map(([key, val]) => (
          <SingleDistribution
            key={key}
            name={key}
            value={val}
            setValue={(newPct) => handleChange(key, newPct)}
          />
        ))}
        <Box className={classes.totalBox}>
          <Typography
            className={classes.total}
            style={{ color: getSumColor() }}
          >
            TOTAL: &nbsp;
            {sum}
            %
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Typography className={classes.subSection} variant="body2">
          COMMENTS
        </Typography>
        <TextField
          multiline
          className={classes.text}
          maxRows={5}
          rows={2} // Think about this when its all together.
          {...commentaryInput}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

export default ReturnDistribution;
