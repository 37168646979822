/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React from "react";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import WarningIcon from "@material-ui/icons/Warning";
import OrgCard from "../OrgCard";

const colorMap = {
  signal_executive_hire: "green",
  signal_executive_departure: "yellow",
};

const iconMap = {
  signal_executive_hire: CheckCircleOutlineIcon,
  signal_executive_departure: WarningIcon,
};

const useStyles = makeStyles((theme) => ({
  icon: ({ color }) => ({
    height: theme.spacing(5),
    width: theme.spacing(5),
    color: theme.palette[color].main,
  }),
  innerContainer: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
  },
  relabel: {
    alignSelf: "flex-end",
  },
  relabelButton: {
    padding: theme.spacing(0.5),
    borderRadius: "100%",
  },
}));

export function EmployeeSignalCard(props) {
  const {
    signalType,
    additionalInformation,
    canRelabel,
    onRelabel,
    rootStyles,
  } = props;
  const theme = useTheme();
  const color = colorMap[signalType];
  const Icon = iconMap[signalType];
  const classes = useStyles({ color });

  const handleRelabel = (e) => {
    const { title, previousJobTitle } = additionalInformation;
    e.preventDefault();
    onRelabel(title || previousJobTitle);
  };

  const renderHireText = () => {
    const {
      name,
      linkedinUrl,
      hiredDate,
      title,
      previousCompanies,
    } = additionalInformation;

    const noOfPreviousCompanies = previousCompanies.length;
    const mapPreviousCompanies = (
      { previousCompanyValorId: valorId, previousCompanyName: name },
      idx,
    ) => (
      <>
        {valorId ? (
          <a target="_blank" href={`/org/${valorId}`} rel="noreferrer">
            {name}
          </a>
        ) : (
          name
        )}
        {noOfPreviousCompanies > 1 && idx !== noOfPreviousCompanies - 1 && ", "}
      </>
    );
    return (
      <Typography style={{ fontWeight: 400 }}>
        Executive Hire on
        {dayjs(hiredDate).format(" MMM YYYY ")}
        {linkedinUrl ? (
          <a href={linkedinUrl} target="_blank" rel="noreferrer">
            {name}
          </a>
        ) : (
          name
        )}
        {" has joined"}
        {title && ` as ${title}`}
        {noOfPreviousCompanies > 0 && (
        <>
          {" "}
          from a job at
          {" "}
          {previousCompanies.map(mapPreviousCompanies)}
        </>
        )}
        .
      </Typography>
    );
  };

  const renderDepartureText = () => {
    const {
      name,
      linkedinUrl,
      departureDate,
      previousJobTitle,
      currentCompanies,
    } = additionalInformation;
    const noOfCurrentCompanies = currentCompanies?.length;
    const mapCurrentCompanies = (
      {
        currentCompanyValorId: valorId,
        currentCompanyName: name,
        currentCompanyTitle: title,
      },
      idx,
    ) => (
      <>
        {valorId ? (
          <a target="_blank" href={`/org/${valorId}`} rel="noreferrer">
            {name}
          </a>
        ) : (
          name
        )}
        {title && ` as ${title}`}
        {noOfCurrentCompanies > 1 && idx !== noOfCurrentCompanies - 1 && ","}
      </>
    );
    return (
      <Typography style={{ fontWeight: 400 }}>
        Executive Departure on
        {dayjs(departureDate).format(" MMM YYYY ")}
        {linkedinUrl ? (
          <a href={linkedinUrl} target="_blank" rel="noreferrer">
            {name}
          </a>
        ) : (
          name
        )}
        {" has left"}
        {previousJobTitle && ` their job as ${previousJobTitle}`}
        {noOfCurrentCompanies > 0 && (
        <>
          {" for a job at "}
          {currentCompanies.map(mapCurrentCompanies)}
        </>
        )}
        .
      </Typography>
    );
  };

  const defaultStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette[color].main}`,
  };

  return (
    <OrgCard style={{ ...rootStyles, ...defaultStyles }}>
      <Box className={classes.innerContainer}>
        <Box>
          <Icon className={classes.icon} />
        </Box>
        <Box width="100%">
          {signalType === "signal_executive_hire" && renderHireText()}
          {signalType === "signal_executive_departure"
            && renderDepartureText()}
        </Box>
        {canRelabel && (
          <Box className={classes.relabel}>
            <ButtonBase
              className={classes.relabelButton}
              onClick={handleRelabel}
            >
              <EditIcon />
            </ButtonBase>
          </Box>
        )}
      </Box>
    </OrgCard>
  );
}

export default EmployeeSignalCard;
