import React from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  redOnHover: {
    "&:hover": {
      fill: "red",
    },
  },
}));

export function RemoveButton({ title, onClick, style }) {
  const classes = useStyles();

  return (
    <Tooltip title={title} placement="bottom" style={style}>
      <IconButton onClick={onClick}>
        <HighlightOffIcon className={classes.redOnHover} />
      </IconButton>
    </Tooltip>
  );
}

RemoveButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

RemoveButton.defaultProps = {
  onClick: () => {},
  title: "Remove",
  style: {},
};

export default RemoveButton;
