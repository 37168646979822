import React from "react";
import axios from "axios";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import LogRocket from "logrocket";
import { ProvideAuth } from "./hooks/useAuth";
import AuthApp from "./AuthApp";

LogRocket.init("wb5g1h/pofoco");

const generateClassName = createGenerateClassName({
  seed: "Insights",
});

const queryClient = new QueryClient();

axios.defaults.headers.post["Content-Type"] = "application/json";

function App() {
  return (
    <Router>
      <StylesProvider generateClassName={generateClassName}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ProvideAuth>
            <QueryClientProvider client={queryClient}>
              <AuthApp />
            </QueryClientProvider>
          </ProvideAuth>
        </QueryParamProvider>
      </StylesProvider>
    </Router>
  );
}

export default App;
