export const config = {
  api: {
    ingress: process.env.REACT_APP_API_INGRESS,
  },
  firebase: {
    apiKey: process.env.REACT_APP_FBASE_apiKey,
    authDomain: process.env.REACT_APP_FBASE_authDomain,
    databaseURL: process.env.REACT_APP_FBASE_databaseURL,
    projectId: process.env.REACT_APP_FBASE_projectId,
    storageBucket: process.env.REACT_APP_FBASE_storageBucket,
    messagingSenderId: process.env.REACT_APP_FBASE_messagingSenderId,
    appId: process.env.REACT_APP_FBASE_appId,
  },
  captcha: {
    siteKey: process.env.REACT_APP_CAPTCHA_siteKey,
  },
};
export default config;
