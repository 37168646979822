/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, Grid, TextField, Theme, createStyles,
} from "@material-ui/core";
import FrameworkRadio from "../../atoms/FrameworkRadio";

const useStyles = makeStyles((theme: Theme) => createStyles({
  subSection: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "left",
  },
  text: {
    width: "100%",
  },
  errorText: {
    color: theme.palette.red.main,
  },
}));

const colorToIndex = {
  blue: 0,
  green: 1,
  yellow: 2,
  red: 3,
  gray: 4,
};

interface Props {
  questionId: string,
  commentRequired: boolean,
  showComment: boolean,
  choices: {
      color: string,
      id: string,
      choiceText: string,
      commentRequired: boolean,
  }[];
}

const choiceValidator = (value: string) => {
  if (!value) {
    return "Required";
  }
  return undefined;
};

const commentValidator = (
  value: string,
  questionRequiresComment: boolean,
  choiceNecessitatesComment: boolean,
) => {
  if (!value) {
    if (questionRequiresComment) {
      return "This question requires a comment";
    }
    if (choiceNecessitatesComment) {
      return "Your choice for this question requires a comment";
    }
  }
  return undefined;
};

export function MultiChoice(props: Props) {
  const {
    questionId,
    commentRequired: questionRequiresComment,
    showComment,
  } = props;

  const [
    choiceNecessitatesComment,
    setChoiceNecessitatesComment,
  ] = useState<(boolean) >(false);

  const {
    input: choiceInput,
    meta: choiceMeta,
  } = useField(`${questionId}.choiceId`, { validate: choiceValidator });
  const {
    input: commentaryInput,
    meta: commentaryMeta,
  } = useField(`${questionId}.commentary`, { validate: (value) => commentValidator(value, questionRequiresComment, choiceNecessitatesComment) });

  let { choices } = props;
  useEffect(() => {
    if (!questionRequiresComment) {
      const choiceCommentRequired = choices
        .find((c) => c.id === choiceInput?.value)
        ?.commentRequired ?? false;
      setChoiceNecessitatesComment(choiceCommentRequired);
    }
  }, [choices, choiceInput?.value, questionRequiresComment]);

  const classes = useStyles();
  // Temporary hack until order is added for choices in the db
  choices = choices.sort((a, b) => colorToIndex[a.color] - colorToIndex[b.color]);
  return (
    <>
      <Typography data-formstate={(choiceMeta?.error) ? "error" : ""} className={classes.errorText}>
        {/* {JSON.stringify(choiceInput)}
        {JSON.stringify(choiceMeta)} */}
        {choiceMeta.submitFailed && choiceMeta.error}
      </Typography>
      <Grid container spacing={1}>
        {choices?.map((choice) => (
          <Grid key={choice.id} item xs={12} lg={(12 / choices.length ?? 1)}>
            <FrameworkRadio
              isActive={choiceInput.value === choice.id}
              onClick={() => (choiceInput.onChange(choice.id))}
              color={choice.color}
              text={choice.choiceText}
            />
          </Grid>
        ))}
      </Grid>
      {!showComment && (
      <>
        <Typography className={classes.subSection} variant="body2">
          COMMENTS
          {" "}
          {Boolean(questionRequiresComment || choiceNecessitatesComment) && "REQUIRED" }
        </Typography>
        <TextField
          multiline
          className={classes.text}
          maxRows={5}
          rows={2}
          data-formstate={(commentaryMeta?.error) ? "error" : ""}
          error={!!(commentaryMeta.submitFailed && commentaryMeta.error)}
          label={commentaryMeta.submitFailed ? commentaryMeta.error : ""}
          {...commentaryInput}
          variant="outlined"
        />
      </>
      )}
    </>
  );
}

export default MultiChoice;
