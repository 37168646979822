/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { addDays, format } from "date-fns";
import {
  Box, useTheme, TextField, Popover, makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import InputAdornment from "@material-ui/core/InputAdornment";
import TodayIcon from "@material-ui/icons/Today";

const today = new Date(new Date().setHours(0, 0, 0, 0));

const staticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: today,
      endDate: new Date(),
    }),
  },
  {
    label: "Last 7 days",
    range: () => ({
      startDate: addDays(today, -6),
      endDate: new Date(),
    }),
  },

  {
    label: "Last 30 days",
    range: () => ({
      startDate: addDays(today, -29),
      endDate: new Date(),
    }),
  },
  {
    label: "Last 90 days",
    range: () => ({
      startDate: addDays(today, -89),
      endDate: new Date(),
    }),
  },
  {
    label: "All Time",
    range: () => ({
      startDate: null,
      endDate: null,
    }),
  },
]);

const useStyles = makeStyles({
  underline: {
    "&:before": {
      borderBottom: (props) => `1px solid ${props.color}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: (props) => `2px solid ${props.color}`,
    },
    "&:after": {
      borderBottom: (props) => `2px solid ${props.color}`,
    },
  },
  disabled: {},
  focused: {},
  error: {},
});

export function DateRangeFilter({
  filterValue = {},
  setFilter,
  color,
}) {
  const theme = useTheme();
  const classes = useStyles({ color });

  const anchorRef = React.useRef();
  const [openPopOver, setOpenPopover] = useState(false);
  const isStartDateEmpty = !("startDate" in filterValue) || filterValue.startDate === null;
  const isEndDateEmpty = !("endDate" in filterValue) || filterValue.endDate === null;

  const getLabel = () => {
    if (isStartDateEmpty && isEndDateEmpty) {
      return "All Time";
    }
    if (isStartDateEmpty) {
      return `Before ${format(filterValue.endDate, "MMM dd, yyyy")}`;
    }
    if (isEndDateEmpty) {
      return `After ${format(filterValue.startDate, "MMM dd, yyyy")}`;
    }

    return `${format(filterValue.startDate, "MMM dd, yyyy")} - ${format(filterValue.endDate, "MMM dd, yyyy")}`;
  };

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={() => setOpenPopover(true)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        flex={1}
      >
        <TextField
          style={{ flex: 1 }}
          id="date-text-field"
          value={getLabel()}
          InputProps={{
            classes,
            style: {
              // maxWidth: "260px",
              width: "100%",
              color,
            },
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Popover
        open={openPopOver}
        anchorEl={anchorRef.current}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock
      >
        <DateRangePicker
          staticRanges={staticRanges}
          inputRanges={[]}
          onChange={(item) => {
            setFilter(item.selection);
          }}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[{ ...filterValue, key: "selection", color: theme.palette.blue.main }]}
          direction="horizontal"
        />
      </Popover>
    </>
  );
}

DateRangeFilter.propTypes = {
  color: PropTypes.string,
  filterValue: PropTypes.shape(
    {
      startDate: PropTypes.Date,
      endDate: PropTypes.Date,
    },
  ),
  setFilter: PropTypes.func.isRequired,
};

DateRangeFilter.defaultProps = {
  color: "#000",
};

export default DateRangeFilter;
