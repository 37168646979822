/* eslint-disable react/prop-types */
import React from "react";
import { useQuery } from "react-query";
import { NewsArticle, CenteredCircularProgress, TitledOrgCard } from "@valor-labs/marble";
import { getNewsArticles } from "../../../api/Pofoco";

export function CompanyNewsCard({ valorId }) {
  const { data: newsArticles = [], isLoading } = useQuery(
    ["NewsArticles", valorId],
    async () => {
      const response = await getNewsArticles(valorId);
      return response;
    },
    {
      retry: 1,
      enabled: Boolean(valorId),
    },
  );

  if (!isLoading && newsArticles.length === 0) {
    return null;
  }
  return (
    <TitledOrgCard title="Recent News">
      <CenteredCircularProgress isLoading={isLoading}>
        {newsArticles.map((article) => (
          <NewsArticle
            key={article.articleDid}
            title={article.title}
            imageUrl={article.imageUrl}
            pageUrl={article.pageUrl}
            siteName={article.siteName}
            articleTimestamp={article.articleTimestamp}
            logoUrl={article.logoUrl}
            name={article.name}
            valorId={article.valorId}
            backupImage={`${process.env.PUBLIC_URL}/images/news-backup.png`}
            showNewsImage
          />
        ))}
      </CenteredCircularProgress>
    </TitledOrgCard>
  );
}

export default CompanyNewsCard;
