/* eslint-disable no-console */
import axios from "axios";

export const getUserData = async () => {
  try {
    const response = await axios.get(
      "/api/pofoco/user",
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompetitors = async (valorId) => {
  try {
    const response = await axios.get(
      `/api/pofoco/competitors/${valorId}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompetitorTimeData = async (valorId) => {
  try {
    const response = await axios.get(`/api/pofoco/histories/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getNewsArticles = async (valorId) => {
  try {
    const response = await axios.get(`/api/pofoco/news/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOrgTalentSummary = async (valorId) => {
  try {
    const response = await axios.get(`/api/pofoco/org-summary/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOrgRecentHiresByCategory = async (valorId, category) => {
  try {
    const response = await axios.get(
      `/api/pofoco/recent-hires/${valorId}/${category}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getOrg = async (valorId) => {
  try {
    const response = await axios.get(`/api/pofoco/org/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const pofoco = {
  getUserData,
  getCompetitors,
  getCompetitorTimeData,
  getNewsArticles,
  getOrgTalentSummary,
  getOrgRecentHiresByCategory,
  getOrg,
};
export default pofoco;
