import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box, Typography, Theme, createStyles,
} from "@material-ui/core";
import { FrameworkChoiceColorIconMapping } from "../../../constants/FrameworkChoiceColorIconMapping";

interface Props {
  color: "green" | "yellow" | "red" | "gray" | "blue";
  isActive?: boolean;
  onClick(...args: unknown[]): void;
  text: string;
  questionType?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: ({ color, isActive }: Props) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.only("xl")]: {
      height: theme.spacing(10),
    },
    [theme.breakpoints.only("lg")]: {
      height: theme.spacing(15),
    },
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(18),
      padding: theme.spacing(1),
    },
    justifyContent: "flex-start",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.neutrals["100"],
    border: isActive
      ? `2px solid ${theme.palette[color].main}`
      : `1px solid ${theme.palette.neutrals["89"]}`,
    margin: isActive && -1,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
  }),
  iconContainer: ({ color }: Props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: theme.spacing(2),
    color: theme.palette[color].main,
  }),
  icon: {
    width: ".75em",
    height: ".75em",
  },
  questionText: ({ color, isActive }: Props) => ({
    color: isActive ? theme.palette[color].main : theme.palette.neutrals["30"],
    fontWeight: isActive
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular,
    userSelect: "none",
    fontSize: "1.2em",
    textAlign: "start",
  }),
  textContainer: {
    "& p::before": {
      height: 0,
      overflow: "hidden",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "1.2rem",
      visibility: "hidden",
    },
  },
}));

export function FrameworkRadio({
  color, isActive = false, onClick, text, questionType = "MULTIPLE_CHOICE",
}: Props) {
  const classes = useStyles({ color, isActive, questionType });
  const Icon = FrameworkChoiceColorIconMapping[color];
  return (
    <button onClick={onClick} value={isActive} type="button" className={classes.container}>
      <Box className={classes.iconContainer}>
        <Icon className={classes.icon} />
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.questionText}>{text}</Typography>
      </Box>
    </button>
  );
}

export default FrameworkRadio;
