/* eslint-disable react/prop-types */
import React from "react";
import {
  Grid,
  Box,
  Typography,
  makeStyles,
  Tooltip,
  Chip,
} from "@material-ui/core";
import {
  KPICard, TitledOrgCard, CenteredCircularProgress,
  get3MonthGrowth,
  getLastValue,
  getYoYGrowth,
  formatTotal,
  formatShrunkTotal,
  formatFloat,
  formatPercentage,
  formatBigDollars,
} from "@valor-labs/marble";
import kebabCase from "lodash.kebabcase";
import zip from "lodash.zip";

const useStyles = makeStyles((theme) => ({
  section: {
    border: `3px solid ${theme.palette.primary.main}`,
    boxShadow: "0 0px 24px 24px rgba(0,0,0,.1)",
  },
}));

export function CompanyStatsCard({ company, sectionHash, clickedAnywhere }) {
  const classes = useStyles();

  const renderAppAnnieStats = () => {
    const stats = [
      {
        title: "Downloads",
        Android: "androidDownloads",
        iOS: "iosDownloads",
        dataFormatter: formatShrunkTotal,
      },
      {
        title: "Monthly Active Users",
        Android: "androidMau",
        iOS: "iosMau",
        dataFormatter: formatShrunkTotal,
      },
      {
        title: "Estimated App Revenue",
        Android: "androidRevenueDollars",
        iOS: "iosRevenueDollars",
        dataFormatter: formatBigDollars,
      },
    ];

    const cards = stats
      .map(({
        Android, iOS, dataFormatter, title,
      }) => {
        let androidData = [];
        if (!company[Android]?.every((x) => x.value === null)) {
          androidData = company[Android]?.map((x) => ({
            timestamp: x.timestamp,
            Android: x.value,
          }));
        }

        let iosData = [];
        if (!company[iOS]?.every((x) => x.value === null)) {
          iosData = company[iOS]?.map((x) => ({
            timestamp: x.timestamp,
            iOS: x.value,
          }));
        }

        const trendline = zip(androidData, iosData).map((x) => x.reduce((acc, curr) => ({ ...acc, ...curr }), {}));
        if (
          !trendline.length
          || trendline?.every((x) => x.iOS === null && x.Android === null)
        ) {
          return null;
        }
        const last = trendline[trendline.length - 1];
        const metrics = [];
        if (last.Android) {
          metrics.push({
            label: "Android",
            value: dataFormatter(last.Android),
          });
        }
        if (last.iOS) {
          metrics.push({
            label: "iOS",
            value: dataFormatter(last.iOS),
          });
        }
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={title}>
            <KPICard
              dataFormatter={dataFormatter}
              title={title}
              metrics={metrics}
              data={trendline || []}
              keys={["Android", "iOS"]}
            />
          </Grid>
        );
      })
      .filter((x) => x);

    const title = "Mobile App Data";
    const subtitle = "Sourced from AppAnnie";
    const kebabedTitle = kebabCase(title);

    if (!cards.length) {
      return null;
    }
    return (
      <Grid
        id={kebabedTitle}
        className={
          sectionHash === `#${kebabedTitle}` && !clickedAnywhere
            ? classes.section
            : ""
        }
        spacing={4}
        container
      >
        <Grid xs={12} item>
          <Box display="flex" alignItems="center">
            <Typography variant="h6">{title}</Typography>
          </Box>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Grid>
        {cards}
      </Grid>
    );
  };
  const renderStaticStatsCardContent = () => {
    const staticSchema = [
      {
        sectionTitle: "Team Size and Hiring",
        subtitle: () => "Sourced from Linkedin",
        stats: [
          {
            title: "Employees",
            metrics: [
              {
                label: "Total",
                getter: getLastValue,
                formatter: formatShrunkTotal,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "employeeTotalHistory",
            dataFormatter: formatTotal,
          },
          {
            title: "Job Postings",
            metrics: [
              {
                label: "Total",
                getter: getLastValue,
                formatter: formatShrunkTotal,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "jobTotalHistory",
            dataFormatter: formatTotal,
          },
        ],
      },
      {
        sectionTitle: "Website Traffic",
        subtitle: (co) => {
          const { trafficDomain } = co;
          if (trafficDomain) {
            return (
              <Box>
                <Tooltip title="This domain may be different from the primary domain above. If you think another domain should be tracked please contact the Labs team">
                  <Chip
                    style={{ marginRight: "8px" }}
                    color="secondary"
                    variant="outlined"
                    label={trafficDomain}
                  />
                </Tooltip>
                <Typography variant="subtitle2">
                  Sourced from Similarweb
                </Typography>
              </Box>
            );
          }
          return null;
        },
        alwaysRenderCharts: true,
        dataFetchedAccessor: "trafficDomain",
        renderNoData: () => {
          <CenteredCircularProgress />;
        },
        stats: [
          {
            title: "Web",
            metrics: [
              {
                label: "Total",
                getter: getLastValue,
                formatter: formatShrunkTotal,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "webTrafficHistory",
            dataFormatter: formatTotal,
          },
          {
            title: "Social Referrals to Site",
            metrics: [
              {
                label: "Total",
                getter: getLastValue,
                formatter: formatShrunkTotal,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "socialTrafficHistory",
            dataFormatter: formatTotal,
          },
        ],
      },
      {
        sectionTitle: "Consumer Transactions",
        subtitle: () => "Sourced from SecondMeasure",
        stats: [
          {
            title: "Observed Sales",
            subtitle: "Based on a sample of credit card data",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: formatBigDollars,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "observedSalesHistory",
            dataFormatter: formatBigDollars,
          },
          {
            title: "Observed Transactions",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: formatShrunkTotal,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "observedTransactionsHistory",
            dataFormatter: formatTotal,
          },
          {
            title: "Observed Customers",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: formatShrunkTotal,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "observedCustomersHistory",
            dataFormatter: formatTotal,
          },

          {
            title: "Sales Per Customers",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: formatBigDollars,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "salesPerCustomerHistory",
            dataFormatter: (value) => formatBigDollars(value),
          },
          {
            title: "Transactions Per Customers",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: (value) => formatFloat(value),
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "txnsPerCustomerHistory",
            dataFormatter: (value) => formatFloat(value),
          },
          {
            title: "Average Transaction Value",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: formatBigDollars,
              },
              {
                label: "3Mo",
                getter: get3MonthGrowth,
                formatter: formatPercentage,
              },
              {
                label: "YoY",
                getter: getYoYGrowth,
                formatter: formatPercentage,
              },
            ],
            data: "avgTxnValueHistory",
            dataFormatter: formatBigDollars,
          },
          {
            title: "Retention",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: formatPercentage,
              },
            ],
            data: "retentionHistory",
            dataFormatter: (value) => formatPercentage(value),
          },
          {
            title: "New Customers",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: (value) => formatPercentage(value, 4),
              },
            ],
            data: "newCustomersHistory",
            dataFormatter: (value) => formatPercentage(value, 4),
          },
          {
            title: "Customer as % of Pop.",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: (value) => formatPercentage(value, 4),
              },
            ],
            data: "customerPctHistory",
            dataFormatter: (value) => formatPercentage(value, 6),
          },
          {
            title: "Lifetime Customer as % of Pop.",
            metrics: [
              {
                label: "Current",
                getter: getLastValue,
                formatter: (value) => formatPercentage(value, 4),
              },
            ],
            data: "lifetimeCustomerPctHistory",
            dataFormatter: (value) => formatPercentage(value, 6),
          },
        ],
      },
    ];

    const sections = staticSchema.map(
      ({
        sectionTitle,
        stats,
        subtitle,
        alwaysRenderCharts = false,
        dataFetchedAccessor,
        renderNoData,
      }) => {
        const cards = stats.map(({
          title, subtitle: cardSubtitle, metrics, data, dataFormatter,
        }) => {
          const KPIs = [];
          const trendline = company[data];

          const noTrendline = trendline === null || trendline === undefined || trendline.length === 0;

          if (!alwaysRenderCharts && noTrendline) {
            return null;
          }
          metrics?.forEach(({ label, getter, formatter }) => {
            const metricValue = getter(trendline);
            if (metricValue) {
              KPIs.push({ label, value: formatter(metricValue) });
            }
          });
          return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={title}>
              <KPICard
                dataFormatter={dataFormatter}
                title={title}
                subtitle={cardSubtitle}
                metrics={KPIs}
                data={trendline || []}
                keys={[data]}
              />
            </Grid>
          );
        });
        if (cards.every((x) => x === null)) {
          return null;
        }
        const kebabedTitle = kebabCase(sectionTitle);
        return (
          <Grid
            key={kebabedTitle}
            id={kebabedTitle}
            className={
              sectionHash === `#${kebabedTitle}` && !clickedAnywhere
                ? classes.section
                : ""
            }
            spacing={4}
            container
          >
            <Grid xs={12} item>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">{sectionTitle}</Typography>
                {dataFetchedAccessor !== undefined
                  && !company[dataFetchedAccessor]
                  && renderNoData()}
              </Box>
              {subtitle && (
                <Typography variant="subtitle2">{subtitle(company)}</Typography>
              )}
            </Grid>
            {cards}
          </Grid>
        );
      },
    );
    return sections;
  };

  return (
    ([
      company.employeeTotalHistory,
      company.retentionHistory,
      company.webTrafficHistory,
    ].some((exist) => exist)
      || !company.trafficDomain) && (
      <TitledOrgCard title="Stats">
        {renderStaticStatsCardContent()}
        {renderAppAnnieStats()}
      </TitledOrgCard>
    )
  );
}

export default CompanyStatsCard;
