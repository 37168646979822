import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function Affinity() {
  return (
    <SvgIcon>
      <path d="M18.8,12.47a1.73,1.73,0,0,0,0-.9l.87-.87a4.47,4.47,0,0,0,0-6.33h0a4.45,4.45,0,0,0-6.33,0l-.79.78A1.91,1.91,0,0,0,12,5.06a2,2,0,0,0-.55.09l-.78-.78a4.45,4.45,0,0,0-6.33,0h0a4.47,4.47,0,0,0,0,6.33l1,1a1.36,1.36,0,0,0,0,.29,2,2,0,0,0,0,.35l-1,1a4.47,4.47,0,0,0,0,6.33h0a4.48,4.48,0,0,0,6.33,0l.89-.89a2,2,0,0,0,.44.06,2.12,2.12,0,0,0,.44-.06l.89.89a4.48,4.48,0,0,0,6.33,0h0a4.47,4.47,0,0,0,0-6.33Zm-6.25,3a1.82,1.82,0,0,0-.52-.08,1.77,1.77,0,0,0-.52.08l-2.8-2.8A1.75,1.75,0,0,0,8.83,12a1.72,1.72,0,0,0-.1-.6L11.62,8.5a1.35,1.35,0,0,0,.41.06,1.4,1.4,0,0,0,.41-.06l3,3a1.51,1.51,0,0,0-.07.48,1.91,1.91,0,0,0,.08.54Zm4-10.69a2.78,2.78,0,0,1,2,4.75l-.83.83a1.61,1.61,0,0,0-.54-.09,2,2,0,0,0-.48.07l-3-3a1.91,1.91,0,0,0,.08-.54,1.84,1.84,0,0,0,0-.41l.84-.84A2.76,2.76,0,0,1,16.52,4.75Zm-11,.81a2.8,2.8,0,0,1,3.94,0l.83.84a1.84,1.84,0,0,0-.05.41,1.61,1.61,0,0,0,.09.54L7.44,10.28a2,2,0,0,0-.35,0,1.75,1.75,0,0,0-.65.13L5.56,9.5A2.8,2.8,0,0,1,5.56,5.56Zm2,13.75a2.79,2.79,0,0,1-2-4.75l.93-.93a2,2,0,0,0,.6.1l.29,0,3,3a1.61,1.61,0,0,0-.06.44,1.77,1.77,0,0,0,.08.52l-.86.86A2.77,2.77,0,0,1,7.53,19.31Zm11-.82a2.76,2.76,0,0,1-3.93,0l-.87-.86a1.49,1.49,0,0,0,.08-.52,1.61,1.61,0,0,0-.06-.44l3-3a2,2,0,0,0,.42.05,1.86,1.86,0,0,0,.48-.06l.89.89A2.77,2.77,0,0,1,18.49,18.49Z" />
    </SvgIcon>
  );
}

export default Affinity;
