import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  ButtonBase,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import kebabCase from "lodash.kebabcase";
import { CompanyAvatar, ResourceLinks } from "@valor-labs/marble";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    width: "100%",
    backgroundColor: theme.palette.neutrals["100"],
  },
  interiorContainer: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    fontSize: "2.25rem"
  },
  companyLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(16),
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: theme.spacing(14),
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: theme.spacing(8),
    },
  },
  logoContainer: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-start",
  },
  tabContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  shareButton: {
    width: theme.spacing(5),
    padding: theme.spacing(0, 1.5),
    color: theme.palette.neutrals["30"],
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
  },
  actionButton: {
    width: theme.spacing(20),
    padding: theme.spacing(0, 1.5),
    color: theme.palette.neutrals["30"],
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  tab: {
    height: theme.spacing(4),
    padding: theme.spacing(2, 0),
    color: theme.palette.neutrals["30"],
  },
  tabLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "14px",
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export function OrgHeader(props) {
  const {
    company,
    currentTab,
    onTabChange,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const isMdDown = useMediaQuery((t) => t.breakpoints.down("md"));
  const isXsDown = useMediaQuery((t) => t.breakpoints.down("xs"));

  const activeTabMapping = {
    summary: "summary",
    employees: "employees",
  };

  const onTabClick = (e, label) => {
    const kebabedLabel = kebabCase(label);
    onTabChange(kebabedLabel);
  };

  
  const tabButtons = Object.keys(activeTabMapping).map((label) => {
    const currentTabStyle = {
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    };
    const kebabedLabel = kebabCase(label);
    return (
      <ButtonBase
        id={kebabedLabel}
        key={label}
        className={classes.tab}
        style={
          kebabedLabel === activeTabMapping[currentTab] ? currentTabStyle : {}
        }
        onClick={(e) => onTabClick(e, label)}
      >
        <Typography component="p" className={classes.tabLabel}>
          {label.toUpperCase()}
        </Typography>
      </ButtonBase>
    );
  });

  const companyLogo = (
    <Box className={classes.companyLogo}>
      <CompanyAvatar src={company.logoUrl} name={company.name} />
    </Box>
  );

  const resources = (
    <Box display="flex">
      <ResourceLinks
        linkedin={company.linkedinUrl}
        crunchbase={company.cbId}
        pitchbook={company.pbId}
      />
    </Box>
  );

  if (isXsDown) {
    return (
      <Box className={classes.root}>
        <Box className={classes.interiorContainer}>
          <Box className={classes.logoContainer} height="80px">
            {companyLogo}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography className={classes.title} variant="h4">
                {company.name}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.tabContainer}>{tabButtons}</Box>
        </Box>
      </Box>
    );
  }

  if (isMdDown) {
    return (
      <Box className={classes.root}>
        <Box className={classes.interiorContainer}>
          <Box className={classes.logoContainer} height="160px">
            {companyLogo}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{ gap: "8px" }}
            >
              <Typography className={classes.title} variant="h4">
                {company.name}
              </Typography>
              {resources}
            </Box>
          </Box>
          <Box className={classes.tabContainer}>{tabButtons}</Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.interiorContainer}>
        <Box className={classes.logoContainer} padding={[1, 1, 0, 1]}>
          {companyLogo}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{ gap: "4px" }}
          >
            <Typography className={classes.title} variant="h4">
              {company.name}
            </Typography>
            {resources}
            <Box className={classes.tabContainer}>{tabButtons}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

}
OrgHeader.propTypes = {
  currentTab: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
};

OrgHeader.defaultProps = {
  currentTab: "summary",
};

export default OrgHeader;
