import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { GetApp } from "@material-ui/icons";
import PropTypes from "prop-types";

const DownloadMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu" 
        aria-haspopup="true" 
        onClick={handleClick}
        style={{ height: "40px", width: "40px" }}
      >
        <GetApp />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.label} onClick={() => { option.onClick(); handleClose();}}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

DownloadMenu.propTypes = {
  options: PropTypes.shape({ 
    label: PropTypes.string.isRequired, 
  }).isRequired
};

export default DownloadMenu;

