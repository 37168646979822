import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) => createStyles({
  filled: {
    fill: theme.palette.green.main,
  },
  empty: {
    fill: theme.palette.neutrals["89"],
  },
}));

interface Props {
  bars: 0 | 1 | 2 | 3
}
export function Signal(props) {
  const { bars } = props;
  const classes = useStyles();
  return (
    <SvgIcon>
      <rect
        className={bars >= 1 ? classes.filled : classes.empty}
        x="2.73"
        y="12.03"
        width="4"
        height="8"
        rx="2"
      />
      <rect
        className={bars >= 2 ? classes.filled : classes.empty}
        x="9.73"
        y="8.03"
        width="4"
        height="12"
        rx="2"
      />
      <rect
        className={bars >= 3 ? classes.filled : classes.empty}
        x="16.74"
        y="4.03"
        width="4"
        height="16"
        rx="2"
      />
    </SvgIcon>
  );
}

export default Signal;
