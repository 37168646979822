import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Signal from "../Icons/Signal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(6),
    height: theme.spacing(3),
    padding: theme.spacing(0.5, 1.5),
    border: `1px solid ${theme.palette.neutrals["89"]}`,
    borderRadius: theme.spacing(4),
  },
}));

export function SignalBadge(props) {
  const { bars, onMouseEnter, onMouseLeave } = props;
  const classes = useStyles();
  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes.container}
    >
      <Signal bars={bars} />
    </Box>
  );
}

SignalBadge.propTypes = {
  bars: PropTypes.oneOf([0, 1, 2, 3]).isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
SignalBadge.defaultProps = {
  onMouseEnter: null,
  onMouseLeave: null,
};

export default SignalBadge;
