/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme, Typography, Box } from "@material-ui/core";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  YAxis,
  Surface,
  Symbols,
} from "recharts";

const displayName = {
  current_company_rank: "Software Engineering Company Rank",
  othereng_current_company_rank: "Non-Software Engineering Company Rank",
  observed_sales_3mo_change: "Revenue Growth (3 months)",
  observed_customers_3mo_change: "Customer Growth (3 months)",
  sales_per_customer_current: "Customer Value",
  sales_per_customer_yoy_change: "Customer Value Growth (1 year)",
  retention_3mo_change: "Retention Growth (3 months)",
  sales_count_3mo_growth: "Sales People Growth (3 months)",
  sales_count_1year_growth: "Sales People Growth (1 year)",
  series: "Series",
  experience_years_actual_mean: "Average Software Engineer Experience",
  person_score_mean: "Average Software Engineer Aptitude",
  person_score_max: "Max Software Engineer Aptitude",
  person_score_all_mean: "Average Employee Aptitude",
  person_score_all_max: "Max Employee Aptitude",
  experience_years_actual_all_mean: "Average Employee Experience",
};

const description = {
  current_company_rank: "A measure of the strength of software engineering talent at a company.",
  othereng_current_company_rank: "A measure of the strength of non-software engineering talent at a company.",
  observed_sales_3mo_change: "Change in direct to consumer revenue.",
  observed_customers_3mo_change: "Change in the number of customers.",
  sales_per_customer_current: "Revenue generated per customer.",
  sales_per_customer_yoy_change: "Change in the revenue generated per customer.",
  retention_3mo_change: "Change in customer retention.",
  sales_count_3mo_growth: "Change in the number of sales people over 3 months.",
  sales_count_1year_growth: "Change in the number of sales people over 1 year.",
  series: "The type of round last raised (Seed vs Series A vs Series B, etc.)",
  experience_years_actual_mean: "The average amount of experience of all software engineers at a company.",
  person_score_mean: "The average strength of software engineers at a company.",
  person_score_max: "The strength of the strongest software engineer at a company.",
  person_score_all_mean: "The average strength of all employees at a company.",
  person_score_all_max: "The strength of the strongest employee at a company.",
  experience_years_actual_all_mean: "The average amount of experience of all employees at a company.",
};

export function ModelConfidenceBarChart({ features, score }) {
  const theme = useTheme();

  const getMapToColor = (arr, colors) => {
    const mapToColor = {};
    arr.forEach((value, index) => {
      mapToColor[value] = colors[index];
    });
    return mapToColor;
  };

  const featureToColor = getMapToColor(
    features.map((feature) => feature.featureType),
    theme.palette.competitors,
  );

  const [onHoverCompany, setOnHoverCompany] = useState("");

  function CustomToolTip(props) {
    const {
      active, payload,
    } = props;

    if (active && payload && payload.length) {
      const { featureType, value } = payload[0]?.payload;

      return (
        <div style={{
          backgroundColor: "white",
          borderColor: "black",
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: "1%",
          fontFamily: "helvetica, sans-serif",
          fontSize: "16px",
          padding: ".5%",
        }}
        >
          <Typography variant="h6">
            {displayName[featureType]}
          </Typography>
          <Typography variant="subtitle1">
            {description[featureType]}
          </Typography>
          MOIC contribution:
          {" "}
          {value.toFixed(2)}
        </div>
      );
    }
    return null;
  }

  const renderCustomizedLegend = ({ }) => (
    <div style={{ textAlign: "left", width: "230px" }}>
      {features.map((feature) => {
        const color = featureToColor[feature.featureType];
        const dataKey = feature.featureType;
        const active = dataKey === onHoverCompany;
        const style = {
          marginRight: 10,
          fontWeight: active ? "bold" : "normal",
          cursor: "pointer",
          color: active ? "#000" : "#808080",
        };

        return (
          <div
            onMouseOver={() => setOnHoverCompany(dataKey)}
            onMouseOut={() => setOnHoverCompany("")}
            style={style}
            key={`span-legend-${dataKey}`}
          >
            <Surface
              width={10}
              height={10}
              viewBox={{
                x: 0, y: 0, width: 10, height: 10,
              }}
            >
              <Symbols cx={5} cy={5} type="circle" size={85} fill={color} />
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={60}
                fill={color}
              />
            </Surface>
            <span style={{ marginLeft: "4px" }}>{displayName[dataKey]}</span>
          </div>
        );
      })}
    </div>
  );

  return (
    <Box width={1200} height={600}>

      <Typography
        variant="h6"
        id="expected-score"
        color="textPrimary"
        style={{
          textAlign: "center", height: "1.5rem", marginTop: "8px", marginBottom: "8px",
        }}
      >
        {score.toFixed(2)}
        x expected MOIC within 3 years
      </Typography>
      <ResponsiveContainer width="100%" height="92%">
        <BarChart
          id="model-confidence-bar-chart"
          cursor="pointer"
          data={features}
          margin={{
            top: 5,
            right: 50,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Legend layout="vertical" verticalAlign="top" align="left" content={renderCustomizedLegend} />
          <YAxis />
          <ChartTooltip
            content={<CustomToolTip />}
            cursor={{ fill: theme.palette.blue.highlight }}
          />
          <Bar dataKey="value">
            <LabelList
              style={{
                fill: "#191919",
              }}
              dataKey="value"
              formatter={(value) => value.toFixed(2)}
              position="top"
            />
            {features.map((feature) => (
              <Cell style={{ fill: featureToColor[feature.featureType], opacity: onHoverCompany === feature.featureType || onHoverCompany === "" ? 1 : 0.5 }} key={feature.featureType} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

ModelConfidenceBarChart.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    featureType: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  score: PropTypes.number.isRequired,
};

export default ModelConfidenceBarChart;
