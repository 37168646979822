import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "default",
  },
  query: {
    fontWeight: 800,
    cursor: "default",
  },
}));

export function SAYTKeywordResult(props) {
  const classes = useStyles();
  const { term, query } = props;

  const find = term.toLowerCase().indexOf(query.toLowerCase());
  const first = term.substring(0, find);
  const middle = term.substring(find, find + query.length);
  const last = term.substring(find + query.length);

  return (
    <Box className={classes.container}>
      <Box paddingLeft={2}>
        <Box>
          <Typography component="span">{first}</Typography>
          <Typography component="span" className={classes.query}>
            {middle}
          </Typography>
          <Typography component="span">{last}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

SAYTKeywordResult.propTypes = {
  term: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
};

export default SAYTKeywordResult;
