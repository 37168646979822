import React from "react";

export function ErrorPage() {
  return (
    <div>
      <h3>
        Looks like your account isnt set up yet or you&apos;ve encountered an error.
        Please email labs@valorep.com if this persists
      </h3>
    </div>
  );
}

export default ErrorPage;
