import React from "react";
import {
  Box, ButtonBase, makeStyles, Theme, createStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { getKey as defaultGetKey, getLabel as defaultGetLabel } from "../../../utils/general";

interface Props {
  id?: string
  width?: string
  evenSizedButtons?: boolean
  getKey?: (option: any) => string
  getLabel?: (option: any) => string
  options: any[]
  onClick: (option: any) => void
  activeKey: string
}
interface PropStyles {
  evenSizedButtons?: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: (props: PropStyles) => ({
    height: "30px",
    flex: props.evenSizedButtons ? "1 1 0" : null,
  }),
  active: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  filterBtns: {
    display: "flex",
    height: "fit-content",
    marginTop: "auto",
    border: `1px solid ${theme.palette.neutrals["89"]}`,
    borderRadius: theme.shape.borderRadius,
    "& > button": {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
    "& > button:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.neutrals["89"]}`,
    },
    "& > button:last-child": {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    "& > button:first-child": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
}));

export function ButtonTabs(props: Props) {
  const {
    id = "",
    width = "100%",
    evenSizedButtons = true,
    getKey = defaultGetKey,
    getLabel = defaultGetLabel,
    options,
    onClick,
    activeKey,
  } = props;
  const classes = useStyles({ evenSizedButtons });

  return (
    <Box id={id} className={classes.filterBtns} width={width}>
      {options.map((option) => (
        <ButtonBase
          className={clsx(classes.button, {
            [classes.active]: getKey(option) === activeKey,
          })}
          key={getKey(option)}
          onClick={() => onClick(option)}
        >
          <Box style={{ marginRight: "3px" }}>{option.startIcon}</Box>
          {getLabel(option)}
        </ButtonBase>
      ))}
    </Box>
  );
}

export default ButtonTabs;
