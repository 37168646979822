import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@material-ui/core";
import DraggableChipBag from "../DraggableChipBag";

export function MMDimensionEditor(props) {
  const {
    dimension: { values, name, id },
    isHorizontal,
    onChange,
  } = props;
  const [dimensionName, setDimensionName] = useState(name);
  const [dimensionValues, setDimensionValues] = useState(values);
  const [currentDimension, setCurrentDimension] = useState(null);
  const nameInputEl = useRef(null);
  const dimensionInputEl = useRef(null);
  useEffect(() => {
    setDimensionName(name);
    setDimensionValues(values);
  }, [name, values]);

  const onClick = (item) => {
    setCurrentDimension(item);
  };

  const onNameChange = (event) => {
    setDimensionName(event.target.value);
    onChange({
      id,
      name: event.target.value,
      values: dimensionValues,
    });
  };
  const onDimensionValueChange = (event) => {
    setCurrentDimension({ ...currentDimension, name: event.target.value });
    const foundIndex = dimensionValues.findIndex(
      (value) => value.id === currentDimension.id,
    );
    const copy = [...dimensionValues];
    copy[foundIndex].name = event.target.value;
    setDimensionValues(copy);
    onChange({
      id,
      name: dimensionName,
      values: copy,
    });
  };
  const onDimensionValueOrderChange = (newOrder) => {
    setDimensionValues(newOrder);
    onChange({
      id,
      name: dimensionName,
      values: newOrder,
    });
  };
  return (
    <Box>
      <TextField
        required
        inputRef={nameInputEl}
        label="Dimension Title"
        variant="filled"
        helperText="The title of your Market Map Dimension"
        value={dimensionName}
        onChange={onNameChange}
      />
      {currentDimension && (
        <TextField
          required
          inputRef={dimensionInputEl}
          variant="outlined"
          helperText="Change the name of your Value"
          value={currentDimension.name}
          onChange={onDimensionValueChange}
        />
      )}
      <DraggableChipBag
        values={dimensionValues}
        isHorizontal={isHorizontal}
        onFinishDrag={onDimensionValueOrderChange}
        onClick={onClick}
      />
    </Box>
  );
}

MMDimensionEditor.propTypes = {
  dimension: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isHorizontal: PropTypes.bool,
};

MMDimensionEditor.defaultProps = {
  isHorizontal: false,
};

export default MMDimensionEditor;
