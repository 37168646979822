import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CompanyAvatar from "../CompanyAvatar";
import RemoveButton from "../RemoveButton";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minWidth: "280px",
    margin: "auto",
    cursor: "default",
  },
  root: {
    display: "flex",
  },
  query: {
    fontWeight: 800,
    cursor: "default",
  },
}));

export function SAYTCompanyResult(props) {
  const classes = useStyles();
  const {
    title, domain, logoUrl, query, handleRemove,
  } = props;

  const find = title.toLowerCase().indexOf(query.toLowerCase());
  const first = title.substring(0, find);
  const middle = title.substring(find, find + query.length);
  const last = title.substring(find + query.length);

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <CompanyAvatar size="32" src={logoUrl} name={title} />
        <Box paddingLeft={2}>
          <Box>
            <Typography component="span">{first}</Typography>
            <Typography component="span" className={classes.query}>
              {middle}
            </Typography>
            <Typography component="span">{last}</Typography>
          </Box>
          <Typography>{domain}</Typography>
        </Box>
      </Box>
      {handleRemove && <RemoveButton onClick={handleRemove} />}
    </Box>
  );
}

SAYTCompanyResult.propTypes = {
  title: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  query: PropTypes.string,
  handleRemove: PropTypes.func,
};

SAYTCompanyResult.defaultProps = {
  logoUrl: null,
  query: "",
  handleRemove: null,
};

export default SAYTCompanyResult;
