import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => createStyles({
  cell: (props: Props) => ({
    display: "grid",
    height: "100%",
    justifyItems: "center",
    gridGap: "8px",
    gridTemplateColumns: `repeat(auto-fit, minmax(calc(${
      100 / Math.ceil(Math.sqrt(props.children.length))
    }% - 8px), 1fr) )`,
    gridTemplateRows: `repeat(auto-fit, minmax(calc(${
      100 / Math.ceil(Math.sqrt(props.children.length))
    }% - 8px), 1fr) )`,
    padding: "8px",
    backgroundColor: "#ffffff",
    borderBottom: `1px solid ${theme.palette.neutrals["89"]}`,
    borderRight: `1px solid ${theme.palette.neutrals["89"]}`,
  }),
}));

interface Props {
  children: React.ReactNode[];
  isLast?: boolean;
}

export function MarketMapCell(props: Props) {
  const { isLast = false, children } = props;
  const classes = useStyles({ children, isLast });
  const lastStyle = { borderBottomRight: "8px" };
  return (
    <Box className={classes.cell} style={isLast ? lastStyle : {}}>
      {children}
    </Box>
  );
}

export default MarketMapCell;
