/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import {
  TextField, createStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() => createStyles({
  text: {
    width: "100%",
  },
}));

function TextFieldAdapter({ input, meta, ...rest }) {
  return (
    <TextField
      {...input}
      {...rest}
      maxRows={5}
      rows={2}
      variant="outlined"
      multiline
      onChange={(event) => input.onChange(event.target.value)}
      error={meta.submitFailed && meta.error}
      label={meta.submitFailed ? meta.error : ""}
      data-formstate={(meta?.error) ? "error" : ""}
    />
  );
}

export function FreeText(props: Props) {
  const {
    questionId,
    skippable,
  } = props;
  const classes = useStyles();
  const required = (value) => (value || skippable ? undefined : "Required");
  return (
    <Field
      className={classes.text}
      name={`${questionId}.commentary`}
      component={TextFieldAdapter}
      validate={required}
    />
  );
}

export default FreeText;
