/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
  circularProgress: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: 9999,
  },
  fullScreen: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10%",
  },
}));

interface Props {
  children?: React.ReactNode
  isLoading?: boolean
  displayChildren?: boolean
  isError?: boolean
}

export function CenteredCircularProgress(props: Props) {
  const {
    children,
    isLoading = true,
    displayChildren = false,
    isError = false,
  } = props;
  const classes = useStyles();

  return (
    <>
      {(!isLoading || displayChildren) && children}
      {isLoading && <CircularProgress className={classes.circularProgress} />}
      {isError && (
        <Typography className={classes.fullScreen} variant="h6">
          Something went wrong with displaying data, contact the labs team with
          this url.
        </Typography>
      )}
    </>
  );
}

export default CenteredCircularProgress;
