import React from "react";
import {
  InputLabel, ListSubheader, MenuItem, FormControl, Select,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getKey, getLabel } from "../../../utils/general";

export function SingleSelect({
  id,
  title,
  options,
  onChange,
  value,
  showNone,
  variant,
  className,
  getKey,
  getLabel,
  width,
  defaultValue,
}) {
  return (
    <FormControl
      style={{ width, marginRight: "8px" }}
      variant={variant}
      margin="dense"
      className={className}
    >
      {title && <InputLabel id={`${id}-select-label`}>{title}</InputLabel>}
      <Select
        labelId={`${id}-select-label`}
        id={`${id}-select`}
        value={value}
        defaultValue={defaultValue}
        label={title}
      >
        {showNone && <MenuItem value="" onClick={() => onChange('')}>All</MenuItem>}
        {Array.isArray(options)
          ? options.map((option) => {
            const key = getKey(option);
            if (key === "subheader") {
              return <ListSubheader>{getLabel(option)}</ListSubheader>;
            }
            return (
              <MenuItem style={{ minHeight: '30px' }} onClick={() => onChange(key, option)} key={key} value={key}>
                {getLabel(option)}
              </MenuItem>
            );
          }) // array case
          : Object.keys(options).map((key) => (
            <MenuItem key={key} value={key} onClick={() => onChange(key)}>
              {options[key]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

SingleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showNone: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  getKey: PropTypes.func,
  getLabel: PropTypes.func,
  width: PropTypes.number,
};

SingleSelect.defaultProps = {
  title: null,
  showNone: false,
  variant: "outlined",
  getKey,
  getLabel,
  width: 150,
};

export default SingleSelect;
