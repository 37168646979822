const shrinkFormatNumbers = (n) => {
  // const isNegative = n < 0;
  // let number = Math.abs(n);
  let number = n;
  if (!Number.isInteger(number)) {
    number = parseInt(n, 10);
  }

  const ONE_THOU = 1000;
  const ONE_MIL = 1000000;
  const ONE_BIL = 1000000000;
  const ONE_TRIL = 1000000000000;
  const ONE_QUAD = 1000000000000000;

  if (Math.abs(number) >= ONE_QUAD) {
    const sliced = number.toString(10).slice(0, -14);
    if (sliced[sliced.length - 1] !== "0") {
      return `${sliced.slice(0, -1)}.${sliced[sliced.length - 1]}Q`;
    }
    return `${sliced.slice(0, -1)}Q`;
  }

  if (Math.abs(number) >= ONE_TRIL) {
    const sliced = number.toString(10).slice(0, -11);
    if (sliced[sliced.length - 1] !== "0") {
      return `${sliced.slice(0, -1)}.${sliced[sliced.length - 1]}T`;
    }
    return `${sliced.slice(0, -1)}T`;
  }

  if (Math.abs(number) >= ONE_BIL) {
    const sliced = number.toString(10).slice(0, -8);
    if (sliced[sliced.length - 1] !== "0") {
      return `${sliced.slice(0, -1)}.${sliced[sliced.length - 1]}B`;
    }
    return `${sliced.slice(0, -1)}B`;
  }

  if (Math.abs(number)>= ONE_MIL) {
    const sliced = number.toString(10).slice(0, -5);
    if (sliced[sliced.length - 1] !== "0") {
      return `${sliced.slice(0, -1)}.${sliced[sliced.length - 1]}M`;
    }
    return `${sliced.slice(0, -1)}M`;
  }

  if (Math.abs(number) >= ONE_THOU) {
    const sliced = number.toString(10).slice(0, -2);
    if (sliced[sliced.length - 1] !== "0") {
      return `${sliced.slice(0, -1)}.${sliced[sliced.length - 1]}K`;
    }
    return `${sliced.slice(0, -1)}K`;
  }
  return number;
};

export const formatTotal = (value, maximumFractionDigits = 0) => value?.toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits,
});

export const formatShrunkTotal = (value, maximumFractionDigits = 0) => shrinkFormatNumbers(value)?.toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits,
});

export const formatFloat = (value, maximumFractionDigits = 2) => Number.parseFloat(value).toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits,
});

export const formatMultiplierFromPercentage = (percent) => {
  if(!percent) {
    return null
  }
  const isPositive = percent > 0;
  const shouldRatio = percent > 100;

  if (shouldRatio) {
    const ratio = Math.round(percent / 100) + 1;
    if (ratio < 10) {
      return isPositive
        ? `up ${(percent / 100 + 1).toFixed(1)}x`
        : `down ${(percent / 100 + 1).toFixed(1)}x`;
    }
    return isPositive ? `up ${ratio.toFixed(0)}x` : `down ${ratio.toFixed(0)}x`;
  }
  return isPositive ? `+${percent.toFixed(0)}%` : `${percent.toFixed(0)}%`;
};

export const formatPercentage = (value, maximumFractionDigits = 2) => `${(value * 100).toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits,
})}%`;

const DollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatDollars = (dollars, showCents = true) => {
  if(!dollars) {
    return null
  }
  const format = DollarFormatter.format(dollars);
  if (showCents) {
    return format;
  }
  return format.slice(0, format.length - 3);
};

export const formatBigDollars = (dollars) => {
  if (dollars == null || Number.isNaN(dollars)) {
    return "";
  }
  if (dollars > 999 || dollars < -999) {
    return `$${shrinkFormatNumbers(dollars)}`;
  }
  return DollarFormatter.format(dollars);
};

export default {
  formatTotal,
  formatFloat,
  formatShrunkTotal,
  formatPercentage,
  formatBigDollars,
  formatDollars,
  formatMultiplierFromPercentage,
};
