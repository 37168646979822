/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { BarChart, TableChartSharp, ShowChart } from "@material-ui/icons";
import { getLastValue, getYoYGrowth } from "@valor-labs/marble";

export const chartRepresentsDoc = (
  <>
    <h3>Chart Represents</h3>
    By picking an option from this dropdown
    {" "}
    <strong>YAxis</strong>
    {" "}
    will
    represent
    {" "}
    <i>Relative Growth</i>
    {" "}
    or
    {" "}
    <i>Absolute Values</i>
    .
    <br />
    <br />
  </>
);

export const historiesOptions = [
  {
    id: "employeeTotalHistory",
    label: "Employees",
    formatter: "formatTotal",
    group: "LinkedIn",
  },
  {
    id: "jobTotalHistory",
    label: "Jobs",
    formatter: "formatTotal",
    group: "LinkedIn",
  },
  {
    id: "webTrafficHistory",
    label: "Web Traffic",
    formatter: "formatShrunkTotal",
    group: "Web Traffic",
  },
  {
    id: "retentionHistory",
    label: "Retention",
    formatter: "formatPercentage",
    group: "Second Measure Customers",
  },
  {
    id: "newCustomersHistory",
    label: "New Customers",
    formatter: "formatPercentage",
    group: "Second Measure Customers",
  },
  {
    id: "observedSalesHistory",
    label: "Observed Sales",
    subtitle: "Monthly data based on a sample of credit card transactions",
    formatter: "formatBigDollars",
    group: "Second Measure Trends",
  },
  {
    id: "observedTransactionsHistory",
    label: "Observed Transactions",
    formatter: "formatTotal",
    group: "Second Measure Trends",
  },
  {
    id: "observedCustomersHistory",
    label: "Observed Customers",
    formatter: "formatTotal",
    group: "Second Measure Trends",
  },
  {
    id: "salesPerCustomerHistory",
    label: "Sales per Customer",
    formatter: "formatBigDollars",
    group: "Second Measure Trends",
  },
  {
    id: "txnsPerCustomerHistory",
    label: "Transactions per Customer",
    formatter: "formatTotal",
    group: "Second Measure Trends",
  },
  {
    id: "avgTxnValueHistory",
    label: "Average Transaction Value",
    formatter: "formatDollars",
    group: "Second Measure Trends",
  },
  {
    id: "socialTrafficHistory",
    label: "Social Traffic",
    formatter: "formatShrunkTotal",
    group: "Second Traffic",
  },
];

export const metricAccessors = {
  totalFundingUsd: (x) => x.totalFundingUsd,
  lastRoundTotalUsd: (x) => x.lastRoundTotalUsd,
  employeeTotal: (x) => getLastValue(x.employeeTotalHistory),
  employeeTotalYoyPct: (x) => getYoYGrowth(x.employeeTotalHistory),
  jobTotal: (x) => getLastValue(x.jobTotalHistory),
  jobTotalYoyPct: (x) => getYoYGrowth(x.jobTotalHistory),
  webTraffic: (x) => getLastValue(x.webTrafficHistory),
  webTrafficYoyPct: (x) => getYoYGrowth(x.webTrafficHistory),
  retention: (x) => getLastValue(x.retentionHistory),
  customersAsPctOfPopulation: (x) => getLastValue(x.customerPctHistory),
  lifetimeCustomersAsPctOfPopulation: (x) => getLastValue(x.lifetimeCustomerPctHistory),
  newCustomers: (x) => getLastValue(x.newCustomersHistory),
  observedSales: (x) => getLastValue(x.observedSalesHistory),
  observedSalesYoyPct: (x) => getYoYGrowth(x.observedSalesHistory),
  observedTransactions: (x) => getLastValue(x.observedTransactionsHistory),
  observedTransactionsYoyPct: (x) => getYoYGrowth(x.observedTransactionsHistory),
  observedCustomers: (x) => getLastValue(x.observedCustomersHistory),
  observedCustomersYoyPct: (x) => getYoYGrowth(x.observedCustomersHistory),
  salesPerCustomer: (x) => getLastValue(x.salesPerCustomerHistory),
  salesPerCustomerYoyPct: (x) => getYoYGrowth(x.salesPerCustomerHistory),
  txnsPerCustomer: (x) => getLastValue(x.txnsPerCustomerHistory),
  txnsPerCustomerYoyPct: (x) => getYoYGrowth(x.txnsPerCustomerHistory),
  avgTxnValue: (x) => getLastValue(x.avgTxnValueHistory),
  avgTxnValueYoyPct: (x) => getYoYGrowth(x.avgTxnValueHistory),
  socialTraffic: (x) => getLastValue(x.socialTrafficHistory),
  socialTrafficYoyPct: (x) => getYoYGrowth(x.socialTrafficHistory),
};

export const metricsOptions = [
  {
    id: "totalFundingUsd",
    label: "Total Raised",
    formatter: "formatBigDollars",
    group: "Capital",
  },
  {
    id: "lastRoundTotalUsd",
    label: "Last Round Total",
    formatter: "formatBigDollars",
    group: "Capital",
  },
  {
    id: "employeeTotal",
    label: "Employees",
    formatter: "formatTotal",
    group: "LinkedIn",
  },
  {
    id: "employeeTotalYoyPct",
    label: "Employees YoY Growth",
    formatter: "formatPercentage",
    group: "LinkedIn",
  },
  {
    id: "jobTotal",
    label: "Jobs",
    formatter: "formatTotal",
    group: "LinkedIn",
  },
  {
    id: "jobTotalYoyPct",
    label: "Jobs YoY Growth",
    formatter: "formatPercentage",
    group: "LinkedIn",
  },
  {
    id: "webTraffic",
    label: "Web Traffic",
    formatter: "formatShrunkTotal",
    group: "Web Traffic",
  },
  {
    id: "webTrafficYoyPct",
    label: "Web Traffic YoY Growth",
    formatter: "formatPercentage",
    group: "Web Traffic",
  },
  {
    id: "retention",
    label: "Retention",
    formatter: "formatPercentage",
    group: "Second Measure Customers",
  },
  {
    id: "customersAsPctOfPopulation",
    label: "Customer % of Pop",
    formatter: "formatPercentage",
    group: "Second Measure Customers",
  },
  {
    id: "lifetimeCustomersAsPctOfPopulation",
    label: "Lifetime Customer % of Pop",
    formatter: "formatPercentage",
    group: "Second Measure Customers",
  },
  {
    id: "newCustomers",
    label: "New Customers",
    formatter: "formatPercentage",
    group: "Second Measure Customers",
  },
  {
    id: "observedSales",
    label: "Observed Sales",
    subtitle: "For the last month based on a sample of credit card data",
    formatter: "formatBigDollars",
    group: "Second Measure Trends",
  },
  {
    id: "observedSalesYoyPct",
    label: "Observed Sales YoY Growth",
    formatter: "formatPercentage",
    group: "Second Measure Trends",
  },
  {
    id: "observedTransactions",
    label: "Observed Transactions",
    subtitle: "Number of transactions in the last month",
    formatter: "formatTotal",
    group: "Second Measure Trends",
  },
  {
    id: "observedTransactionsYoyPct",
    label: "Observed Transactions: YoY Growth",
    formatter: "formatPercentage",
    group: "Second Measure Trends",
  },
  {
    id: "observedCustomers",
    label: "Observed Customers",
    formatter: "formatTotal",
    group: "Second Measure Trends",
  },
  {
    id: "observedCustomersYoyPct",
    label: "Observed Customers YoY Growth",
    formatter: "formatPercentage",
    group: "Second Measure Trends",
  },
  {
    id: "salesPerCustomer",
    label: "Sales per Customer",
    formatter: "formatBigDollars",
    group: "Second Measure Trends",
  },
  {
    id: "salesPerCustomerYoyPct",
    label: "Sales per Customer Yoy Growth",
    formatter: "formatPercentage",
    group: "Second Measure Trends",
  },
  {
    id: "txnsPerCustomer",
    label: "Transactions per Customer",
    formatter: "formatTotal",
    group: "Second Measure Trends",
  },
  {
    id: "txnsPerCustomerYoyPct",
    label: "Transactions per Customer YoY Growth",
    formatter: "formatPercentage",
    group: "Second Measure Trends",
  },
  {
    id: "avgTxnValue",
    label: "Average Transaction Value",
    formatter: "formatDollars",
    group: "Second Measure Trends",
  },
  {
    id: "avgTxnValueYoyPct",
    label: "Transactions per Customer YoY Growth",
    formatter: "formatPercentage",
    group: "Second Measure Trends",
  },
  {
    id: "socialTraffic",
    label: "Social Traffic",
    formatter: "formatShrunkTotal",
    group: "Second Traffic",
  },
  {
    id: "socialTrafficYoyPct",
    label: "Social Traffic YoY Growth",
    formatter: "formatPercentage",
    group: "Second Traffic",
  },
];

// IMPORTANT: defaultValue array should store the same object references as the options array
// Autocomplete component does only reference equality
export const defaultMetrics = [
  metricsOptions[12],
  metricsOptions[13],
  metricsOptions[8],
  metricsOptions[18],
  metricsOptions[19],
  metricsOptions[2],
  metricsOptions[3],
  metricsOptions[6],
  metricsOptions[7],
];

export const defaultHistoriesValues = [
  historiesOptions[5],
  historiesOptions[8],
  historiesOptions[3],
  historiesOptions[0],
  historiesOptions[2],
];

export const tabs = [
  {
    key: "graph",
    label: "Graph",
    startIcon: <BarChart />,
  },
  {
    key: "table",
    label: "Table",
    startIcon: <TableChartSharp />,
  },
];

export const graphTabs = [
  {
    key: "line",
    label: "Line Chart",
    startIcon: <ShowChart />,
  },
  {
    key: "bar",
    label: "Bar Chart",
    startIcon: <BarChart />,
  },
];
