/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import MultiSelect from "../MultiSelect";

export function MultiSelectColumnFilter({
  column: {
    filterValue = [], setFilter, preFilteredRows, id,
  },
  labelFormatter,
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const uniqueOptions = new Set();
    preFilteredRows.forEach((row) => {
      const value = row.values[id];
      if (value !== null) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            uniqueOptions.add(...value);
          }
        } else {
          uniqueOptions.add(value);
        }
      }
    });
    return [...uniqueOptions].sort();
  }, [preFilteredRows]);

  useEffect(() => {
    setFilter(options);
  }, [options]);

  return (
    <MultiSelect
      id={`${id}-table-multi-select-filter`}
      allValues={options}
      onChange={(value) => { setFilter(value); }}
      defaultValue={options.map((o) => ({ value: o, label: o }))}
      value={filterValue}
      getLabel={(label) => labelFormatter(label)}
    />
  );
}

MultiSelectColumnFilter.propTypes = {
  labelFormatter: PropTypes.func,
};

MultiSelectColumnFilter.defaultProps = {
  labelFormatter: (title) => title,
};

export default MultiSelectColumnFilter;
