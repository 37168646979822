import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CompanyAvatar from "../../atoms/CompanyAvatar";
import OrgCard from "../../atoms/OrgCard";
import FollowButton from "../../atoms/FollowButton";
// import Company from "../../../types/Company";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.neutrals["89"]}`,
  },
  rowContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.neutrals["100"],
    borderBottom: `1px solid ${theme.palette.neutrals["89"]}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.neutrals["96"],
    },
  },
  title: {
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "12px",
  },
  companyName: {
    paddingLeft: theme.spacing(2),
    overflowX: "hidden",
    color: theme.palette.neutrals["30"],
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export function OrgHistoryCard(props) {
  const { companies, onClick, onFollowClick } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const mapCompanies = (company) => {
    const {
      valorId, name, logoUrl, isFollowing,
    } = company;
    return (
      <Box
        className={classes.rowContainer}
        key={valorId}
        onClick={(e) => onClick(e, valorId)}
      >
        <Box display="flex" alignItems="center">
          <CompanyAvatar src={logoUrl} name={name} size="32" />
          <Typography className={classes.companyName} variant="subtitle1">
            {name}
          </Typography>
        </Box>
        <FollowButton
          isFollowing={isFollowing}
          onClick={(e) => onFollowClick(e, valorId, isFollowing)}
          isXs
          showBorder={false}
        />
      </Box>
    );
  };
  if (isMdDown && Boolean(companies.length)) {
    return (
      <Box display="flex" flexDirection="column" margin={1}>
        {companies.slice(0, 5).map(mapCompanies)}
      </Box>
    );
  }
  return (
    <OrgCard style={{ minHeight: "260px", marginTop: "8px" }}>
      <Box>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            RECENTLY VIEWED COMPANIES
          </Typography>
        </Box>

        {Boolean(companies.length) && (
          <Box display="flex" flexDirection="column">
            {companies.slice(0, 5).map(mapCompanies)}
          </Box>
        )}
      </Box>
    </OrgCard>
  );
}
OrgHistoryCard.propTypes = {
  // companies: PropTypes.arrayOf(Company).isRequired,
  onClick: PropTypes.func.isRequired,
  onFollowClick: PropTypes.func.isRequired,
};

export default OrgHistoryCard;
