const Segments = {
  A: "Consumer Technology",
  B: "Food / Retail Tech",
  C: "Metaverse",
  D: "Vertical AI",
  E: "Financial Technology",
  F: "Real Estate Tech",
  G: "Security Tech",
  H: "Enterprise / B2B",
  I: "Transportation & Logistics",
  J: "Health Technology",
  K: "Advanced Engineering",
};

export default Segments;
