/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState } from "react";
import {
  RootRef, Button, Box, Typography, Theme, createStyles,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  dropZone: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(20),
    height: theme.spacing(20),
    margin: theme.spacing(1),
    border: "1px solid transparent",
    borderRadius: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

interface Props {
  title?: string;
  acceptString?: string;
  onSubmit(file: File): void;
  multiple?: boolean;
  isBusy?: boolean;
}

export function FileDropZone({
  title = "Upload a File",
  acceptString = "",
  onSubmit,
  multiple = false,
  isBusy = false,
}: Props) {
  const classes = useStyles();
  const [uploadedFile, setUploadedFile] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  }, []);
  const {
    getRootProps, getInputProps, inputRef, isDragActive,
  } = useDropzone({
    onDrop,
  });
  const { ref, ...rootProps } = getRootProps();
  return (
    <RootRef rootRef={ref}>
      <Box className={classes.root}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box
          className={classes.dropZone}
          style={isDragActive ? { border: "1px solid black" } : {}}
          {...rootProps}
        >
          <AttachFile className={classes.icon} />
        </Box>
        {uploadedFile ? (
          <>
            <Typography component="span" variant="subtitle1">
              {uploadedFile.name}
            </Typography>
            <Box display="flex" justifyContent="space-between" width={240}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setUploadedFile(null)}
              >
                Remove File
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onSubmit(uploadedFile)}
                disabled={isBusy}
              >
                Submit
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => inputRef.current.click()}
            >
              Upload a File
            </Button>
            <input
              {...getInputProps()}
              multiple={multiple}
              accept={acceptString}
            />
          </>
        )}
      </Box>
    </RootRef>
  );
}

export default FileDropZone;
