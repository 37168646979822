/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography, Box, Theme, createStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { getThemeColor } from "../../../utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color:
      props.fallback || !props.src
        ? theme.palette.getContrastText(getThemeColor(props.name))
        : theme.palette.neutrals["96"],
    borderRadius: theme.shape.borderRadius,
    width: props.size ? `${props.size}px` : "100%",
    height: props.size ? `${props.size}px` : props.fallback ? "auto" : "100%",
    overflow: "hidden",
  }),
  image: (props) => ({
    maxWidth: "100%",
    minWidth: props.minStretch && "100%",
    minHeight: props.minStretch && "100%",
    maxHeight: "100%",
    boxSizing: "border-box",
    borderRadius: !props.shouldFallBack && theme.shape.borderRadius,
  }),
  fallbackText: (props) => ({
    textAlign: "center",
    lineHeight: props.size ? `${props.size}px` : "100%",
    fontSize: props.size ? `${props.size / 3}px` : "2.5em",
    overflowX: "hidden",
    cursor: "default",
    padding: theme.spacing(0.5, 2),
  }),
}));

interface Props {
  src?: string;
  name?: string;
  size?: string;
  minStretch?: boolean;
  loading?: boolean;
}

export function CompanyAvatar(props: Props) {
  const {
    src, name = "", size, minStretch, loading,
  } = props;
  const [shouldFallBack, setFallBackStatus] = useState(false);
  const classes = useStyles({
    src,
    size,
    name,
    fallback: shouldFallBack,
    minStretch,
  });
  return (
    <Box className={classes.container}>
      {shouldFallBack || !src ? (
        <div style={{ backgroundColor: getThemeColor(name) }}>
          <Typography className={classes.fallbackText}>
          {name
            .split(/\s/)
            .reduce((response, word) => (response += word.slice(0, 1)), "")
            .slice(0, 3)
            .toUpperCase()}
          </Typography>
        </div>
      ) : (
        <img
          className={classes.image}
          src={src}
          onError={() => setFallBackStatus(true)}
          alt={name}
          loading={loading}
        />
      )}
    </Box>
  );
}

CompanyAvatar.defaultProps = {
  loading: "lazy",
};

export default CompanyAvatar;
