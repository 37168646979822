import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Box, Typography, Collapse, Checkbox,
} from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme: Theme) => createStyles({
  parent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  text: {
    display: "flex",
    alignItems: "center",
  },
  childChecks: {
    paddingLeft: theme.spacing(4),
  },
  parentCheckBoxLabel: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightBold,
  },
  checkBoxLabel: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

interface CheckChildren {
  id: string
  label: string
  value: string
}

interface Check {
  parent: string
  children: CheckChildren[]
}

interface Props {
  checks: Check
  onCheckChange: (check: Check) => void
}

export function CollapseChecks(props: Props) {
  const { checks, onCheckChange } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onCheck = (id) => {
    const newState = JSON.parse(JSON.stringify(checks));
    if (id === checks.parent) {
      const current = checks.children.every((check) => check.value);
      // eslint-disable-next-line no-param-reassign, no-return-assign
      newState.children.forEach((check) => (check.value = !current));
      return onCheckChange(newState);
    }
    const ele = newState.children.find((check) => check.id === id);
    ele.value = !ele.value;
    return onCheckChange(newState);
  };

  const renderCheckbox = (check) => (
    <Box key={check.id} className={classes.text}>
      <Checkbox
        key={check.id}
        checked={check.value === true}
        onChange={() => onCheck(check.id)}
        name={check.label}
        color="primary"
      />
      <Typography className={classes.checkBoxLabel}>{check.label}</Typography>
    </Box>
  );

  return (
    <Box paddingBottom={2}>
      <Box className={classes.parent}>
        <Checkbox
          checked={checks.children.every((check) => check.value)}
          onChange={() => onCheck(checks.parent)}
          name={checks.parent}
          color="primary"
        />
        <Box className={classes.text} onClick={() => setOpen(!open)}>
          <Typography className={classes.parentCheckBoxLabel}>
            {checks.parent}
          </Typography>
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </Box>
      </Box>
      <Collapse in={open}>
        <Box className={classes.childChecks}>
          {checks.children.map(renderCheckbox)}
        </Box>
      </Collapse>
    </Box>
  );
}
export default CollapseChecks;
