import React from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import {
  makeStyles, useTheme, Theme, createStyles,
} from "@material-ui/core/styles";
import SignalBadge from "../../atoms/SignalBadge";
import Notable from "../../atoms/Icons/Notable";
import FillingBar from "../../atoms/FillingBar";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.neutrals["30"],
    borderBottom: `1px solid ${theme.palette.neutrals["89"]}`,
  },
  signal: {
    marginRight: theme.spacing(2),
  },
  arcContainer: {
    paddingBottom: theme.spacing(1),
  },
  arcLabel: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
  },
  notableTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  notableTitle: {
    color: theme.palette.neutrals["13"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
  },
}));

interface Props {
  signal: 0 | 1 | 2 | 3;
  activity: 1 | 2 | 3;
  repeat: 1 | 2 | 3;
  concentration: 1 | 2 | 3;
  topFirms: string[]
  hideTitle: boolean
}

export function FundingSignalCard(props: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    signal,
    activity,
    repeat,
    concentration,
    topFirms,
    hideTitle = false,
  } = props;

  return (
    <Box id="funding-signal">
      <Box className={classes.title}>
        <Box className={classes.signal}>
          <SignalBadge bars={signal} />
        </Box>
        {!hideTitle && <Typography variant="h6">Signal Strength</Typography>}
      </Box>
      <Box className={classes.arcContainer}>
        <Tooltip
          title="ACTIVITY measures how many high-signal firms have participated in funding, more firms give a higher score"
          placement="top-start"
        >
          <Typography className={classes.arcLabel}>ACTIVITY</Typography>
        </Tooltip>
        <FillingBar
          filled={(activity / 3) * 100}
          color={theme.palette.teal.main}
        />
      </Box>
      <Box className={classes.arcContainer}>
        <Tooltip
          title="CONCENTRATION measures much funding high-signal firms have contributed, larger rounds give a higher score"
          placement="top-start"
        >
          <Typography className={classes.arcLabel}>CONCENTRATION</Typography>
        </Tooltip>
        <FillingBar
          filled={(concentration / 3) * 100}
          color={theme.palette.red.main}
        />
      </Box>
      <Box className={classes.arcContainer}>
        <Tooltip
          title="REPEAT INVESTMENT measures how often high-signal firms make continued investments, more rounds by the same firm give a higher score"
          placement="top-start"
        >
          <Typography className={classes.arcLabel}>
            REPEAT INVESTMENT
          </Typography>
        </Tooltip>
        <FillingBar
          filled={(repeat / 3) * 100}
          color={theme.palette.purple.main}
        />
      </Box>

      <Box className={classes.notableTitleContainer}>
        <Box style={{ color: theme.palette.purple.main }}>
          <Notable />
        </Box>
        <Typography className={classes.notableTitle}>
          Notable Investors
        </Typography>
      </Box>
      <Typography>{topFirms.join(", ")}</Typography>
    </Box>
  );
}

export default FundingSignalCard;
