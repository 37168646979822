import React from "react";
import PropTypes from "prop-types";
import { withStyles, Typography } from "@material-ui/core";
import MUISlider from "@material-ui/core/Slider";

const CustomSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.blue.light,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(MUISlider);

export function Slider({
  id, min, max, defaultValue, onChange, title,
}) {
  return (
    <>
      {title && (
        <Typography id={`custom-slider-typography-${id}`} gutterBottom>
          {title}
        </Typography>
      )}
      <CustomSlider
        id={`custom-slider-${id}`}
        valueLabelDisplay="auto"
        aria-label="custom slider"
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={(e, value) => onChange(value)}
      />
    </>
  );
}

Slider.propTypes = {
  id: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Slider;
