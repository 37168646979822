/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  AutocompleteSelect,
  ButtonTabs,
  CompetitorBarChart,
  CompetitorChart,
  CompetitorLineChart,
  DropdownInfo,
  getStartAndEndDate,
  SingleSelect,
  Slider,
  StartEndDates,
  useLocalStorageState,
} from "@valor-labs/marble";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
  metricsOptions,
  metricAccessors,
  defaultMetrics,
  defaultHistoriesValues,
  historiesOptions,
  graphTabs,
  chartRepresentsDoc,
} from "./data";

dayjs.extend(isBetween);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  alert: {
    position: "absolute",
    zIndex: theme.zIndex.appBar + 1,
    boxShadow: theme.shadows[3],
    left: "50%",
  },
}));

const border = "1px solid rgba(49, 49, 49, .1)";
const DEFAULT_HEIGHT = 270;

const getMapToColor = (arr, colors) => {
  const mapToColor = {};
  arr.forEach((value, index) => {
    mapToColor[value] = colors[index];
  });
  return mapToColor;
};

// This is a false positive by eslint
// eslint-disable-next-line react/function-component-definition
export const Graphs = (props) => {
  const { company, competitors, histories } = props;
  const classes = useStyles();

  const [graph, setGraph] = useLocalStorageState(
    "line",
    "Insights-kpis-graph",
  );
  const [metrics, setMetrics] = useLocalStorageState(
    defaultMetrics,
    "Insights-graph-metrics",
  );
  const [historiesValues, setHistoriesValues] = useLocalStorageState(
    defaultHistoriesValues,
    "Insights-histories-values-list",
  );
  const [barHeight, setBarHeight] = useLocalStorageState(
    DEFAULT_HEIGHT,
    "Insights-bar-height",
  );
  const [chartsPerRow, setChartsPerRow] = useLocalStorageState(
    competitors.length > 6 ? 6 : 2,
    "Insights-charts-per-row",
  );
  const theme = useTheme();
  const isMdDown = useMediaQuery((t) => t.breakpoints.down("md"));
  const competitorNames = competitors.map((c) => c.name);
  const companyToColor = getMapToColor(
    competitorNames,
    theme.palette.competitors,
  );
  const dates = getStartAndEndDate("last_2_years");
  const [startDate, setStartDate] = useState(dates.startDate);
  const [endDate, setEndDate] = useState(dates.endDate);
  const [dateRange, setDateRange] = useState("last_2_years");
  const [filteredHistories, setFilteredHistories] = useState({});
  const [chartRepresents, setChartRepresents] = useState("absolute");
  // highlight all charts
  const [highlightedCompany, setHighlightedCompany] = useState(company.name);

  const [showRelativeBlurb, setShowRelativeBlurb] = useLocalStorageState(
    true,
    "competitor-kpis-charts-relative-blurb",
  );

  useEffect(
    () => {
      if (chartRepresents === "relative") {
        setTimeout(() => setShowRelativeBlurb(false), 5000);
      }
    },
    [chartRepresents, setShowRelativeBlurb],
  );

  useEffect(() => {
    if (histories) {
      if (startDate && endDate) {
        const filteredData = {};
        for (const option of historiesValues) {
          if (histories[option.id]) {
            filteredData[option.id] = histories[option.id]?.filter((value) => dayjs
              .unix(value.timestamp)
              .utc()
              .isBetween(startDate, endDate, "day", "[]"));
          }
        }
        setFilteredHistories(filteredData);
      } else {
        setFilteredHistories(histories);
      }
    }
  }, [startDate, endDate, historiesValues, histories]);

  return (
    <Grid id="Insights-graph" container spacing={1} style={{ borderTop: border }}>
      <Grid
        item
        md={4}
        lg={2}
        style={{ borderRight: border, paddingRight: "10px" }}
      >
        <Box id="graph-control">
          <Box className={classes.formControl} style={{ marginBottom: "5px" }}>
            <Typography id="bars-per-row" gutterBottom>
              Graph
            </Typography>
            <ButtonTabs
              options={graphTabs}
              onClick={(option) => setGraph(option.key)}
              activeKey={graph}
            />
          </Box>
          <SingleSelect
            className={classes.formControl}
            width="100%"
            id="highlight-all-charts"
            title="Highlight All Charts"
            value={highlightedCompany}
            onChange={setHighlightedCompany}
            options={competitorNames}
            showNone
          />
          {graph === "line" ? (
            <AutocompleteSelect
              id="histories"
              label="Histories"
              value={historiesValues}
              onChange={setHistoriesValues}
              options={historiesOptions}
            />
          ) : (
            <AutocompleteSelect
              id="metrics"
              label="Metrics"
              value={metrics}
              onChange={setMetrics}
              options={metricsOptions}
            />
          )}
          <Box className={classes.formControl}>
            <Slider
              id="adjust-height"
              title="Adjust Height"
              min={200}
              max={610}
              defaultValue={barHeight || DEFAULT_HEIGHT}
              onChange={setBarHeight}
            />
            {!isMdDown && (
              <>
                <Typography id="charts-per-row" gutterBottom>
                  Charts per row
                </Typography>
                <ButtonTabs
                  options={[1, 2, 3, 4]}
                  onClick={setChartsPerRow}
                  activeKey={chartsPerRow}
                />
              </>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid container item md={8} lg={10}>
        {showRelativeBlurb && chartRepresents === "relative" && graph === "line" && (
        <Alert className={classes.alert} variant="filled" severity="info">
          Relative Growth shows a growth multiple since the start date.
        </Alert>
        )}
        {graph === "line" && (
          <Grid id="chart-represents" item xs={12}>
            <DropdownInfo
              style={{ marginLeft: "1%" }}
              component={(
                <SingleSelect
                  id="chart-represents"
                  width={170}
                  title="Chart Represents"
                  value={chartRepresents}
                  onChange={setChartRepresents}
                  options={{
                    relative: "Relative Growth",
                    absolute: "Absolute Values",
                  }}
                />
              )}
              title={chartRepresentsDoc}
            />

            <StartEndDates
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Grid>
        )}
        {(graph === "line" ? historiesValues : metrics).map((metric) => (
          <Grid key={metric.id} item xs={12} lg={12 / chartsPerRow}>
            <CompetitorChart
              metric={metric}
              currentCompany={company.name}
              defaultHighlightedCompany={highlightedCompany}
              height={barHeight}
            >
              {graph === "line" ? (
                <CompetitorLineChart
                  history={filteredHistories[metric.id]}
                  allDisplayValues={competitorNames}
                  companyToColor={companyToColor}
                  chartRepresents={chartRepresents}
                />
              ) : (
                <CompetitorBarChart
                  competitors={competitors}
                  metricAccessors={metricAccessors}
                />
              )}
            </CompetitorChart>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

Graphs.propTypes = {
  company: PropTypes.object.isRequired,
};

export default Graphs;
