/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useHistory,
} from "react-router-dom";
import { Box, Link } from "@material-ui/core";
import Helmet from "react-helmet";
import { ErrorMessage, CenteredCircularProgress, slugify } from "@valor-labs/marble";
import { getOrg } from "../../api/Pofoco";
import { useAuth } from "../../hooks/useAuth";
import OrgHeader from "./OrgHeader";
import OrganizationSummary from "./Summary";
import OrganizationEmployees from "./Employees";

function Organization(props) {
  // eslint-disable-next-line react/prop-types
  const {
    location: { pathname },
  } = props;
  const { id, section, slug } = useParams();
  const history = useHistory();
  const { user, isAuthenticated } = useAuth();

  const [currentTab, setCurrentTab] = useState(section);

  const { status, data: company = {}, isLoading } = useQuery(
    ["Organization", id, user],
    async () => {
      const response = await getOrg(id);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      enabled: isAuthenticated,
      retry: 1,
    },
  );

  const onTabChange = (tab) => {
    history.push(`/${slugify(company.name)}/org/${id}/${tab}`);
    setCurrentTab(tab);
  };

  useEffect(() => setCurrentTab(section), [id, section]);

  useEffect(() => {
    if (!slug && company?.name) {
      history.replace(`/${slugify(company.name)}${pathname}`);
    }
  }, [slug, company, history, pathname]);

  if (status === "error" || !company) {
    return (
      <Box>
        <ErrorMessage
          title={"Sorry, we couldn't find this company"}
          message={(
            <>
              If you think this is a mistake, contact the labs team at
              {" "}
              <Link href="mailto:labs@valorep.com?subject=Missing Company">
                labs@valorep.com
              </Link>
            </>
          )}
        />
      </Box>
    );
  }

  if (isLoading || !isAuthenticated || !id) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box>
      <Switch>
        <Redirect exact from="/org/:id" to="/org/:id/summary" />
        <Redirect exact from="/:slug/org/:id" to="/:slug/org/:id/summary" />
      </Switch>
      <OrgHeader
        company={company}
        currentTab={currentTab}
        onTabChange={onTabChange}
      />
      <Box>
        <Helmet>
          <title>{company.name}</title>
          <meta property="og:url" content={company.logoUrl} />
          <meta property="og:title" content={company.name} />
          <meta
            property="og:description"
            content={`See ${company.logoUrl} on Insights`}
          />
        </Helmet>
        <Route
          exact
          path="/:slug?/org/:id/summary"
          render={(props) => (
            <OrganizationSummary {...props} company={company} id={id} />
          )}
        />
        <Route
          exact
          path={["/:slug?/org/:id/employees"]}
          render={(props) => (
            <OrganizationEmployees {...props} company={company} id={id} />
          )}
        />
      </Box>
    </Box>
  );
}

export default Organization;
