/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from "@material-ui/core";
import Helmet from "react-helmet";
import {
  CompetitorTable, CenteredCircularProgress, ButtonTabs, Joyride, mapToExcel,
} from "@valor-labs/marble";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { useHistory } from "react-router-dom";
import { ViewColumnOutlined, GetApp } from "@material-ui/icons";
import xlsx from "json-as-xlsx";
import {
  getCompetitors,
  getOrg,
  getCompetitorTimeData,
} from "../../api/Pofoco";
import { tabs } from "./data";
import { graphSteps, tableSteps } from "./joyride";
import Graphs from "./Graphs";
import { useAuth } from "../../hooks/useAuth";

export function HomePage() {
  const { user, isLoading } = useAuth();
  const [openDrawer, setDrawerOpen] = useState(false);
  const history = useHistory();
  const [view, setView] = useQueryParam(
    "graph",
    withDefault(StringParam, "graph"),
  );

  const { isLoading: isLoadingCompany, data: company = {} } = useQuery(
    ["Organization", user?.valorId],
    async () => {
      if (!user?.valorId) return {};
      const response = await getOrg(user?.valorId);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
      enabled: Boolean(user?.valorId),
    },
  );

  const { isLoading: isLoadingCompetitors, data: competitors = [] } = useQuery(
    ["Competitors", user?.valorId],
    async () => {
      if (!user?.valorId) return [];
      const response = await getCompetitors([user?.valorId]);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
      enabled: Boolean(user?.valorId),
    },
  );

  const { isLoading: isLoadingHistory, data: histories } = useQuery(
    ["histories", user?.valorId],
    async () => {
      if (!user?.valorId) return [];
      const response = await getCompetitorTimeData([
        user?.valorId,
      ]);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  const combinedLoad = isLoading || isLoadingCompany || isLoadingCompetitors || isLoadingHistory;
  if (combinedLoad) {
    return <CenteredCircularProgress />;
  }

  return (
    <Box>
      <Helmet>
        <title>{`${company.name} - Insights Competitor Dashboard`}</title>
        <meta
          property="og:title"
          content={`${company.name} - Insights Competitor Dashboard`}
        />
        <meta
          property="og:description"
          content={`Compare KPIs for ${company.name} and its competitors on Insights`}
        />
      </Helmet>
      <Box>
        <Typography variant="h3">
          {company.name}
          {" "}
          Competitor Dashboard
        </Typography>
      </Box>

      <Box>
        <Grid container style={{ marginBottom: "1%" }}>
          <Grid item md={6} container justifyContent="flex-end">
            <ButtonTabs
              id="view-selector"
              width="208px"
              options={tabs}
              onClick={(option) => setView(option.key)}
              activeKey={view}
            />
          </Grid>
          <Grid item md={6} container justifyContent="flex-end">
            {view === "table" && (
            <Button
              id="edit-columns"
              style={{ marginRight: "5px", height: "30px" }}
              variant="outlined"
              color="secondary"
              onClick={() => setDrawerOpen(!openDrawer)}
              startIcon={<ViewColumnOutlined />}
            >
              EDIT COLUMNS
            </Button>
            )}
            <Tooltip title="Export to Excel" placement="top">
              <IconButton
                id="export"
                style={{ height: "30px", width: "30px" }}
                onClick={() => xlsx(mapToExcel(competitors), {
                  fileName: `${company.name} Competitors KPIS`, // Name of the resulting spreadsheet
                  extraLength: 0, // A bigger number means that columns will be wider
                  writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
                })}
              >
                <GetApp />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        {view === "table" ? (
          <CompetitorTable
            currentValorId={user?.valorId}
            openDrawer={openDrawer}
            setDrawerOpen={setDrawerOpen}
            key="needle"
            data={competitors}
            initialColumns={[
              "company",
              "description",
              "secondMeasureCustomer",
              "secondMeasureTrends",
              "linkedIn",
              "webTraffic",
              "socialTraffic",
            ]}
            bannedColumns={["sourced"]}
            navigate={history.push}
          />
        ) : (
          <Graphs
            company={company}
            histories={histories}
            competitors={competitors}
          />
        )}
      </Box>
      <Joyride
        title="InsightsGraph"
        steps={graphSteps}
        run={view === "graph" && !(combinedLoad)}
        continuous
        disableScrolling
      />
      <Joyride
        title="InsightsTable"
        steps={tableSteps}
        run={view === "table" && !(combinedLoad)}
        continuous
        disableScrolling
      />
    </Box>
  );
}

export default HomePage;
