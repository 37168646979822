import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { valorMUITheme } from "@valor-labs/marble";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <ThemeProvider theme={valorMUITheme}>
    <App />
  </ThemeProvider>,
  document.getElementById("root"),
);

reportWebVitals(console.log);
