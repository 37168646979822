/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import { useTheme, Typography } from "@material-ui/core";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useWindowDimensions } from "../../../hooks";
import { concatFirstLetters, getFunc } from "../../../utils";

interface Props {
  metric?: {
    id: string,
    label: string,
    formatter: string
  };
  metricAccessors?: object;
  height?: number;
  competitors?: unknown[];
  setHighlightedCompany?(co: str): void;
  highlightedCompany?: string;
  margin?: object;
}

export function CompetitorBarChart(props: Props) {
  const {
    metric,
    metricAccessors,
    competitors,
    height,
    setHighlightedCompany,
    highlightedCompany,
    margin,
  } = props;
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const onClickBar = (e) => {
    if (e) {
      const clickedCompany = competitors[e.activeTooltipIndex].name;
      setHighlightedCompany(
        highlightedCompany === clickedCompany ? "" : clickedCompany,
      );
    }
  };

  const formatLabel = (value) => {
    if (
      value.length > 20
      && (width < 1500 || competitors.length > 4)
    ) {
      return concatFirstLetters(value);
    }
    return value;
  };

  return (
    <>
      <Typography
        variant="h6"
        id={`bar-${metric.label}`}
        color="textPrimary"
        style={{ textAlign: "center", height: "1.5rem" }}
      >
        {metric.label}
      </Typography>
      <Typography
        variant="subtitle2"
        id={`barsub-${metric.subtitle}`}
        color="textPrimary"
        style={{ textAlign: "center", height: "1.5rem" }}
      >
        {metric.subtitle}
      </Typography>
      <ResponsiveContainer width="99%" height={height}>
        <BarChart
          id={metric.label}
          margin={margin}
          cursor="pointer"
          onClick={onClickBar}
          data={competitors}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            interval={0}
            dy={8}
            maxLines={3}
            tickFormatter={formatLabel}
            height={100}
            angle={45}
            textAnchor="start"
          />
          <YAxis tickFormatter={getFunc[metric.formatter]} />
          <ChartTooltip
            formatter={(value) => getFunc[metric.formatter](value)}
            cursor={{ fill: theme.palette.blue.highlight }}
          />
          <Bar name={metric.label} dataKey={metricAccessors[metric.id]}>
            <LabelList
              style={{
                fill: "#191919",
              }}
              dataKey={metricAccessors[metric.id]}
              formatter={getFunc[metric.formatter]}
              position="top"
            />
            {competitors.map((company) => (
              <Cell
                style={{
                  fill:
                    company.name === highlightedCompany
                      ? theme.palette.primary.main
                      : "#ADADAD",
                }}
                key={metric.id}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

CompetitorBarChart.propTypes = {
  metric: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    formatter: PropTypes.string.isRequired,
  }),
  metricAccessors: PropTypes.object,
  height: PropTypes.number,
  competitors: PropTypes.array,
  setHighlightedCompany: PropTypes.func,
  highlightedCompany: PropTypes.string,
  margin: PropTypes.object,
};

export default CompetitorBarChart;
