import {
  ThumbUpOutlined,
  ThumbDownOutlined,
  IndeterminateCheckBox,
  Stars,
} from "@material-ui/icons";

export const FrameworkChoiceColorIconMapping = {
  green: ThumbUpOutlined,
  blue: Stars,
  yellow: IndeterminateCheckBox,
  red: ThumbDownOutlined,
  gray: IndeterminateCheckBox,
};

export default { FrameworkChoiceColorIconMapping };
