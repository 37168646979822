import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayUtils from "@date-io/dayjs";
import getStartAndEndDate from "../../../utils/getStartAndEndDate";
import SingleSelect from "../../atoms/SingleSelect";
import DropdownInfo from "../../atoms/DropdownInfo";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      padding: 0,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 0,
      },
      "& .MuiInputBase-input": {
        padding: 0,
      },
    },
  },
});

const dateRangeDocs = (
  <>
    <h3>Filter By Date Range</h3>
    Date range holds a list of most used date filters.
    {" "}
    <br />
    {" "}
    <br />
    By selecting a date range the
    {" "}
    <i>Start Date</i>
    {" "}
    and
    {" "}
    <i>End Date</i>
    {" "}
    will be
    autopopulated.
    <br />
    <br />
    If you want to manually pick a
    {" "}
    <i>Start Date</i>
    {" "}
    and
    {" "}
    <i>End Date</i>
    {" "}
    select
    {" "}
    <strong>
      <i>Custom</i>
    </strong>
    {" "}
    option and the dates wll be enabled.
    <br />
  </>
);

const defaultStartDateDoc = (
  <>
    <h3>Filter By Start Date</h3>
    The histories values be greater than or equal to the
    {" "}
    <i>Start Date</i>
    .
    <br />
    <br />
  </>
);

const deafultEndDateDoc = (
  <>
    <h3>Filter By End Date</h3>
    The histories values be less than or equal to
    {" "}
    <i>End Date</i>
    .
    <br />
    <br />
  </>
);

const dateOptions = {
  custom: "Custom",
  last_6_months: "Last 6 Months",
  last_year: "Last year",
  last_2_years: "Last 2 years",
  all_time: "All Time",
};

// Leaving the functionality of custom date pickers in case it is resurrected as day/month picker
export function StartEndDates(props) {
  const {
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    dateRange,
    setDateRange,
    startDateDoc,
    endDateDoc,
    showPickers,
  } = props;
  const classes = useStyles();
  const disabledDate = dateRange !== "custom";

  const onChangeDateRange = (value) => {
    setDateRange(value);
    const o = getStartAndEndDate(value);
    setStartDate(o.startDate);
    setEndDate(o.endDate);
  };

  return (
    <>
      <DropdownInfo
        component={(
          <SingleSelect
            id="date-range"
            title="Date Range"
            value={dateRange}
            onChange={onChangeDateRange}
            options={dateOptions}
          />
        )}
        title={dateRangeDocs}
      />
      {showPickers
      && (
      <MuiPickersUtilsProvider utils={DayUtils}>
        <DropdownInfo
          component={(
            <KeyboardDatePicker
              className={classes.formControl}
              variant="inline"
              inputVariant="outlined"
              format="MMMM-DD-YYYY"
              margin="dense"
              id="date-picker-start-date"
              label={startDateLabel}
              value={startDate}
              disabled={disabledDate}
              onChange={setStartDate}
            />
          )}
          title={startDateDoc}
        />
        <DropdownInfo
          component={(
            <KeyboardDatePicker
              variant="inline"
              inputVariant="outlined"
              format="MMMM-DD-YYYY"
              margin="dense"
              id="date-picker-end-date"
              label={endDateLabel}
              // minDate={startDate}
              value={endDate}
              disabled={disabledDate}
              onChange={setEndDate}
              className={classes.root}
            />
          )}
          title={endDateDoc}
        />
      </MuiPickersUtilsProvider>
      )}
    </>
  );
}

StartEndDates.propTypes = {
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(dayjs),
  endDate: PropTypes.instanceOf(dayjs),
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  dateRange: PropTypes.string.isRequired,
  setDateRange: PropTypes.func.isRequired,
  startDateDoc: PropTypes.object,
  endDateDoc: PropTypes.object,
};

StartEndDates.defaultProps = {
  startDateLabel: "Start Date",
  endDateLabel: "End Date",
  startDateDoc: defaultStartDateDoc,
  endDateDoc: deafultEndDateDoc,
};

export default StartEndDates;
