import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import kebabCase from "lodash.kebabcase";
import OrgCard from "../../atoms/OrgCard";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    color: theme.palette.neutrals["30"],
  },
  cardTitleContainer: {
    padding: theme.spacing(2, 0),
    borderBottom: `1px solid ${theme.palette.neutrals["89"]}`,
  },
  cardContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
    },
  },
}));

interface Props {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode
}

export function TitledOrgCard(props: Props) {
  const { title, children, icon } = props;
  const classes = useStyles();
  return (
    <OrgCard>
      <Box className={classes.cardContainer} id={kebabCase(title)}>
        <Box className={classes.cardTitleContainer}>
          <Box className={classes.cardTitle}>
            {icon}
            <Typography variant="h6" style={{ flex: 1 }}>{title}</Typography>
          </Box>
        </Box>
        <Box className={classes.cardContent}>{children}</Box>
      </Box>
    </OrgCard>
  );
}

export default TitledOrgCard;
