import React, { useState } from "react";
import {
  Box,
  Typography,
  Collapse,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import { KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons";

// collapse from material ai, chevr
const useStyles = makeStyles((theme) => ({
  listHeader: {
    display: "flex",
    cursor: "pointer",
  },
  listContent: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
}));

interface Props {
  title: string;
  children: React.ReactElement;
  openByDefault?: boolean;
  color?: string;
  fontWeight?: string;
}

export function CollapseList(props: Props) {
  const {
    title, children, openByDefault = false, color = "", fontWeight = "",
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(openByDefault || false);

  return (
    <Box paddingBottom={2}>
      <Box
        className={classes.listHeader}
        color={color}
        onClick={() => setOpen(!open)}
      >
        {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        <Typography
          style={{ fontWeight: fontWeight || theme.typography.fontWeightBold }}
        >
          {title}
        </Typography>
      </Box>
      <Collapse in={open}>
        <Box className={classes.listContent}>{children}</Box>
      </Collapse>
    </Box>
  );
}
export default CollapseList;
