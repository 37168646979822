/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Drawer,
  makeStyles,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Theme,
  createStyles,
  Badge,
} from "@material-ui/core";
import {
  AccountBalance,
  Build,
  BusinessCenter,
  ChevronLeft,
  ChevronRight,
  ContactMail,
  FilterList,
  FindInPageOutlined,
  FormatListBulleted,
  Help,
  Home,
  Map,
  Notifications,
  PanTool,
  People,
  PlaylistAddCheck
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import Bitcoin from "../../atoms/Icons/Bitcoin";
import Notable from "../../atoms/Icons/Notable";
import { MARKET_MAPS_ROLE_ID, VPO_ROLE_IDS, MANAGEMENT_TIER_ROLE_IDS, SOURCING_LIST_IDS } from "../../../constants/Roles";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  noLinkStyle: {
    textDecoration: "none",
    color: "inherit",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  drawerMobile: {
    display: "flex",
    flexDirection: "row",
  },
  navDrawer: {
    height: "100%",
    padding: `${theme.spacing(9)}px 0px 0px 0px`,
  },
}));

const options = (numTasks: number) => [
  {
    name: "Home",
    icon: <Home />,
    path: "home",
  },
  {
    name: "Management Tools",
    icon: <BusinessCenter />,
    path: "management-dashboard",
  },
  {
    name: "Tasks & Alerts",
    icon: <Badge badgeContent={numTasks} max={99} color="error"><Notifications /></Badge>,
    path: "tasks",
  },
  {
    name: "Recent Meetings",
    icon: <ContactMail />,
    path: "recent-meetings",
  },
  {
    divider: true,
  },
  {
    name: "Deal Search",
    icon: <FindInPageOutlined />,
    path: "search",
  },
  {
    name: "Pipeline Signals",
    icon: <FilterList />,
    path: "pipeline-signals",
  },
  {
    name: "Sourcing Lists",
    icon: <PlaylistAddCheck />,
    path: "sourcing-lists",
  },
  {
    divider: true,
  },
  {
    name: "Market Maps",
    icon: <Map />,
    path: "market-maps",
  },
  { divider: true },
  {
    name: "Valor People Ops",
    icon: <People />,
    path: "valor-people-ops",
  },
  { divider: true },
  {
    name: "Crypto",
    icon: <Bitcoin />,
    path: "crypto",
  },
  {
    divider: true,
  },
  {
    name: "Company Watchlist",
    icon: <FormatListBulleted />,
    path: "following",
  },
  {
    name: "Compliance",
    icon: <PanTool />,
    path: "compliance-and-permissions",
  },
  // {
  //   name: "Verity",
  //   icon: <Cloud />,
  //   path: "verity",
  // },
  {
    name: "Tools",
    icon: <Build />,
    path: "tools",
  },
  {
    name: "Help",
    icon: <Help />,
    path: "help",
  },
];

interface Props {
  drawerOpen: boolean;
  handleDrawerOpen(...args: unknown[]): unknown;
  handleDrawerClose(...args: unknown[]): unknown;
  history: {location: any };
  user: any, // TODO change to user type
  numTasks: int
}

export function NavDrawer(props: Props) {
  const {
    drawerOpen, handleDrawerOpen, handleDrawerClose, history, user, numTasks,
  } = props;
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const [selectedPath, setSelectedPath] = useState("");
  const { location } = history;

  useEffect(() => {
    setSelectedPath(location.pathname.split("/")[1]);
  }, [location.pathname]);

  const listOptions = () => options(numTasks).map((option, i) => {
    if (
      (option.name === "Compliance" && !MANAGEMENT_TIER_ROLE_IDS.has(user.roleId))
      || (option.name === "Valor People Ops" && !VPO_ROLE_IDS.has(user.roleId))
      || (option.name !== "Market Maps" && user.roleId === MARKET_MAPS_ROLE_ID)
      || (option.name === "Management Tools" && !MANAGEMENT_TIER_ROLE_IDS.has(user.roleId))
      || (option.name === "Sourcing Lists" && !SOURCING_LIST_IDS.has(user.roleId))
    ) {
      return null;
    }
    return option.divider ? (
      <Divider key={i} />
    ) : (
      <Link className={classes.noLinkStyle} to={(location) => ({ ...location, pathname: `/${option.path}` })}>
        <ListItem
          key={option.path}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
          selected={option.path === selectedPath}
          button
        >
          <ListItemIcon
            className={clsx({ [classes.icon]: option.path === selectedPath })}
          >
            {option.icon}
          </ListItemIcon>
          <ListItemText primary={option.name} />
        </ListItem>
      </Link>

    );
  });

  if (isSmDown) {
    return (
      <Drawer open={drawerOpen} onClose={handleDrawerClose}>
        <Box className={classes.drawerMobile}>
          <Box className={classes.navDrawer}>{listOptions()}</Box>
        </Box>
      </Drawer>
    );
  }
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Divider />
      {listOptions()}
    </Drawer>
  );
}

export default NavDrawer;
