import React from "react";
import { Typography } from "@material-ui/core";

const style = {
  textAlign: "left",
};
export const graphSteps = [
  {
    title: "Insights by Valor Labs",
    placement: "center",
    target: "body",
    content:
    (
      <Typography style={style}>
        Welcome to Insights by Valor Labs
        <br />
        <b>You can advance these steps quickly using the Enter Key</b>
      </Typography>
    ),
  },
  {
    title: "Identified Competitors",
    target: "#listed-competitor-1",
    content:
      (
        <Typography style={style}>
          We&apos;ve identified some competitors you may be interested in tracking. You can explore more information about them directly by clicking on their logo. If you&apos;d like us to expand this set please email
          &nbsp;
          <a href="mailto:labs@valorep.com">labs@valorep.com</a>
        </Typography>
      ),
  },
  {
    title: "The Graph View",
    target: "#Insights-graph",
    placement: "center",
    content:
      "You can visualize a number of metrics for each competitor. You can also see the historical data for each metric.",
  },
  {
    title: "Graph Options",
    target: "#graph-control",
    placement: "auto",
    content:
      "You can select those metrics and other options here.",
  },
  {
    title: "Line Chart Options",
    target: "#chart-represents-select",
    placement: "bottom",
    content:
      "On line charts you can change whether or not you want to see absolute values or relative growth multiple.",
  },
  {
    title: "You can also see all this data in a table",
    target: "#view-selector",
    placement: "bottom",
  },
];

export const tableSteps = [
  {
    title: "The Table View",
    placement: "center",
    target: "body",
    content:
    (
      <Typography style={style}>
        Data can also be viewed as a large table
        <br />
        <b>You can advance these steps quickly using the Enter Key</b>
      </Typography>
    ),
  },
  {
    title: "Edit Columns",
    placement: "bottom-start",
    target: "#edit-columns",
    content:
    (
      <Typography style={style}>
        You can edit columns you want to see here
      </Typography>
    ),
  },
  {
    title: "Export to Excel",
    placement: "bottom-start",
    target: "#export",
    content:
    (
      <Typography style={style}>
        You can also download this data to excel
      </Typography>
    ),
  },
  {
    title: "Rearrange Tabs",
    placement: "bottom-start",
    target: "#header-groups-1",
    content:
    (
      <Typography style={style}>
        You can click and drag to rearrange these header groups
      </Typography>
    ),
  },

];

const steps = { graphSteps, tableSteps };
export default steps;
