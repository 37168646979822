import React from "react";
import { Avatar, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import { getInitials } from "../../../utils/general";
import getThemeColor from "../../../utils/getThemeColor";

export function UserAvatar(props) {
  const { user, useLargeImage, displayTooltip } = props;

  const profileImage = useLargeImage
    ? user?.largeProfilePicture
    : user?.profilePicture;

  const title = displayTooltip ? user.name : "";

  return user?.profilePicture ? (
    <Tooltip key={`tooltip-${user.id}`} title={title} placement="top">
      <Avatar alt={user.name} src={profileImage} {...props} />
    </Tooltip>
  ) : (
    <Tooltip key={`tooltip-${user.id}`} title={title} placement="top">
      <Avatar
        alt={user.name}
        {...props}
        style={{
          backgroundColor: getThemeColor(user?.name),
          cursor: "pointer",
        }}
      >
        {getInitials(user.name)}
      </Avatar>
    </Tooltip>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  useLargeImage: PropTypes.bool,
  displayTooltip: PropTypes.bool,
};

UserAvatar.defaultProps = {
  useLargeImage: false,
  displayTooltip: false,
};

export default UserAvatar;
