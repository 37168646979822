/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { CompanyAvatar } from "../CompanyAvatar";

const useStyles = makeStyles((theme) => ({
  companyName: {
    color: theme.palette.neutrals["13"],
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: ".875rem",
  },
  companyDomain: {
    color: theme.palette.neutrals["13"],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: ".75rem",
    textDecoration: "none",
  },
}));

export function CompanyCell({ companyName, valorId, domain, logoUrl, linkState }) {
  const classes = useStyles();
  
  return (
    <Box
      style={{
        display: "flex",
        gap: "24px",
        cursor: "pointer",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Link to={{ pathname:`/org/${valorId}`, state: linkState}}>
        <CompanyAvatar src={logoUrl} name={companyName} size="40" />
      </Link>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Link className={classes.companyName} to={{ pathname:`/org/${valorId}`, state: linkState}}>
          {companyName}
        </Link>
        <a
          className={classes.companyDomain}
          href={`https://www.${domain}`}
        >
          {domain}
        </a>
      </Box>
    </Box>
  );
}

export default CompanyCell;
