/* eslint-disable no-restricted-globals */
import React from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExitToApp from "@material-ui/icons/ExitToApp";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: "100%",
    display: "flex",
    justfiyContent: "space-between",
  },
  menuIcon: {
    color: theme.palette.neutrals["30"],
  },
  menuText: {
    paddingLeft: theme.spacing(2),
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
  },
}));

export function UserMenu(props) {
  const {
    user, logOut, anchorEl, onClose,
  } = props;
  const classes = useStyles();

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={() => location.reload()}>
        <GetAppIcon className={classes.menuIcon} />
        <Box>
          <Typography variant="subtitle1" className={classes.menuText}>
            Fetch App Update
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={() => logOut(user)}>
        <ExitToApp className={classes.menuIcon} />
        <Box>
          <Typography variant="subtitle1" className={classes.menuText}>
            Log Out
          </Typography>
        </Box>
      </MenuItem>
    </Menu>
  );
}

UserMenu.propTypes = {
  user: PropTypes.shape({ name: PropTypes.string }).isRequired,
  logOut: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
UserMenu.defaultProps = {
  anchorEl: null,
};
export default UserMenu;
