import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import CategorySelect from "../../molecules/CategorySelect";
import MMDimensionEditor from "../../molecules/MMDimensionEditor";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(2),
  },
  categoryBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  },
  error: {
    color: theme.palette.error.main,
  },
  column: {
    width: theme.spacing(25),
  },
  row: {
    marginLeft: theme.spacing(25),
  },
  selector: {
    width: theme.spacing(20),
  },
}));

const validateDimension = (dimension) => {
  const errors = [];
  if (dimension.name === "") errors.push("A dimension needs a title");
  if (!dimension.values.every((value) => value.name !== "")) {
    errors.push("Some dimensions are missing names");
  }
  return errors;
};

interface Dimension {
  name?: string,
  id?: string,
  values?: {
    name?: string,
    id?: string
  }
}
interface Props {
  title: string;
  dimensions: Dimension[];
  onSubmit:
    (
      rowId: string,
      columnId: string,
      title: string,
      dimensions: Dimension[],
      investmentCategory: string,
      segment :string
    )=> void;
}

export function EditMarketMap(props: Props) {
  const { dimensions, title, onSubmit } = props;
  const classes = useStyles();
  const nameInputEl = useRef(null);

  const [row, setRow] = useState(null);
  const [column, setColumn] = useState(null); // this should be an index only

  const [investmentCategory, setInvestmentCategory] = useState("");
  const [segment, setSegment] = useState("");

  const [statefulTitle, setStatefulTitle] = useState(title);
  const [statefulDimensions, setStatefulDimensions] = useState(dimensions);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setStatefulTitle(title);
    setStatefulDimensions(dimensions);
  }, [title, dimensions]);

  const onDimensionSelectChange = (chosen, valueSetter) => {
    const changedDimension = dimensions.find(
      (dimension) => dimension.id === chosen.id,
    );
    valueSetter(changedDimension.id);
    setErrors([]);
  };

  const onDimensionReorder = (changedDimension) => {
    const copy = [...statefulDimensions];
    const foundIndex = copy.findIndex(
      (dimension) => dimension.id === changedDimension.id,
    );
    copy[foundIndex] = changedDimension;
    setStatefulDimensions(copy);
    setErrors([]);
  };

  const onSubmitChange = () => {
    let errorList = [];
    if (column === row) errorList.push("Row and Column cant be the same dimension");
    if (statefulTitle === "") errorList.push("The Market Map Title cannot be blank");

    // It would be more helpful to actually highlight
    // the forms and validate on a per form basis as well as
    // navigate the user to focus where there are issues but since this
    // is a "guts" component that partners most likely wont interact with
    // the UX can be sacrificed in lieu of speed
    statefulDimensions.forEach((dimension) => {
      errorList = [...errorList, ...validateDimension(dimension)];
    });

    if (!investmentCategory && !segment) {
      errorList.push("You must set Investment Category and Segment.");
    }
    if (errorList.length) {
      return setErrors(errorList);
    }

    return onSubmit({
      rowId: row,
      columnId: column,
      title: statefulTitle,
      dimensions: statefulDimensions,
      investmentCategory,
      segment,
    });
  };

  const dimensionMultiSelect = (name, axisValue, setAxis) => {
    const dimension = statefulDimensions.find((x) => x.id === axisValue) || {
      name: "",
    };
    return (
      <FormControl className={classes.selector}>
        <InputLabel>{name}</InputLabel>
        <Select
          value={dimension}
          renderValue={(value) => value.name || ""}
          onChange={(event) => onDimensionSelectChange(event.target.value, setAxis)}
        >
          {statefulDimensions.map((statefulDimension) => (
            <MenuItem key={statefulDimension.id} value={statefulDimension}>
              {statefulDimension.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const rowSelect = dimensionMultiSelect("Row", row, setRow);
  const columnSelect = dimensionMultiSelect("Column", column, setColumn);

  return (
    <Paper className={classes.root}>
      <TextField
        required
        inputRef={nameInputEl}
        label="Market Map Title"
        variant="outlined"
        helperText="The title of your Market Map"
        value={statefulTitle}
        onChange={(event) => setStatefulTitle(event.target.value)}
      />
      {/* uniquify the errors array then display. */}
      {errors
        .reduce(
          (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
          [],
        )
        .map((error) => (
          <Typography key={error} className={classes.error} component="h6">
            {error}
          </Typography>
        ))}
      <Box className={classes.categoryBox}>
        <CategorySelect
          onICChange={setInvestmentCategory}
          onSegmentChange={setSegment}
        />
      </Box>
      <Box display="flex">
        {rowSelect}
        {columnSelect}
      </Box>
      <Box className={classes.row}>
        {!!column && (
          <MMDimensionEditor
            isHorizontal
            dimension={statefulDimensions.find(
              (dimension) => dimension.id === column,
            )}
            onChange={onDimensionReorder}
          />
        )}
      </Box>
      <Box className={classes.column}>
        {!!row && (
          <MMDimensionEditor
            dimension={statefulDimensions.find(
              (dimension) => dimension.id === row,
            )}
            onChange={onDimensionReorder}
          />
        )}
      </Box>
      {!!row && !!column && !!segment && !!investmentCategory && (
        <Button
          disabled={!!errors.length}
          variant="contained"
          color="primary"
          onClick={onSubmitChange}
        >
          Submit Map
        </Button>
      )}
    </Paper>
  );
}

export default EditMarketMap;
