/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import {
  Box,
  ClickAwayListener,
  Popover,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useTable } from "react-table";
import { formatBigDollars, TitledOrgCard } from "@valor-labs/marble";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "580px",
    overflowY: "auto",
  },
  roundChip: {
    display: "inline-block",
    padding: theme.spacing(1, 2),
    color: theme.palette.neutrals["100"],
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    cursor: "pointer",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "280px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    boxShadow: theme.shadows[4],
  },
}));

export function FundingRoundTableCard({ fundingRounds, fundingRoundLink }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [highlightedRound, setHighlightedRound] = useState([]);

  const onClosePopover = () => {
    setAnchorEl(null);
    setHighlightedRound(null);
  };

  const open = Boolean(anchorEl);

  const columns = useMemo(
    () => [
      {
        Header: "Round Name/Type",
        accessor: "roundName",
        width: 128,
        Cell: ({ row }) => {
          const { roundName, dealDate, investorNames } = row.original;

          const makeClickable = [dealDate, investorNames].some((item) => Boolean(item));
          const handleClick = (event) => {
            if (makeClickable) {
              setAnchorEl(event.currentTarget);
              setHighlightedRound(row.original);
            } else {
              setHighlightedRound(null);
            }
          };
          return (
            <Box onClick={handleClick} className={classes.roundChip}>
              <Typography variant="subtitle2">
                {roundName ?? "N/A"}
              </Typography>
            </Box>
          );
        },
      },
      {
        Header: "Post-Money Valuation",
        accessor: "postValuation",
        width: 64,
        Cell: ({ value }) => formatBigDollars(value),
      },
      {
        Header: "Round Size",
        accessor: "roundSize",
        width: 64,
        Cell: ({ value }) => formatBigDollars(value),
      },
    ],
    [classes.roundChip],
  );

  const {
    rows,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    headerGroups,
  } = useTable({
    columns,
    data: fundingRounds,
  });

  if (!fundingRounds?.length) {
    return <TitledOrgCard title="Funding Rounds" />;
  }

  return (
    <TitledOrgCard title="Funding Rounds">
      <Typography variant="body2">
        Data sourced from&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={fundingRoundLink}>
          {fundingRounds[0].source}
        </a>
      </Typography>
      <div className={classes.content}>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    key={column.id}
                    className={classes.headerTitle}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className={classes.body} {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={onClosePopover}
        >
          <Popover
            open={open}
            onClose={onClosePopover}
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: 32,
              horizontal: "center",
            }}
          >
            <Box display="flex" flexDirection="column" width="100%">
              {highlightedRound?.dealDate && (
                <>
                  <Typography variant="subtitle1">Deal Date</Typography>
                  <Typography variant="subtitle2">
                    {dayjs(highlightedRound?.dealDate).format("MMM DD, YYYY")}
                  </Typography>
                </>
              )}
              {Boolean(highlightedRound?.investorNames?.length) && (
                <>
                  <Typography variant="subtitle1">Investors</Typography>
                  <Typography variant="subtitle2">
                    {highlightedRound?.investorNames?.join(", ")}
                  </Typography>
                </>
              )}
            </Box>
          </Popover>
        </ClickAwayListener>
      </div>
    </TitledOrgCard>
  );
}

export default FundingRoundTableCard;
