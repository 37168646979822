/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from "react";
import {
  TextField, Box, Typography, Button, Theme, createStyles,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import StickyTable from "../../atoms/StickyTable";
import CategorySelect from "../../molecules/CategorySelect";

const useStyles = makeStyles((theme: Theme) => createStyles({
  section: {
    padding: `${theme.spacing(2)}px 0`,
  },
  buttonSection: {
    display: "flex",
    justifyContent: "space-between",
    width: theme.spacing(30),
    padding: `${theme.spacing(2)}px 0`,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

interface Company {
  companyName: string;
  domain: string;
}

interface DimensionValue {
  id: string,
  name: string
}
interface Dimensions {
  id: string;
  name: string;
  values: DimensionValue[]
}

interface Props {
  title: string;
  badCompanies: Company[];
  noOfCompanies: number;
  onMapCancel: () => void;
  onMapSubmit: (data: any) => void;
  dimensions: Dimensions[];
}

export function VerifyMarketMap(props: Props) {
  const {
    title,
    noOfCompanies,
    badCompanies = [],
    dimensions = [],
    onMapSubmit,
    onMapCancel,
  } = props;
  const classes = useStyles();
  const nameInputEl = useRef(null);

  const [errors, setErrors] = useState("");
  const [mapTitle, setMapTitle] = useState(title);
  const [investmentCategory, setInvestmentCategory] = useState("");
  const [segment, setSegment] = useState("");
  const [disableForm, setFormDisabled] = useState(false);

  useEffect(() => {
    if (!noOfCompanies || !dimensions.length) {
      const issueList = [];
      // eslint-disable-next-line no-unused-expressions
      !noOfCompanies && issueList.push("companies");
      !dimensions.length && issueList.push("dimensions");
      setFormDisabled(true);
      setErrors(
        `There was an error parsing your CSV. We did not find any valid ${issueList.join(
          ", ",
        )} in the CSV.`,
      );
    }
  }, [title, dimensions, noOfCompanies]);

  const handleChange = (event) => {
    setErrors("");
    setMapTitle(event.target.value);
  };

  const onSubmit = () => {
    if (mapTitle === "") {
      return setErrors(
        "No title given, a title is required to submit a market map",
      );
    }
    if (segment === "" || investmentCategory === "") {
      return setErrors(
        "No full categorization given, please select an investment category and segment",
      );
    }
    try {
      return onMapSubmit({
        title: nameInputEl.current.value,
        segment,
        investmentCategory,
      });
    } catch (err) {
      setErrors(
        "There was an error on upload, please try again. If this problem persists contact the labs team.",
      );
      return err;
    }
  };

  const badCompanyColumns = [
    { id: "name", label: "Company Name", minWidth: 100 },
    { id: "domain", label: "Domain Name", minWidth: 150 },
  ];

  const dimensionColumns = [
    { id: "name", label: "Dimension Name", minWidth: 100 },
    { id: "values", label: "Dimension Values", minWidth: 150 },
  ];
  const dimensionRows = dimensions.map((dimension) => ({
    name: dimension.name,
    values: dimension.values.map((value) => value.name).join(", "),
  }));

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="subtitle1"> Verify Market Map Details </Typography>
      {Boolean(errors.length) && (
        <Typography className={classes.error} component="h6">
          {errors}
        </Typography>
      )}
      <Typography className={classes.section} component="span" variant="body1">
        We were able to parse&nbsp;
        <strong>{noOfCompanies}</strong>
        &nbsp;companies from your csv.
      </Typography>
      <TextField
        required
        disabled={disableForm}
        inputRef={nameInputEl}
        label="Market Map Title"
        variant="outlined"
        helperText="The title of your Market Map"
        value={mapTitle}
        onChange={handleChange}
      />
      <CategorySelect
        onICChange={setInvestmentCategory}
        onSegmentChange={setSegment}
      />
      {Boolean(Object.keys(dimensions).length) && (
        <Box className={classes.section}>
          <Typography variant="h5"> Dimensions</Typography>
          <StickyTable rows={dimensionRows} columns={dimensionColumns} />
        </Box>
      )}
      {Boolean(badCompanies.length) && (
        <Box className={classes.section}>
          <Alert severity="warning">
            <AlertTitle>Omitted Companies</AlertTitle>
            Please check the domain names from your csv and resubmit or proceed
            &nbsp;
            <strong>without</strong>
            &nbsp;these&nbsp;
            {badCompanies.length}
            &nbsp; companies included in your map.
          </Alert>
          <StickyTable rows={badCompanies} columns={badCompanyColumns} />
        </Box>
      )}
      <Box className={classes.buttonSection}>
        <Button variant="contained" color="secondary" onClick={onMapCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={disableForm}
          color="primary"
          onClick={onSubmit}
        >
          Submit Map
        </Button>
      </Box>
    </Box>
  );
}

export default VerifyMarketMap;
