/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */ // TODO
import React, { useState, useContext } from "react";
import clsx from "clsx";
import {
  BarChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import {
  Box,
  Link,
  Typography,
  Popover,
  useTheme,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import {
  ErrorOutlineOutlined,
  ViewHeadline,
} from "@material-ui/icons";
import { formatBigDollars } from "../../../utils/numberFormat";
import { InvestmentProductMappings } from "../../../constants/InvestmentProductsMapping";
import { FrameworkChoiceColorIconMapping } from "../../../constants/FrameworkChoiceColorIconMapping";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  message: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  errorMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: theme.spacing(20),
  },
  errorIcon: {
    color: theme.palette.neutrals["43"],
    fontSize: theme.spacing(6),
  },
  userHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "center",
  },
  attributeName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  name: {
    color: theme.palette.neutrals["13"],
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "14px",
    textAlign: "center",
  },
  date: {
    color: theme.palette.neutrals["30"],
    textAlign: "center",
    width: "100%",
    fontSize: "12px",
  },
  noSurveys: {
    alignSelf: "flex-start",
    margin: theme.spacing(2),
  },
  paper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "420px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    boxShadow: theme.shadows[5],
  },
  subSection: {
    padding: theme.spacing(0.5, 0, 1, 0),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "left",
  },
  leftColumnSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: theme.spacing(22),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0, 0.5),
  },
  leftColumnSubSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: theme.spacing(22),
    padding: theme.spacing(0, 0.5),
  },
  frozenTop: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.drawer - 1,
    backgroundColor: theme.palette.background.paper,
  },
  frozenLeft: {
    position: "sticky",
    left: 0,
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
  },
  popOver: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "calc(100% - 32px)",
    height: theme.spacing(10),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  valorHugIcon: {
    color: theme.palette.blue.main,
  },
}));

const useItemStyles = makeStyles((theme) => ({
  root: ({ row, column, color }) => ({
    gridRow: row,
    gridColumn: column,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette?.[color]?.main,
    "&:hover": {
      backgroundColor: theme.palette?.[color]?.dark,
    },
  }),
  iconStyle: ({ color }) => ({
    color:
      color === "red" || color === "green" || color === "gray" || color === "blue"
        ? theme.palette.neutrals["100"]
        : theme.palette.neutrals["30"],
  }),
}));

const useGridClasses = makeStyles((theme) => ({
  root: ({ columns }) => ({
    display: "grid",
    gridTemplateColumns: `176px repeat(${columns}, 128px)`,
    width: `calc(100vw - ${theme.spacing(20)})`,
    overflowX: "scroll",
    overflowY: "scroll",
    height: "80vh",
    borderRight: "1px solid #CCCCCC",
    "& > div": {
      borderTop: "1px solid #CCCCCC",
      borderLeft: "1px solid #CCCCCC",
    },
  }),
}));

export function GridItem(props) {
  const {
    row,
    column,
    commentary,
    color,
    answerData,
    question,
    onClick,
  } = props;
  const classes = useItemStyles({ row, column, color });
  let content = null;

  if (question.questionType === "PERCENTAGE_ESTIMATE") {
    return (
      <Box className={classes.root}>
        {commentary}
        %
      </Box>
    );
  }
  if (question.questionType === "QUARTER") {
    return (
      <Box className={classes.root}>
        {commentary}
      </Box>
    );
  }

  if (question.questionType === "DOLLAR_ESTIMATE") {
    content = (
      <Box className={classes.root}>
        {answerData === "N/A" ? answerData : formatBigDollars(answerData)}
        {Boolean(commentary?.length) && <ViewHeadline className={classes.iconStyle} />}
      </Box>
    );
  }

  if (question.questionType === "TEXT") {
    content = <ViewHeadline className={classes.iconStyle} />;
  }
  if (question.questionType === "RETURN_DISTRIBUTION") {
    const answerObj = JSON.parse(answerData);
    content = Object.keys(answerObj).reduce((a, b) => (answerData[a] > answerData[b] ? a : b));
  } else if (
    question.questionType === "MULTIPLE_CHOICE"
  ) {
    const Icon = FrameworkChoiceColorIconMapping?.[color];
    content = (
      <>
        <Icon className={classes.iconStyle} />
        {Boolean(commentary?.length) && <ViewHeadline className={classes.iconStyle} />}
      </>
    );
  }

  return (
    <Box className={classes.root} onClick={(event) => onClick(event, props)}>
      {content}
    </Box>
  );
}

export function FrameworkGrid(props) {
  const {
    responses, sections, status, takeSurvey, drawerContext,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const gridClasses = useGridClasses({ columns: responses.length });
  const [gridItemEl, setGridItemEl] = useState(null);
  const [gridItemData, setGridItemData] = useState(null);
  const SurveyDrawer = useContext(drawerContext);

  const openGridItemPopover = Boolean(gridItemEl);
  const ValorHugIcon = FrameworkChoiceColorIconMapping.blue;

  const handleClick = (event, data) => {
    if (data.answerData === "N/A" && !data.commentary) {
      event.stopPropagation();
      return;
    }
    setGridItemData(data);
    setGridItemEl(event?.currentTarget);
  };

  const handleClose = () => {
    setGridItemEl(null);
    setGridItemData(null);
  };

  const renderPopOverContent = ({
    color,
    text,
    commentary,
    answerData,
    question: { questionType },
  }) => {
    let questionSpecificVisualization = null;
    if (questionType === "MULTIPLE_CHOICE") {
      const Icon = FrameworkChoiceColorIconMapping[gridItemData?.color];
      const style = {
        border: `1px solid ${theme.palette?.[color]?.main}`,
      };
      questionSpecificVisualization = (
        <Box className={classes.popOver} style={style}>
          <Icon style={{ color: theme.palette?.[color]?.main, marginRight: theme.spacing(1) }} />
          {text}
        </Box>
      );
    }

    if (questionType === "RETURN_DISTRIBUTION") {
      const answerObj = JSON.parse(answerData)
      const chartData = Object.entries(answerObj).map((x) => ({
        label: x[0],
        value: x[1],
      }));
      questionSpecificVisualization = (
        <ResponsiveContainer height={348} width={300}>
          <BarChart
            data={chartData}
            barGap={2}
            layout="vertical"
            margin={{
              top: 10, right: 40, bottom: 10, left: 5,
            }}
          >
            <XAxis datakey="value" type="number" hide />
            <YAxis dataKey="label" type="category" minTickGap={0} />
            <Bar
              barSize={48}
              dataKey="value"
              fill={theme.palette.primary.main}
            >
              <LabelList
                offset={1}
                position="right"
                dataKey="value"
                formatter={(value) => `${value}%`}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    }

    return (
      <>
        {questionSpecificVisualization}
        {Boolean(commentary?.length) && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Typography className={classes.subSection} variant="body2">
              COMMENT
            </Typography>
            <Typography variant="body2">{commentary}</Typography>
          </Box>
        )}
      </>
    );
  };

  const getResponses = (answers, column) => {
    const responses = [];
    let row = 1;
    sections.forEach((section) => {
      row += 1;
      responses.push(
        <Box
          key={`cell-${row}-${column}`}
          style={{
            gridColumn: column,
            gridRow: row,
            borderLeftStyle: "none",
          }}
        />,
      );
      section.attributes.forEach((attribute) => {
        row += 1;
        const response = answers[attribute.id];
        responses.push(response ? (
          <GridItem
            key={`cell-${row}-${column}`}
            row={row}
            column={column}
            question={response.question}
            commentary={response.commentary}
            color={response.choice.color}
            text={response.choice.text}
            answerData={response.answerData}
            onClick={handleClick}
          />
        )
          : (
            <Box
              key={`cell-${row}-${column}`}
              style={{
                gridColumn: column,
                gridRow: row,
              }}
            />
          ));
      });
    });
    return responses;
  };

  const getLeftColumn = () => {
    const cells = [];
    let row = 1;

    sections.forEach((section) => {
      row += 1;
      cells.push(
        <Box
          key={`section-${section.name}`}
          className={clsx(classes.leftColumnSection, classes.frozenLeft)}
          style={{
            gridRow: row, gridColumn: 1, height: row === 1 && "76px",
          }}
        >
          {section.name}
        </Box>,
      );
      section.attributes.forEach((attribute) => {
        row += 1;
        cells.push(
          <Box
            key={`attribute-${attribute.id}`}
            className={classes.frozenLeft}
            style={{
              gridRow: row, gridColumn: 1, paddingLeft: "4px",
            }}
          >
            <Tooltip title={attribute.name} placement="top">
              <Typography className={classes.attributeName}>{attribute.name}</Typography>
            </Tooltip>
          </Box>,
        );
      });
    });
    return cells;
  };

  return (
    <Box className={classes.root}>
      {!responses.length && (
        <Box className={classes.message}>
          {takeSurvey && (
            <Box className={classes.errorMessage}>
              <ErrorOutlineOutlined className={classes.errorIcon} />
              <Typography variant="h4">Results Locked</Typography>
              <Typography variant="subtitle1">
                Please take your
                {" "}
                <Link
                  component="button"
                  onClick={() => {
                    SurveyDrawer.setSurveyDrawerState(true);
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    first survey
                  </Typography>
                </Link>
                {" "}
                to access results
              </Typography>
            </Box>
          )}
          {!takeSurvey && status === "success" && (
            <Typography style={{ padding: "24px", width: "100%" }} variant="h6">
              No Survey Responses
            </Typography>
          )}
        </Box>
      )}
      {Boolean(responses.length)
          && (
          <Box className={gridClasses.root}>
            {getLeftColumn()}
            {responses.map((response, idx) => (
              <React.Fragment key={response.id}>
                <div
                  className={clsx(classes.userHeader, classes.frozenTop)}
                  style={{ gridRow: "1", height: "148px", gridColumn: idx + 2 }}
                  id="user-grid-item"
                >
                  <Typography className={classes.name}>
                    {response.user.name}
                  </Typography>
                  <Typography className={classes.date}>
                    {dayjs(response.completedAt).utc().local().format("MM/DD/YYYY")}
                  </Typography>

                  <Typography style={{
                    padding: "4px 2px", width: "100%", height: "48px", textAlign: "center", fontSize: "12px",
                  }}
                  >
                    {response.surveyName}
                    {response.bearhug && (
                      <Tooltip title="Is Valor Hugged" placement="top">
                        <ValorHugIcon className={classes.valorHugIcon} />
                      </Tooltip>
                    )}
                  </Typography>
                  <Typography style={{
                    padding: "4px 2px", width: "100%", textAlign: "center", fontSize: "12px",
                  }}
                  >
                    {InvestmentProductMappings[response.department]}
                  </Typography>

                </div>
                {getResponses(response.answers, idx + 2)}
              </React.Fragment>
            ))}
          </Box>
          )}
      <Popover
        open={openGridItemPopover}
        anchorEl={gridItemEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: classes.paper,
        }}
        disableScrollLock
      >
        {gridItemData && renderPopOverContent(gridItemData)}
      </Popover>
    </Box>
  );
}

export default FrameworkGrid;
