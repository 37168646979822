import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
} from "@material-ui/core";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import ErrorPage from "./pages/Error";
import OrganizationPage from "./pages/Organization";
import Header from "./Header";
import { useAuth } from "./hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  appRoot: {
    display: "flex",
    overflowX: "hidden",
    msOverflowStyle: "none", /* IE and Edge */
    scrollbarWidth: "none", /* Firefox */
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  page: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    boxSizing: "border-box",
    width: "96%",
    height: "100vh",
    ...theme.defaultMargin,
  },
}));
export function AuthApp() {
  const history = useHistory();
  const classes = useStyles();
  const {
    user, isAuthenticated, logOut, isLoading: isAuthLoading,
  } = useAuth();

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated) {
      history.replace("/login");
    }
  }, [isAuthLoading, isAuthenticated, history.push, history]);

  useEffect(() => {
    if (isAuthenticated && !user?.valorId) {
      history.replace("/error");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthLoading, user, history.push, history]);

  return (
    <div className={classes.appRoot}>
      <CssBaseline />
      <div className={classes.page}>
        <div className={classes.toolbar} />
        <Header
          user={user}
          logoUrl={`${process.env.PUBLIC_URL}/images/logo.png`}
          logOut={logOut}
        />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/:slug?/org/:id/:section?" component={OrganizationPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/error" component={ErrorPage} />
        </Switch>
      </div>

    </div>
  );
}

export default AuthApp;
