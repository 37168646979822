import React, { useState } from "react";
import {
  Card, Button, FormControl, IconButton, InputAdornment, Input, InputLabel, Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { CenteredCircularProgress } from "@valor-labs/marble";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "center",
    width: "50%",
    minWidth: "320px",
    padding: theme.spacing(3),
  },
  input: {
    alignSelf: "center",
    width: "70%",
    minWidth: theme.spacing(30),
  },
}));

export function LoginPage() {
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { signIn, isLoading, loginError } = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signIn(email, password);
      setEmail("");
      setPassword("");
      history.push("/");
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        {isLoading
          ? <CenteredCircularProgress />
          : (
            <>

              <Typography variant="h4">Welcome to Insights</Typography>
              <Typography variant="h6">Please sign in</Typography>
              {loginError
            && (
            <Typography>
              {loginError === "INVALID_EMAIL" && "Email format is invalid"}
              {loginError === "INVALID_INFORMATION" && "Invalid Credentials. If this problem continues, please contact Valor."}
            </Typography>
            )}
              <FormControl className={classes.input} error={Boolean(loginError)}>
                <InputLabel htmlFor="standard-adornment-login">Login</InputLabel>
                <Input
                  autoFocus
                  id="standard-adornment-login"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>

              <FormControl className={classes.input} error={Boolean(loginError)}>
                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                )}
                />
              </FormControl>

              <Button
                disabled={!email.length && !password.length}
                variant="contained"
                color="primary"
                onClick={handleLogin}
              >
                Sign In
              </Button>

            </>
          )}
      </Card>
    </div>
  );
}

export default LoginPage;
