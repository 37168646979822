/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Legend,
  Surface,
  ReferenceArea,
  Symbols,
  Label,
} from "recharts";
import {
  Box, Grid, Typography, useTheme,
} from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { formatShrunkTotal } from "@valor-labs/marble/src/utils/numberFormat";
import startCase from "lodash.startcase";
import MultiSelect from "../MultiSelect";
import DropdownInfo from "../DropdownInfo";

dayjs.extend(utc);

const displayDoc = (
  <>
    <h3>Display</h3>
    Select which metric you want to chart.
    <br />
    <br />
    <strong>
      <i>Note:</i>
    </strong>
    {" "}
    You can quickly (de)select all metrics by clicking
    {" "}
    <strong>
      <i>&apos;All&apos;</i>
    </strong>
    .
  </>
);

// TODO reuse
const getMapToColor = (arr, colors) => {
  const mapToColor = {};
  arr.forEach((value, index) => {
    mapToColor[value] = colors[index];
  });
  return mapToColor;
};

interface Props {
  id: string;
  title: string;
  height?: number;
  data?: {
    value: string,
    timestamp: number,
  }[];
  isGroupedByMonth: boolean,
  zoomIn: (startDate: string, endDate: string, period: "DAILY") => void;
  allDisplayValues?: string[];
  xAxisKey: string;
}

export function MultiLineChart(props: Props) {
  const {
    id,
    title,
    height,
    data,
    isGroupedByMonth,
    zoomIn,
    allDisplayValues,
    xAxisKey,
  } = props;

  const [state, setState] = useState({ refAreaLeft: "", refAreaRight: "" });

  const [onHoverCompany, setOnHoverCompany] = useState("");
  const [displayValues, setDisplayValues] = useState(allDisplayValues);
  const [highlightedKey, setHighlightedKey] = useState("");
  const theme = useTheme();

  const companyToColor = getMapToColor(allDisplayValues, theme.palette.competitors);

  const tickFormatter = (value) => dayjs(value).format(`${isGroupedByMonth ? "" : "DD-"}MMM-YYYY`);

  const zoom = () => {
    const { refAreaLeft, refAreaRight } = state;

    setState({
      refAreaLeft: "",
      refAreaRight: "",
    });

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      return;
    }

    let startDate = dayjs(refAreaLeft);
    let endDate = dayjs(refAreaRight);

    if (startDate.isAfter(endDate)) {
      [startDate, endDate] = [endDate, startDate];
    }

    zoomIn(startDate, endDate, "Daily");
  };

  const renderCustomizedLegend = ({ payload }) => (
    <div style={{ textAlign: "center" }}>
      {payload.map((entry) => {
        const { dataKey, color } = entry;
        const active = dataKey === onHoverCompany || dataKey === highlightedKey;
        const style = {
          marginRight: 10,
          fontWeight: active ? "bold" : "normal",
          cursor: "pointer",
          color: active ? "#000" : "#808080",
        };

        return (
          <span
            onClick={() => setHighlightedKey(
              dataKey === highlightedKey ? "" : dataKey,
            )}
            onMouseOver={() => setOnHoverCompany(dataKey)}
            onMouseOut={() => setOnHoverCompany("")}
            style={style}
            key={`span-legend-${dataKey}`}
          >
            <Surface
              width={10}
              height={10}
              viewBox={{
                x: 0, y: 0, width: 10, height: 10,
              }}
            >
              <Symbols cx={5} cy={5} type="circle" size={85} fill={color} />
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={60}
                fill={active ? color : "#FFF"}
              />
            </Surface>
            <span>{startCase(dataKey)}</span>
          </span>
        );
      })}
    </div>
  );

  return (
    <Box
      borderRadius="10px"
      border={1}
      borderColor="#D3D3D3"
      height={480}
      m={2}
    >
      <Grid container>
        <Grid item md={6} container justifyContent="flex-end">
          <Typography
            id={`line-${id}`}
            variant="subtitle1"
            align="center"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item md={6} container justifyContent="flex-end">
          <DropdownInfo
            component={(
              <MultiSelect
                allValues={allDisplayValues}
                getLabel={startCase}
                defaultValue={allDisplayValues}
                value={displayValues}
                title="Display"
                id={`${id}-line`}
                onChange={setDisplayValues}
                colorMap={companyToColor}
              />
            )}
            title={displayDoc}
          />
        </Grid>
      </Grid>
      <div style={{ userSelect: "none" }}>

        <ResponsiveContainer width="99%" height={height}>
          <LineChart
            id={id}
            data={data}
            onMouseDown={(e) => setState({ ...state, refAreaLeft: e.activeLabel })}
            onMouseMove={(e) => state.refAreaLeft
            && setState({ ...state, refAreaRight: e.activeLabel })}
            onMouseUp={zoom}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisKey} tickFormatter={tickFormatter} />
            <YAxis tickFormatter={(value) => formatShrunkTotal(value)} yAxisId="1">
              <Label
                value="Daily"
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <Tooltip labelFormatter={tickFormatter} formatter={(value, name) => [formatShrunkTotal(value), startCase(name)]} />

            <Legend content={renderCustomizedLegend} />
            {displayValues.map((company) => (
              <Line
                key={`line-${company}`}
                yAxisId="1"
                type="monotone"
                animationDuration={300}
                strokeOpacity={
                company === highlightedKey
                || company === onHoverCompany
                || (highlightedKey === "" && onHoverCompany === "")
                  ? 1
                  : 0.3
              }
                strokeWidth={
                company === highlightedKey || company === onHoverCompany
                  ? 4
                  : 2
              }
                dot={false}
                dataKey={company}
                stroke={companyToColor[company]}
              />
            ))}
            {state.refAreaLeft && state.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={state.refAreaLeft}
                x2={state.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
}

export default MultiLineChart;
