import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Helmet from "react-helmet";

import { LabeledDetail, TitledOrgCard, getFundingSourceLink } from "@valor-labs/marble";

import FundingRoundTableCard from "./FundingRoundTableCard";
import CompanyStatsCard from "./CompanyStatsCard";
import CompanyNewsCard from "./CompanyNewsCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  opportunities: {
    display: "flex",
    flexFlow: "row wrap",
    flexShrink: 0,
    "& .Insights-MuiChip-label": {
      whiteSpace: "break-spaces",
    },
  },
  columns: {
    "& > div": {
      marginBottom: theme.spacing(3),
    },
  },
}));

function OrganizationSummary(props) {
  const {
    company,
    location: { hash },
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [clickedAnywhere, setHasClickedAnywhere] = useState(false);

  const fundingRoundLink = getFundingSourceLink({
    affId: company?.affId,
    pbId: company?.pbId,
    cbId: company?.cbId,
    fundingSource: company?.fundingRounds?.[0]?.source,
  });

  useEffect(() => {
    // We want to hide the attention-grabbing ui after someones interacted on the page
    if (hash !== "") {
      const elementId = window.location.hash.replace("#", "");
      const element = document.getElementById(elementId);
      element.scrollIntoView();
      window.addEventListener("touchstart", () => setHasClickedAnywhere(true));
      window.addEventListener("click", () => setHasClickedAnywhere(true));
    }
    return () => {
      window.removeEventListener("click", () => setHasClickedAnywhere(true));
      window.removeEventListener("touchstart", () => setHasClickedAnywhere(true));
    };
  }, [company, hash]);

  const renderDetailsCard = () => (
    <TitledOrgCard title="Details">
      <Grid spacing={2} container>
        <Grid item sm={12} lg={9}>
          <LabeledDetail title="WEBSITE">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.${company.domain}`}
            >
              {`www.${company.domain}`}
            </a>
          </LabeledDetail>
          <LabeledDetail title="DESCRIPTION">
            <Typography>{company.description}</Typography>
          </LabeledDetail>
        </Grid>
        <Grid item sm={12} lg={3}>
          <LabeledDetail title="EMPLOYEES">
            <Typography variant="subtitle1">
              {company.employeeTotalHistory?.[(company?.employeeTotalHistory?.length ?? 0) - 1]?.value?.toLocaleString()}
            </Typography>
          </LabeledDetail>

          <LabeledDetail title="LOCATION">
            <Typography variant="subtitle1">
              {Boolean(company.city) && company.city}
              {Boolean(company.city) && Boolean(company.state) && ", "}
              {Boolean(company.state) && company.state}
            </Typography>
          </LabeledDetail>
          {company.yearFounded && (
          <LabeledDetail title="FOUNDED">
            <Typography variant="subtitle1">
              {company.yearFounded}
            </Typography>
          </LabeledDetail>
          )}
        </Grid>
      </Grid>
    </TitledOrgCard>
  );

  return (
    <Grid container spacing={isMdDown ? 0 : 3} className={classes.root}>
      <Helmet>
        <title>{`${company.name} - Insights Summary`}</title>
        <meta property="og:title" content={`${company.name} - Insights Summary`} />
        <meta
          property="og:description"
          content={`See an overview of ${company.name} on Insights`}
        />
      </Helmet>
      <Grid className={classes.columns} item xs={12} lg={9}>
        {renderDetailsCard()}
        <CompanyStatsCard
          clickedAnywhere={clickedAnywhere}
          sectionHash={hash}
          company={company}
        />
      </Grid>
      <Grid className={classes.columns} item xs={12} lg={3}>
        <CompanyNewsCard valorId={company.valorId} />
        <FundingRoundTableCard
          fundingRounds={company?.fundingRounds ?? []}
          fundingRoundLink={fundingRoundLink}
        />
      </Grid>
    </Grid>
  );
}

OrganizationSummary.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrganizationSummary;
