/* eslint-disable no-bitwise */
export const hexToRgb = (hex) => {
  const parsedHex = hex.replaceAll("#", "");
  const bigint = parseInt(parsedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
};

export default hexToRgb;
