export const DEVELOPER_ROLE_ID = 1;
export const GROWTH_FUND_PARTNER_ROLE_ID = 2;
export const VP_PRINCIPAL = 3;
export const COMPLIANCE_ROLE_ID = 4;
export const ASSOCIATES = 11;
export const MARKET_MAPS_ROLE_ID = 12;
export const NEW_USER_ROLE_ID = 13;
export const CEO_ROLE_ID = 14;
export const PRESIDENTS_ROLE_ID = 15;
export const OPS_PARTNER = 16;
export const HR_ROLE_ID = 17;
export const ADMIN_ROLE_ID = 18;

export const MANAGEMENT_TIER_ROLE_IDS = new Set([
  GROWTH_FUND_PARTNER_ROLE_ID,
  DEVELOPER_ROLE_ID,
  CEO_ROLE_ID,
  PRESIDENTS_ROLE_ID,
  OPS_PARTNER,
  ADMIN_ROLE_ID
]);

export const VPO_ROLE_IDS = new Set([
  DEVELOPER_ROLE_ID,
  CEO_ROLE_ID,
  PRESIDENTS_ROLE_ID,
  HR_ROLE_ID,
  ADMIN_ROLE_ID
]);

export const SOURCING_LIST_IDS = new Set([
  CEO_ROLE_ID,
  PRESIDENTS_ROLE_ID,
  ADMIN_ROLE_ID,
  VP_PRINCIPAL,
  DEVELOPER_ROLE_ID
]);

export default {
  VPO_ROLE_IDS,
  COMPLIANCE_ROLE_ID,
  MARKET_MAPS_ROLE_ID,
  ASSOCIATES,
  VP_PRINCIPAL,
  NEW_USER_ROLE_ID,
  SOURCING_LIST_IDS,
  MANAGEMENT_TIER_ROLE_IDS,
  DEVELOPER_ROLE_ID,
  CEO_ROLE_ID,
  PRESIDENTS_ROLE_ID,
  HR_ROLE_ID,
  ADMIN_ROLE_ID
};
