import React from "react";
import { withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.blue.main,
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\"",
    ].join(","),
    "&:hover": {
      color: theme.palette.blue.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.blue.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: theme.palette.blue.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export function SlidingTabs({ values, setValue, value }) {
  const labelToIndex = values.reduce(
    (prev, curr, index) => ((prev[curr] = index), prev),
    {},
  );

  const onChange = (event, newIntegerValue) => {
    // newValue is in
    setValue(values[newIntegerValue]);
  };

  return (
    <StyledTabs value={labelToIndex[value]} onChange={onChange}>
      {values.map((value) => (
        <StyledTab key={`styled-tab-${value}`} label={value} />
      ))}
    </StyledTabs>
  );
}

export default SlidingTabs;
