import React from "react";
import {
  Checkbox,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles(() => ({
  formControl: {
    width: "100%",
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Option {
  id: string
  label: string
  formatter?: string,
  group?: string
}

interface Props {
  id: string
  label?: string
  options: Option[]
  value: Option[],
  onChange: (value: string | Option[]) => void
}

export function AutocompleteSelect(props: Props) {
  const {
    id, label, value, onChange, options,
  } = props;

  const classes = useStyles();
  return (
    <FormControl
      className={classes.formControl}
      margin="dense"
      variant="outlined"
    >
      <Autocomplete
        multiple
        value={value}
        // @ts-ignore
        onChange={(event, newVal: string | Option[]): void => {
          onChange(newVal);
        }}
        getOptionSelected={(option, current) => option.id === current.id}
        groupBy={(option) => option.group}
        size="small"
        id={id}
        getOptionLabel={(option) => option.label}
        options={options}
        disableCloseOnSelect
        renderOption={(option: Option, { selected }) => (
          <>
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </>
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label={label}
            placeholder="Search"
          />
        )}
      />
    </FormControl>
  );
}

export default AutocompleteSelect;
