import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PollIcon from "@material-ui/icons/Poll";
import kebabCase from "lodash.kebabcase";
import ShareIcon from "../../atoms/Icons/Share";
import CompanyAvatar from "../../atoms/CompanyAvatar";
import FollowButton from "../../atoms/FollowButton";
import ResourceLinks from "../ResourceLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme.palette.neutrals["100"],
  },
  interiorContainer: {
    padding: theme.spacing(1, 2, 0, 2),
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    fontSize: "2.25rem",
    marginRight: theme.spacing(1),
  },
  companyLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.spacing(10),
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: theme.spacing(8),
    },
  },
  logoContainer: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-start",
  },
  tabContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  shareButton: {
    width: theme.spacing(5),
    padding: theme.spacing(0, 1.5),
    color: theme.palette.neutrals["30"],
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
  },
  actionButton: {
    width: theme.spacing(20),
    padding: theme.spacing(0, 1.5),
    color: theme.palette.neutrals["30"],
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  tab: {
    height: theme.spacing(4),
    padding: theme.spacing(2, 0),
    color: theme.palette.neutrals["30"],
  },
  tabLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "14px",
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

export function OrgHeader(props) {
  const {
    company,
    currentTab,
    onTabChange,
    isFollowing,
    onFollowClick,
    drawerContext,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const context = useContext(drawerContext);

  const isMdDown = useMediaQuery((t) => t.breakpoints.down("md"));
  const isXsDown = useMediaQuery((t) => t.breakpoints.down("xs"));

  const tabs = [
    "summary",
    "public data",
    "competitors",
  ];

  const isPWA = (window?.matchMedia
      && window?.matchMedia("(display-mode: standalone)")?.matches)
    || false;

  const shareCompany = () => {
    navigator?.share({
      title: `Argos ${company.name}`,
      url: document.location.href,
    });
  };

  const onTabClick = (e, label) => {
    onTabChange(kebabCase(label));
  };

  const tabButtons = tabs.map((label) => (
    <ButtonBase
      key={label}
      className={classes.tab}
      onClick={(e) => onTabClick(e, label)}
      style={
        kebabCase(label) === currentTab ? {
          color: theme.palette.primary.main,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        } : {}
      }
    >
      <Typography component="p" className={classes.tabLabel}>
        {label.toUpperCase()}
      </Typography>
    </ButtonBase>
  ));

  const callToActions = (
    <Box display="flex" marginLeft="auto">
      <Button
        size="small"
        style={{ marginRight: "8px", height: "45px" }}
        onClick={() => {
          context.setSurveyDrawerState(true);
        }}
        className={classes.actionButton}
      >
        <PollIcon />
        TAKE SURVEY
      </Button>
      <FollowButton isFollowing={isFollowing} onClick={onFollowClick} />
      {isPWA && (
        <ButtonBase
          onClick={() => shareCompany()}
          className={classes.shareButton}
        >
          <ShareIcon />
        </ButtonBase>
      )}
    </Box>
  );

  const companyLogo = (
    <Box className={classes.companyLogo}>
      <CompanyAvatar src={company.logoUrl} name={company.name} />
    </Box>
  );

  const resources = (
    <Box display="flex">
      <ResourceLinks
        linkedin={company.linkedinUrl}
        crunchbase={company.cbId}
        pitchbook={company.pbId}
        affinity={company.affId}
        synaptic={company.syId}
      />
    </Box>
  );

  if (isXsDown) {
    return (
      <Box className={classes.root}>
        <Box className={classes.interiorContainer}>
          <Box className={classes.logoContainer} height="80px">
            {companyLogo}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Typography className={classes.title} variant="h4">
                {company.name}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="flex-end"
            style={{ gap: "8px" }}
          >
            {callToActions}
          </Box>
          <Box className={classes.tabContainer}>{tabButtons}</Box>
        </Box>
      </Box>
    );
  }

  if (isMdDown) {
    return (
      <Box className={classes.root}>
        <Box className={classes.interiorContainer}>
          <Box className={classes.logoContainer} height="160px">
            {companyLogo}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{ gap: "8px" }}
            >
              <Typography className={classes.title} variant="h4">
                {company.name}
              </Typography>
              {resources}
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="flex-end"
                style={{ gap: "8px" }}
              >
                {callToActions}
              </Box>
            </Box>
          </Box>
          <Box className={classes.tabContainer}>{tabButtons}</Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.interiorContainer}>
        <Box className={classes.logoContainer}>
          {companyLogo}
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: "4px" }}
          >
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography className={classes.title} variant="h4">
                {company.name}
              </Typography>
              {resources}
            </Box>
            <Box className={classes.tabContainer}>{tabButtons}</Box>
          </Box>
          {callToActions}
        </Box>
      </Box>
    </Box>
  );
}

OrgHeader.propTypes = {
  // company: Company.isRequired,
  currentTab: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  isFollowing: PropTypes.bool,
  onFollowClick: PropTypes.func,
};

OrgHeader.defaultProps = {
  currentTab: "summary",
  isFollowing: false,
  onFollowClick: () => {},
};

export default OrgHeader;
