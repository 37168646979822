/* eslint-disable react/no-danger */
import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: ({ isActive }) => ({
    display: "flex",
    color: theme.palette.neutrals["43"],
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    backgroundColor: isActive ? "#F8FDFF" : theme.palette.neutrals["100"],
    cursor: "pointer",
  }),
  title: ({ isActive }) => ({
    color: theme.palette.neutrals["13"],
    fontWeight: isActive
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightSemiBold,
    fontSize: "16px",
    textAlign: "left",
    "& em": {
      fontWeight: theme.typography.fontWeightBlack,
      textDecoration: "underline",
      fontStyle: "none",
    },
  }),
  author: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.neutrals["13"],
    fontSize: "14px",
  },
  date: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.neutrals["43"],
    fontSize: "12px",
  },
  text: {
    display: "-webkit-box",
    overflow: "hidden",
    color: theme.palette.neutrals["13"],
    fontSize: "14px",
    /* stylelint-disable */
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "5",
    /* stylelint-enable */
    "& em": {
      fontWeight: theme.typography.fontWeightBlack,
      fontStyle: "normal",
      textDecoration: "underline",
    },
  },
}));

export function NotePreview(props) {
  const {
    title,
    authorName,
    date,
    text,
    documentId,
    isActive,
    onClick,
  } = props;
  const classes = useStyles({ isActive });

  return (
    <Box className={classes.container} onClick={() => onClick(documentId)}>
      <h3
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Typography className={classes.author}>{authorName}</Typography>
      <Typography className={classes.date}>
        {dayjs(date * 1000).format("MM/DD/YYYY")}
      </Typography>
      <p className={classes.text} dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
  );
}

NotePreview.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
NotePreview.defaultProps = {
  isActive: false,
};

export default NotePreview;
