/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box, Typography, Tooltip,
} from "@material-ui/core";
import dayjs from "dayjs";


const useStyles = makeStyles((theme) => ({
    lastContactedCell: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      width: "100%",
      cursor: "pointer",
    },
    daysSince: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: ".875rem",
    },
}));

  
export function LastContactedCell({
    lastInteractionTs,
    lastInteractionPersons,
    affId
}) {
    const classes = useStyles();
    const theme = useTheme();

    let input = "";
    let color = "red";
    let then = null
    if (lastInteractionTs) {
        const now = dayjs();
        if (Number.isInteger(lastInteractionTs)) {
            then = dayjs.unix(lastInteractionTs);
        } else {
            then = dayjs(lastInteractionTs);
        }

        let difference = now.diff(then, "days");
        let singular = "day";
        let plural = "days";
        if (difference === 0) {
        difference = now.diff(then, "hours");
        singular = "hour";
        plural = "hours";
        }

        input = `${difference} ${
        difference === 1 ? ` ${singular}` : ` ${plural}`
        }`;
        color = difference <= 60 ? "green" : difference > 120 ? "red" : "yellow";
    } else {
        input = "No Contact Data";
    }

    let peopleContent;
    let toolTipTitle = `${
        lastInteractionTs
        ? then.format("MMM D, YYYY")
        : ""
    } `;
    const people = lastInteractionPersons?.length
        && lastInteractionPersons.map(
        (person) => {
            if (person.name) {
                return person.name;
            } else {
                return `${person.firstName} ${person.lastName}`;
            }
        }
        );
    if (lastInteractionPersons?.length) {
        toolTipTitle += `by ${people?.join(", ")}. `;
        if (people.length > 2) {
        peopleContent = `${people.slice(0, 2).join(", ")} +${
            people.length - 2
        }`;
        } else {
        peopleContent = people.join(", ");
        }
    }
    toolTipTitle += "From Affinity";

    return (
        <Tooltip title={toolTipTitle} placement="top-start">
        <Box
            onClick={() => window.open(
            `https://valor.affinity.co/companies/${affId}`,
            "_blank",
            )}
            className={classes.lastContactedCell}
        >
            <Typography
            className={classes.daysSince}
            style={{ color: theme.palette[color].main }}
            >
            {input}
            </Typography>
            {Boolean(peopleContent) && (
            <Typography className={classes.lastContact}>
                by &thinsp;
                {peopleContent}
            </Typography>
            )}
        </Box>
        </Tooltip>
    );
}

export default LastContactedCell;