/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Card, Box, Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  BarChart, Bar, ResponsiveContainer, Tooltip,
} from "recharts";
import { formatTotal, titleCase } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: theme.shadows[1],
  },
  card: {
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    color: theme.palette.neutrals["30"],
    fontSize: "1rem",
  },
  label: {
    paddingRight: theme.spacing(0.5),
    color: theme.palette.neutrals["30"],
    fontSize: ".875rem",
    textAlign: "left",
  },
  value: {
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: ".875rem",
  },
}));

const sortStack = (a, b, data) => {
  if (data[a] === data[b]) {
    if (a > b) return -1;
    if (a < b) return 1;
    return 0;
  }
  return data[a] > data[b] ? -1 : 1;
};

export function CustomToolTip(props) {
  const { active, payload, hoveredBar } = props;
  const classes = useStyles();
  if (active && payload && payload.length && hoveredBar) {
    const data = payload[0]?.payload;
    return (
      <Card className={classes.card}>
        <Box display="flex" flexDirection="column">
          {Object.keys(data)
            .sort((a, b) => sortStack(b, a, data))
            .map((dataPoint) => (
              <Box key={dataPoint} className={classes.container}>
                <Box
                  style={{
                    width: 16,
                    height: 16,
                    marginRight: 8,
                    backgroundColor:
                      hoveredBar?.name === dataPoint
                        ? hoveredBar.color
                        : "transparent",
                  }}
                />
                <Typography
                  className={classes.label}
                  style={{
                    fontWeight: dataPoint === hoveredBar?.name ? 700 : null,
                  }}
                >
                  {titleCase(dataPoint)}
                  : &nbsp;
                </Typography>
                <Typography className={classes.value}>
                  {formatTotal(data[dataPoint] || 0)}
                </Typography>
              </Box>
            ))}
        </Box>
      </Card>
    );
  }
  return null;
}

export function SingleStackedBar(props) {
  const { data, color, labelFormatter } = props;
  // TODO prop Types
  const theme = useTheme();
  const chartColors = theme.palette.charts[color];

  const [hoveredBar, setHoveredBar] = useState(null);

  const handleBarHover = (bar, barColor) => {
    setHoveredBar({ name: bar, color: barColor });
  };

  const handleBarHoverLeave = () => {
    setHoveredBar(null);
  };

  const renderCustomizedLabel = (payload, name, value, fill) => {
    const {
      x, y, width, height,
    } = payload;
    if (height < 32) {
      return null;
    }
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        dominantBaseline="central"
        textAnchor="middle"
        fill={theme.palette.getContrastText(fill)}
      >
        {labelFormatter === null ? name : labelFormatter(name)}
        {" "}
        {value}
      </text>
    );
  };

  return (
    <ResponsiveContainer height={600} width="100%">
      <BarChart
        throttleDelay={400}
        data={data}
        margin={{
          top: 5, right: 0, bottom: 30, left: 0,
        }}
      >
        {Object.keys(data[0])
          .sort((a, b) => sortStack(a, b, data[0]))
          .map((val, idx) => {
            const fill = chartColors[idx % chartColors.length];
            return (
              <Bar
                key={val}
                name={val}
                dataKey={val}
                stackId="a"
                fill={fill}
                isAnimationActive={false}
                label={(payload) => renderCustomizedLabel(payload, val, data[0][val], fill)}
                onMouseEnter={() => handleBarHover(val, fill)}
                onMouseLeave={handleBarHoverLeave}
              />
            );
          })}
        <Tooltip
          cursor={{ fill: "transparent" }}
          content={<CustomToolTip hoveredBar={hoveredBar} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

SingleStackedBar.propTypes = {};

SingleStackedBar.defaultProps = {
  labelFormatter: null,
};

export default SingleStackedBar;
