import React, { useState, useEffect } from "react";
import {
  Box, ButtonBase, makeStyles, Tooltip, Theme, createStyles,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import { svgElToSvgFile } from "../../../utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
  download: {
    color: theme.palette.neutrals["30"],
    padding: theme.spacing(0.5),
    borderRadius: "100%",
  },
}));

const boxStyle = { margin: "1%", flex: 1 };
const margin = {
  top: 16, right: 32, left: 16, bottom: 16,
};

interface Props {
  children: React.ReactElement;
  metric: {
    label: string,
    id: string,
    subtitle?: string
  };
  currentCompany: string;
  height: number;
  defaultHighlightedCompany: string;
}
export function CompetitorChart(props: Props) {
  const {
    metric,
    currentCompany,
    children,
    defaultHighlightedCompany,
    height,
  } = props;
  const classes = useStyles();
  const [highlightedCompany, setHighlightedCompany] = useState(
    defaultHighlightedCompany,
  );

  useEffect(() => {
    setHighlightedCompany(defaultHighlightedCompany);
  }, [defaultHighlightedCompany]);

  return (
    <Box
      style={boxStyle}
      boxShadow={1}
      bgcolor="background.paper"
      borderRadius="10px"
      p={1}
    >
      {React.cloneElement(children, {
        highlightedCompany,
        setHighlightedCompany,
        margin,
        height,
        metric,
      })}
      <Box>
        <Tooltip title="Download Chart as Image" placement="top">
          <ButtonBase
            onClick={() => svgElToSvgFile(
              document.getElementById(metric.label),
              `${metric.label}-${currentCompany}.svg`,
            )}
            className={classes.download}
          >
            <GetAppIcon />
          </ButtonBase>
        </Tooltip>
      </Box>
    </Box>
  );
}

CompetitorChart.propTypes = {
  children: PropTypes.element.isRequired,
  metric: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  }).isRequired,
  currentCompany: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  defaultHighlightedCompany: PropTypes.string.isRequired,
};

export default CompetitorChart;
