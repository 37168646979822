import React from "react";
import { Link } from "react-router-dom";
import {
  makeStyles, Typography, createStyles, Theme,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { capitalizeFirstLetter } from "../../../utils/general";

const useStyles = makeStyles((theme: Theme) => createStyles({
  breadcrumb: {
    "& > a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  path: string
}

export function PathBreadCrumbs(props: Props) {
  const { path } = props;

  const classes = useStyles();

  const formatPath = (path) => {
    const parts = path.split("-").filter((x) => x);
    return parts.map((part) => capitalizeFirstLetter(part)).join(" ");
  };
  const parts = path.split("/").filter((x) => x);
  let buildPath = "";
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {parts.map((value, index) => {
        buildPath += `/${value}`;
        const isLast = index === parts.length - 1;
        const formatted = formatPath(value);
        return (
          <Typography
            key={`breadcrumbs-typography-${formatted}`}
            color="textPrimary"
            className={classes.breadcrumb}
            variant="h6"
          >
            {isLast ? (
              formatted
            ) : (
              <Link color="inherit" key={`link-${buildPath}`} to={buildPath}>
                {formatted}
              </Link>
            )}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}

export default PathBreadCrumbs;
