import React from "react";
import ReactJoyride, { STATUS, Step } from "react-joyride";
import {
  makeStyles, useTheme, Theme, createStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  "@keyframes pulse": {
    "0%": {
      transform: "scale(1)",
    },
    "55%": {
      backgroundColor: "rgba(100, 150, 255, 0.9)",
      transform: "scale(1.6)",
    },
  },
  bug: {
    display: "inline-block",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: theme.palette.blue.main,
    borderRadius: "50%",
    animation: "$pulse 1s ease-in-out infinite",
  },
}));

export function JoyrideBug(props) {
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <span className={classes.bug} {...props} />
  );
}

interface Props {
  title: string;
  run?: boolean;
  steps: Step[];
}

export function Joyride(props: Props) {
  const { title, run = false } = props;
  const shouldRun = !localStorage.getItem(`${title}Tour`);
  const theme = useTheme();
  const handleCallback = ({ status }) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      localStorage.setItem(`${title}Tour`, "toured");
    }
  };
  return (
    <ReactJoyride
      beaconComponent={JoyrideBug}
      spotlightPadding={16}
      callback={handleCallback}
      locale={{
        back: "Back",
        close: "Close",
        last: "Finish",
        next: "Next",
        skip: "Skip",
      }}
      styles={{
        options: {
          arrowColor: theme.palette.neutrals["96"],
          backgroundColor: theme.palette.neutrals["96"],
          beaconSize: 36,
          overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: theme.palette.primary.main,
          spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
          textColor: theme.palette.neutrals["30"],
          width: undefined,
          zIndex: 100,
        },
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      run={run && shouldRun}
    />
  );
}

export default Joyride;
