import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Grid,
  Select,
  MenuItem,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import {
  TitledOrgCard, titleCase, SingleStackedBar, Linkedin, StackedBarWithTrendLine, CenteredCircularProgress,
} from "@valor-labs/marble";

import {
  getOrgTalentSummary,
  getOrgRecentHiresByCategory,
} from "../../../api/Pofoco";

const barOrder = [
  "executive",
  "operations",
  "administrative",
  "engineering",
  "product",
  "accounting",
  "finance",
  "sales",
  "marketing",
  "other",
];

const useStyles = makeStyles((theme) => ({
  signalsContainer: {
    display: "flex",
    gap: theme.spacing(4),
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    paddingBottom: theme.spacing(2),
    overflowX: "scroll",
  },
  labels: {
    padding: theme.spacing(1, 3),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem",
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  goToLinkedin: {
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5, 0.75),
    color: theme.palette.neutrals["30"],
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
    "& svg": {
      width: ".75em",
      height: ".75em",
    },
  },
  peopleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
  },
  employeeName: {
    paddingRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "1rem",
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: ".875rem",
  },
}));

export function OrganizationEmployees({ id }) {
  const classes = useStyles();
  const theme = useTheme();
  const [category, setCategory] = useState("executive");

  const {
    data: talentSummary = {},
    isLoading: isLoadingTalentSummary,
  } = useQuery(
    ["OrgTalentSummary", id],
    async () => getOrgTalentSummary(id),
    {
      retry: 1,
    },
  );

  const { data: recentHires = [], isLoading: isLoadingRecentHires } = useQuery(
    ["RecentHires", id, category],
    async () => getOrgRecentHiresByCategory(id, category),
    {
      retry: 1,
    },
  );

  const overallMonthlyTrend = useMemo(() => talentSummary?.employmentMonthly?.map(
    ({ month, employeeTotal, categories }) => ({
      month,
      total: employeeTotal,
      bars: Object.keys(categories).reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: categories[curr] && categories[curr].employeeTotal,
        }),
        {},
      ),
    }),
  ), [talentSummary]);

  const categoryMonthlyTrend = useMemo(() => talentSummary?.employmentMonthly?.map(({ month, categories }) => ({
    month,
    total: categories?.[category]?.employeeTotal || 0,
    bars: categories?.[category]?.departments || [],
  })), [talentSummary, category]);

  const categorySources = useMemo(() => [
    talentSummary?.categories?.[category]?.reduce(
      (acc, curr) => ({ [curr.name]: curr.employeeTotal, ...acc }),
      {},
    ),
  ], [talentSummary, category]);

  const filledDepartments = useMemo(() => [
    ...new Set(
      talentSummary?.employmentMonthly?.flatMap((x) => Object.keys(x.categories[category]?.departments || [])),
    ),
  ], [talentSummary, category]);

  const overallSources = useMemo(() => [
    talentSummary?.sources?.reduce(
      (acc, curr) => ({ [curr.name]: curr.employeeTotal, ...acc }),
      {},
    ),
  ], [talentSummary]);

  if (isLoadingTalentSummary) {
    return <CenteredCircularProgress />;
  }

  const renderPeopleSection = () => {
    const renderPeople = ({
      id: personId,
      name,
      url,
      jobTitle,
      department,
      currentCompanySince,
    }) => (
      <Box key={personId} className={classes.peopleContainer}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Typography className={classes.employeeName}>{name}</Typography>
            <ButtonBase
              className={classes.goToLinkedin}
              onClick={() => window.open(url, "_blank")}
            >
              <Linkedin />
            </ButtonBase>
          </Box>
          <Typography className={classes.subtitle}>{jobTitle}</Typography>
          <Typography className={classes.subtitle}>
            {titleCase(department)}
          </Typography>
        </Box>
        {currentCompanySince && (
          <>
            Started:
            {dayjs(currentCompanySince).format("MMM YYYY")}
          </>
        )}
      </Box>
    );

    if (isLoadingRecentHires && recentHires?.length === 0) {
      return (
        <Box
          display="flex"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      );
    }
    if (recentHires.length === 0) {
      return (
        <Typography>
          No Recent Hires for
          {titleCase(category)}
        </Typography>
      );
    }
    return (
      <Box>
        <Typography className={classes.labels}>
          {titleCase(category)}
          {" "}
          Recent Hires
        </Typography>
        <Box
          style={{
            maxHeight: "600px",
            overflowY: "scroll",
            padding: "0px 8px",
          }}
        >
          {recentHires?.map(renderPeople)}
        </Box>
      </Box>
    );
  };

  const renderTalentSummaryData = () => (
    <>
      <Grid container spacing={2} style={{ paddingBottom: "16px" }}>
        <Grid item xs={12} sm={6}>
          <TitledOrgCard title="Growth by Category">
            <Box style={{ height: 600 }}>
              {Boolean(overallMonthlyTrend?.length) && (
                <StackedBarWithTrendLine
                  data={overallMonthlyTrend}
                  barNames={barOrder}
                />
              )}
            </Box>
          </TitledOrgCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TitledOrgCard title="Sources of Talent">
            <Box style={{ height: 600 }}>
              {Boolean(talentSummary?.sources?.length)
                && overallSources[0] !== undefined && (
                  <SingleStackedBar data={overallSources} color="blue" />
              )}
            </Box>
          </TitledOrgCard>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TitledOrgCard title={`Analysis of ${titleCase(category)}`}>
            <Grid container>
              <Grid item xs={12} style={{ paddingLeft: theme.spacing(2) }}>
                <Select
                  value={category || ""}
                  onChange={(e) => {
                    setCategory(e.target.value || undefined);
                  }}
                >
                  {barOrder.map((option) => (
                    <MenuItem key={`${option}`} value={option}>
                      {titleCase(option)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box className={classes.chartContainer}>
                  {Boolean(categoryMonthlyTrend?.length) && (
                    <>
                      <Typography className={classes.labels}>
                        Monthly Trends
                      </Typography>
                      <StackedBarWithTrendLine
                        showTrendLine={false}
                        data={categoryMonthlyTrend}
                        barNames={filledDepartments}
                        showLegend={false}
                        color={
                          theme.palette.brandOrder[barOrder.indexOf(category)]
                        }
                      />
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} lg={3}>
                {Boolean(categorySources.length)
                  && categorySources[0] !== undefined && (
                    <Box className={classes.chartContainer}>
                      <Typography className={classes.labels}>
                        Select Previous Employers
                      </Typography>
                      <SingleStackedBar data={categorySources} color="blue" />
                    </Box>
                )}
              </Grid>
              <Grid item xs={6} lg={3}>
                {renderPeopleSection()}
              </Grid>
            </Grid>
          </TitledOrgCard>
        </Grid>
      </Grid>
    </>
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isLoadingTalentSummary
      && (talentSummary === null || talentSummary.employmentMonthly === []) ? (
        <Box margin={1}>
          <Typography variant="h6">No Employee Classification Data</Typography>
        </Box>
        ) : (
          renderTalentSummaryData()
        )}
    </>
  );
}

OrganizationEmployees.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrganizationEmployees;
