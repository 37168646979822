import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    boxShadow: theme.shadows[2],
  },
}));

interface Props {
  children: React.ReactElement
  style?: object
}

export function OrgCard(props: Props) {
  const { children, style = {} } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root} style={style}>
      {children}
    </Card>
  );
}

export default OrgCard;
