import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl, Select, MenuItem,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import InvestmentCategories from "../../../constants/InvestmentCategories";
import Segments from "../../../constants/Segments";

const useStyles = makeStyles((theme: Theme) => createStyles({
  select: {
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(25),
    backgroundColor: theme.palette.neutrals["43"],
    "& >input": {
      color: `${theme.palette.neutrals[100]} !important`,
    },
    "& >div:after": {
      borderBottom: `1px solid ${theme.palette.neutrals[100]}`,
    },
    "& > div": {
      height: "100%",
      color: `${theme.palette.neutrals[100]} !important`,
    },
  },
  span: {
    fontSize: theme.typography.pxToRem(20),
    margin: theme.spacing(0, 1),
  },
}));

interface Chooser {
  [id: string]: string
}

interface Props {
  onICChange(IC: string): void;
  onSegmentChange(segment: string): void;
}
export function CategorySelect(props: Props) {
  const { onICChange, onSegmentChange } = props;

  const classes = useStyles();
  const [investmentCategory, setIC] = useState("");
  const [segment, setSegment] = useState("");

  const [chooseableSegments, setChooseables] = useState<Chooser[]>([]);
  const hierachy = {
    1: ["A", "B", "C"],
    2: ["J"],
    3: ["D", "E", "F", "G", "H", "I"],
    4: ["K"],
  };

  const onSegment = (value: string) => {
    setSegment(value);
    onSegmentChange(value);
  };

  const onIC = (value: string) => {
    const choices: Chooser = {};
    setIC(value);
    // eslint-disable-next-line no-return-assign
    hierachy[value].forEach((seg: string) => (choices[seg] = Segments[seg]));
    onSegment("");
    setChooseables(choices);
    onICChange(value);
    if (Object.keys(choices).length === 1) {
      const onlyChoice = Object.keys(choices)[0];
      onSegment(onlyChoice);
      onSegmentChange(onlyChoice);
    }
  };

  const categoryMultiSelect = (
    name: string,
    value: string,
    setValue: (v: string) => void,
    lookupDict: Chooser,
  ) => (
    <FormControl className={classes.select}>
      <Select
        value={value}
        renderValue={(choice: string) => (
          <span className={classes.span}>
            <b>{name}</b>
            {": "}
            {lookupDict[choice]}
          </span>
        )}
        onChange={(event) => setValue(event.target.value)}
      >
        {Object.keys(lookupDict).map((key) => (
          <MenuItem key={key} value={key}>
            {lookupDict[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const icSelect = categoryMultiSelect(
    "Investment Category",
    investmentCategory,
    onIC,
    InvestmentCategories,
  );
  const segmentSelect = categoryMultiSelect(
    "Segment",
    segment,
    onSegment,
    chooseableSegments,
  );

  return (
    <>
      {icSelect}
      {investmentCategory && segmentSelect}
    </>
  );
}

CategorySelect.propTypes = {
  onICChange: PropTypes.func.isRequired,
  onSegmentChange: PropTypes.func.isRequired,
};

export default CategorySelect;
