import React from "react";
import {
  Box, Typography, Theme, createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 0),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.neutrals["56"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
    textAlign: "left",
  },
}));

interface Props {
  title: string;
  children: React.ReactElement;
}

export function LabeledDetail(props: Props) {
  const { title, children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </Box>
  );
}

export default LabeledDetail;
