import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function Synaptic() {
  return (
    <SvgIcon>
      <path style={{ transform: "scale(0.4)" }} d="M8.951 9.436a4.066 4.066 0 0 0-5.803.112 4.162 4.162 0 0 0-1.14 2.883H2v10.681h.007c0 1.091.414 2.18 1.25 2.996a4.066 4.066 0 0 0 5.803-.111 4.162 4.162 0 0 0 1.141-2.885h.008v-10.68H10.2c0-1.09-.413-2.18-1.25-2.996zM32.62 41.037a4.065 4.065 0 0 0 5.802-.11 4.165 4.165 0 0 0 1.142-2.885h.006V8.158h-.006c0-1.09-.415-2.18-1.251-2.995a4.066 4.066 0 0 0-5.803.11 4.168 4.168 0 0 0-1.14 2.885h-.008v29.884h.007c0 1.09.415 2.179 1.251 2.995zM23.793 5.163a4.065 4.065 0 0 0-5.801.11 4.165 4.165 0 0 0-1.142 2.885h-.007v29.884h.007c0 1.09.414 2.179 1.25 2.995a4.066 4.066 0 0 0 5.804-.11 4.168 4.168 0 0 0 1.14-2.885h.008V8.158h-.008c0-1.09-.414-2.18-1.25-2.995zm8.824 40.795a4.197 4.197 0 0 0 .11 5.88 4.068 4.068 0 0 0 5.803-.111 4.198 4.198 0 0 0-.11-5.88 4.067 4.067 0 0 0-5.803.11zM54 33.78h-.007a4.166 4.166 0 0 1-1.14 2.884 4.066 4.066 0 0 1-5.803.111 4.165 4.165 0 0 1-1.251-2.995h-.007v-10.68h.007a4.17 4.17 0 0 1 1.14-2.885 4.068 4.068 0 0 1 5.804-.112 4.17 4.17 0 0 1 1.25 2.996H54v10.681z" />
    </SvgIcon>
  );
}

export default Synaptic;
