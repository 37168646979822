/* eslint-disable react/destructuring-assignment */
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Crunchbase from "../../atoms/Icons/Crunchbase";
import Pitchbook from "../../atoms/Icons/Pitchbook";
import Affinity from "../../atoms/Icons/Affinity";
import Linkedin from "../../atoms/Icons/Linkedin";
import Synaptic from "../../atoms/Icons/Synaptic";

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1, 1.5),
    color: theme.palette.neutrals["30"],
    border: `1px solid ${theme.palette.neutrals["43"]}`,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}));

interface Props{
  crunchbase?: string;
  pitchbook?: string;
  affinity?: string;
  linkedin?: string;
  synaptic?: string;
}

export function ResourceLinks(props: Props) {
  const {
    crunchbase, affinity, pitchbook, linkedin, synaptic,
  } = props;
  const classes = useStyles();
  return (
    <>
      {crunchbase && (
        <ButtonBase
          className={classes.button}
          onClick={() => window.open(
            `https://www.crunchbase.com/organization/${crunchbase}`,
            "_blank",
          )}
        >
          <Crunchbase />
        </ButtonBase>
      )}
      {pitchbook && (
        <ButtonBase
          className={classes.button}
          onClick={() => window.open(
            `https://my.pitchbook.com/profile/${pitchbook}`,
            "_blank",
          )}
        >
          <Pitchbook />
        </ButtonBase>
      )}
      {affinity && (
        <ButtonBase
          className={classes.button}
          onClick={() => window.open(
            `https://valor.affinity.co/companies/${affinity}`,
            "_blank",
          )}
        >
          <Affinity />
        </ButtonBase>
      )}
      {linkedin && (
        <ButtonBase
          className={classes.button}
          onClick={() => window.open(linkedin, "_blank")}
        >
          <Linkedin />
        </ButtonBase>
      )}
      {synaptic
        && (
        <ButtonBase
          className={classes.button}
          onClick={() => window.open(`https://app.synaptic.com/companies/${synaptic}/`, "_blank")}
        >
          <Synaptic />
        </ButtonBase>
        )}
    </>
  );
}

export default ResourceLinks;
