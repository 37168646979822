import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Hidden,
  Grid,
  useTheme,
  useMediaQuery,
  Toolbar,
  IconButton,
  AppBar,
  makeStyles,
} from "@material-ui/core";
import { Menu, MenuOpen } from "@material-ui/icons";
import clsx from "clsx";
import SearchBar from "../SearchBar";
import UserMenu from "../../atoms/UserMenu";
import UserAvatar from "../../atoms/UserAvatar";
import { MARKET_MAPS_ROLE_ID } from "../../../constants/Roles";

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.black.main,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    backgroundColor: theme.palette.black.main,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 4,
  },
  hide: {
    display: "none",
  },
  nav: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    minHeight: "100%",
    backgroundColor: "transparent",
  },
  logoContainer: {
    cursor: "pointer",
  },
  logo: {
    position: "relative",
    cssFloat: "left",
    height: "50px",
    padding: "5px",
  },
  searchBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  userName: {
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
  },
  toolbarWrapper: {
    "& .MuiToolbar-gutters": {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

export function Header(props) {
  const {
    user,
    logOut,
    search,
    selectCompany,
    drawerOpen,
    setDrawerOpen,
    logoUrl,
    renderLink,
  } = props;
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen && !isSmDown,
      })}
    >
      <Toolbar disableGutters>
        {setDrawerOpen
          && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen && !isSmDown,
            })}
          >
            {drawerOpen && isSmDown ? <MenuOpen /> : <Menu />}
          </IconButton>
          )}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Grid className={classes.gridItem} item xs={2} sm={2}>
            <Box className={classes.nav}>
              <Hidden mdDown>
                {renderLink(
                  <Box className={classes.logoContainer}>
                    <img
                      className={classes.logo}
                      alt="Logo"
                      src={logoUrl}
                    />
                  </Box>,
                )}

              </Hidden>
            </Box>
          </Grid>
          {user.roleId !== MARKET_MAPS_ROLE_ID
          && (
          <Grid className={classes.gridItem} item xs={6}>
            {search
              ? (
                <Box className={classes.searchBar}>
                  <SearchBar
                    showSAYT
                    placeholder="Search"
                    search={search}
                    entity="company"
                    selectTerm={selectCompany}
                  />
                </Box>
              )
              : (
                <Hidden lgUp>
                  {renderLink(
                    <Box style={{ display: "flex", justifyContent: "center" }} className={classes.logoContainer}>
                      <img
                        className={classes.logo}
                        alt="Logo"
                        src={logoUrl}
                      />
                    </Box>
                  )}

                </Hidden>
              )}
          </Grid>
          )}
          <Grid className={classes.gridItem} item xs={2}>
            {user && (
            <Box className={classes.userBox}>
              <Hidden mdDown>
                <Typography variant="subtitle1" className={classes.userName}>
                  {user?.name}
                </Typography>
              </Hidden>

              <IconButton onClick={handleClickUserMenu}>
                <UserAvatar user={user} />
              </IconButton>
              <UserMenu
                user={user}
                logOut={logOut}
                anchorEl={anchorEl}
                onClose={handleCloseUserMenu}
              />
            </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    profilePicture: PropTypes.string,
  }).isRequired,
  logOut: PropTypes.func.isRequired,
  logoUrl: PropTypes.string.isRequired,
  search: PropTypes.func,
  selectCompany: PropTypes.func,
  renderLink: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
};

Header.defaultProps = {
  setDrawerOpen: null,
  search: null,
  selectCompany: null,
  drawerOpen: false,
};

export default Header;
