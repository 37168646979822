import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box, Typography, Grid, Theme, createStyles,
} from "@material-ui/core";
import DollarEstimate from "./DollarEstimate";
import FreeText from "./FreeText";
import FutureQuarter from "./FutureQuarter";
import MultiChoice from "./MultiChoice";
import ReturnDistribution from "./ReturnDistribution";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingBottom: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(2),
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightBold,
  },
  answerContainer: {
    padding: theme.spacing(1),
    display: "flex",
  },
}));

interface Props {
  question: {
    choices?: {
      choiceText: string,
      color: string,
      id: string
    }[],
    questionType: string,
    id: string,
  };
  name: string;
  // eslint-disable-next-line react/require-default-props
  subtitle?: string;
}

// eslint-disable-next-line react/display-name
export const FrameworkQuestion = React.memo((props: Props) => {
  const {
    question,
    name,
    subtitle,
  } = props;

  const classes = useStyles();
  const { questionType } = question;

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title} variant="h6">
          {name}
        </Typography>
        <Typography className={classes.title} variant="body2">
          {subtitle}
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.answerContainer}>
        {questionType === "MULTIPLE_CHOICE" && (
          <MultiChoice
            questionId={question.id}
            commentRequired={question.commentRequired}
            choices={question?.choices}
          />
        )}
        {questionType === "RETURN_DISTRIBUTION" && (
          <ReturnDistribution
            // commentRequired={question.commentRequired}
            questionId={question.id}
          />
        )}
        {questionType === "DOLLAR_ESTIMATE" && (
          <DollarEstimate
            questionId={question.id}
          />
        )}
        {questionType === "QUARTER" && (
          <FutureQuarter
            questionId={question.id}
          />
        )}
        {questionType === "TEXT" && (
          <FreeText questionId={question.id} skippable={question.skippable} />
        )}
      </Grid>
    </Box>
  );
});

export default FrameworkQuestion;
