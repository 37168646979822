import React, { useReducer } from "react";
import {
  CreditCard,
  PersonOutlined,
  Language,
  SystemUpdate,
  PersonAdd,
  LocalAtm,
  PersonAddDisabled,
} from "@material-ui/icons";
import { useQuery } from "react-query";
import {
  Box, Tooltip, Popover, CircularProgress, Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatMultiplierFromPercentage, formatTotal, formatBigDollars, formatPercentage,
} from "../../../utils/numberFormat";
import { get3MonthGrowth, getLastValue } from "../../../utils/calculateTimeSeriesMetrics";
import { KPICard } from "../KPICard";
import { ModelConfidenceBarChart } from "./ModelConfidenceBarChart";

const useStyles = makeStyles((theme) => ({
  chip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.neutrals["30"]}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.neutrals["80"],
    },
  },
  paper: {
    position: "absolute",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    minWidth: "320px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    boxShadow: theme.shadows[5],
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "override":
      return { ...state, ...action.state };
    default:
      return state;
  }
};

function renderDepartureText({
  name,
  linkedinUrl,
  previousJobTitle,
  currentCompanyName,
}) {
  return (
    <>
      {linkedinUrl ? (
        <a href={linkedinUrl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ) : (
        name
      )}

      {" has left"}
      {previousJobTitle && ` their job as ${previousJobTitle}`}
      {currentCompanyName > 0 && (
        ` for a job at ${currentCompanyName}.`
      )}
      .
    </>
  );
}

function renderHireText({
  name, linkedinUrl, newJobTitle, previousJobTitle, previousCompanyName,
}) {
  return (
    <>
      {linkedinUrl ? (
        <a href={linkedinUrl} target="_blank" rel="noreferrer">
          {name}
        </a>
      ) : (
        name
      )}
      {" has joined"}
      {newJobTitle && ` as ${newJobTitle}`}
      {previousCompanyName && (` from ${previousCompanyName}`)}
      {(previousJobTitle && previousCompanyName) && (` where they were ${previousJobTitle}`)}
      .
    </>
  );
}

export function SignalChips({
  signals = [], showIcon = true, showName = false, fetchSignal,
}) {
  const classes = useStyles();
  const [signalState, dispatchSignal] = useReducer(reducer, { signal: null, anchorEl: null });

  const { data: signalData = [], isLoading } = useQuery(
    ["Signal", signalState.signal?.signalId],
    () => fetchSignal(signalState.signal?.signalId),
    {
      enabled: !!signalState?.signal?.signalId,
    },
  );
  const getColor = (scoreFimoica) => {
    if (scoreFimoica <= 1) {
      return "red";
    }
    if (scoreFimoica > 1 && scoreFimoica <= 3) {
      return "#FFB52E";
    }
    return "#007500";
  };
  const signalMapping = {
    signal_fimoica_prediction: {
      name: "MOIC Model",
      icon: LocalAtm,
      tooltip: "A Valor ML Model that predicts MOIC within the next 3 years.",
      dataDisplay: ({ score }) => (
        <Typography variant="subtitle2" style={{ color: getColor(score) }}>
          {`${score.toFixed(2)}x`}
        </Typography>
      ),

      popover: ({ records }, { score }) => (
        <Box>
          <ModelConfidenceBarChart features={records} score={score} />
        </Box>
      ),
    },
    signal_second_measure_growth: {
      name: "Sales",
      icon: CreditCard,
      tooltip: "Indicates that normalized / seasonally adjusted yearly sales growth are greater than 1 absolute standard deviation.",
      dataDisplay: ({ value }) => <Typography variant="subtitle2">{formatMultiplierFromPercentage(value) ?? 0}</Typography>,
      popover: ({ records }) => (
        <KPICard
          title="Sales"
          metrics={[
            {
              label: "3 Month Growth",
              value: formatPercentage(get3MonthGrowth(records)),
            },
            {
              label: "Currently",
              value: formatBigDollars(getLastValue(records)),
            },
          ]}
          dataFormatter={formatBigDollars}
          data={records}
          keys={["sales"]}
        />
      ),
    },
    signal_employee_growth: {
      name: "Employee Headcount",
      icon: PersonOutlined,
      tooltip: "Indicates that normalized / seasonally adjusted yearly employee headcount growth is greater than 1 absolute standard deviation.",
      dataDisplay: ({ value }) => <Typography variant="subtitle2">{formatMultiplierFromPercentage(value) ?? 0}</Typography>,
      popover: ({ records }) => (
        <KPICard
          title="Employee Headcount"
          metrics={[
            {
              label: "3 Month Growth",
              value: formatPercentage(get3MonthGrowth(records)),
            },
            {
              label: "Currently",
              value: formatTotal(getLastValue(records)),
            },
          ]}
          dataFormatter={formatTotal}
          data={records}
          keys={["employee"]}
        />
      ),
    },
    signal_similarweb_growth: {
      name: "Website Traffic",
      icon: Language,
      tooltip: "Indicates that normalized / seasonally adjusted yearly web traffic growth are greater than 1 absolute standard deviation.",
      dataDisplay: ({ value }) => <Typography variant="subtitle2">{formatMultiplierFromPercentage(value) ?? 0}</Typography>,
      popover: ({ records }) => (
        <KPICard
          title="Web Traffic"
          metrics={[
            {
              label: "3 Month Growth",
              value: formatPercentage(get3MonthGrowth(records)),
            },
            {
              label: "Currently",
              value: formatTotal(getLastValue(records)),
            },
          ]}
          dataFormatter={formatTotal}
          data={records}
          keys={["webTraffic"]}
        />
      ),
    },
    signal_app_annie_growth: {
      name: "App Downloads",
      icon: SystemUpdate,
      tooltip: "Indicates that normalized / seasonally adjusted yearly app download growth are greater than 1 absolute standard deviation.",
      dataDisplay: ({ value }) => <Typography variant="subtitle2">{formatMultiplierFromPercentage(value) ?? 0}</Typography>,
      popover: ({ records }) => (
        <KPICard
          title="App Downloads"
          metrics={[
            {
              label: "3 Month Growth",
              value: formatPercentage(get3MonthGrowth(records)),
            },
            {
              label: "Currently",
              value: formatTotal(getLastValue(records)),
            },
          ]}
          dataFormatter={formatTotal}
          data={records}
          keys={["appDownloads"]}
        />
      ),
    },
    signal_executive_departure: {
      name: "Executive Departure",
      icon: PersonAddDisabled,
      tooltip: "Number of Executives who have recently departed.",
      dataDisplay: ({ score }) => <Typography variant="subtitle2">{score}</Typography>,
      popover: ({ records }) => (
        <Box display="flex" flexDirection="column">
          {records.map((record) => (
            renderDepartureText(record)))}
        </Box>
      ),
    },
    signal_executive_hire: {
      name: "Executive Hire",
      icon: PersonAdd,
      tooltip: "Number of Executives who have recently joined.",
      dataDisplay: ({ score }) => <Typography variant="subtitle2">{score}</Typography>,
      popover: ({ records }) => (
        <Box display="flex" flexDirection="column">
          {records.map((record) => (
            renderHireText(record)))}
        </Box>
      ),
    },
  };

  const renderChip = (signal, onClick) => {
    const { signalType, score, value } = signal;
    const {
      name, icon, dataDisplay, tooltip,
    } = signalMapping[signalType];
    const Icon = icon;
    return (
      <Tooltip enterDelay={1000} key={signalType} title={tooltip} placement="top">
        <Box className={classes.chip} onClick={(e) => onClick(e.currentTarget, signal)}>
          <span style={{
            display: "flex", paddingRight: "4px", alignItems: "center", justifyContent: "center",
          }}
          >
            {showIcon && Icon && <Icon />}
            {showName && name}
          </span>
          {dataDisplay({ value, score })}
        </Box>
      </Tooltip>
    );
  };

  const onSignalClick = (target, signal) => {
    dispatchSignal({
      type: "override",
      state: {
        signal,
        anchorEl: target,
      },
    });
  };

  return (
    <>
      <div style={{ display: "inline-flex", gap: "8px", flex: 1 }}>
        {signals.map((x) => (
          renderChip(x, onSignalClick)
        ))}

      </div>
      <Popover
        open={Boolean(signalState.signal)}
        anchorEl={signalState.anchorEl}
        className={classes.hoverPopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          paper: classes.paper,
        }}
        PaperProps={{
          onMouseLeave: () => dispatchSignal({ type: "override", state: { signal: null, anchorEl: null } }),
        }}
        onClose={() => {
          dispatchSignal({ type: "override", state: { signal: null, anchorEl: null } });
        }}
        disableRestoreFocus
      >
        {isLoading && <CircularProgress />}
        {signalState.signal?.signalType && !isLoading
            && signalMapping[signalState.signal.signalType].popover(signalData, signalState.signal)}

      </Popover>
    </>
  );
}

export default SignalChips;
