import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  Divider,
  Stepper,
  Step,
  StepButton,
  Theme,
  createStyles,
} from "@material-ui/core";
import { FileDropZone } from "../../atoms/FileDropZone";
import VerifyMarketMap from "../VerifyMarketMap";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },

}));

interface Props {
  verifyMarketMap: (file: any) => void
  uploadMarketMap: (map: any) => void
}

export function MarketMapWizard(props: Props) {
  const { verifyMarketMap, uploadMarketMap } = props;
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [verifyData, setVerifyData] = useState(null);
  const [mapId, setMapId] = useState(null);
  const steps = ["Upload CSV", "Verify and Submit Market Map", "Complete"];

  const submitFile = async (file) => {
    setBusy(true);
    const data = await verifyMarketMap(file);
    setBusy(false);
    setVerifyData(data);
    setActiveStep(1);
  };

  const uploadMapJson = async ({ title, segment, investmentCategory }) => {
    const { companies, dimensions } = verifyData;
    const data = {
      title,
      segment,
      investmentCategory,
      companies,
      dimensions,
    };

    const response = await uploadMarketMap(data);
    setMapId(response);
    setActiveStep(2);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FileDropZone
            title="Upload CSV"
            acceptString=".csv"
            multiple={false}
            onSubmit={submitFile}
            isBusy={busy}
          />
        );
      case 1:
        return (
          <VerifyMarketMap
            title={verifyData.title}
            noOfCompanies={verifyData.companies.length}
            onMapSubmit={uploadMapJson}
            onMapCancel={() => {
              setActiveStep(0);
            }}
            dimensions={verifyData.dimensions}
            badCompanies={verifyData.missingCompanies}
          />
        );
      case 2:
        return (
          <a
            style={{ textAlign: "center" }}
            href={`/market-maps/view/${mapId}`}
          >
            {mapId}
          </a>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Paper className={classes.root}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton completed={activeStep > index}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
      <Divider />
      <Box className={classes.instructions}>{getStepContent(activeStep)}</Box>
    </Paper>
  );
}

export default MarketMapWizard;
