import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Hidden,
  Grid,
  Toolbar,
  IconButton,
  AppBar,
  makeStyles,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { UserMenu, UserAvatar, CompanyAvatar } from "@valor-labs/marble";
import { getCompetitors } from "./api/Pofoco";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  navigationElement: {
    color: theme.palette.neutrals["100"],
    textDecoration: "none",
  },
  logoNav: {
    "&:hover": {
      border: "1px solid white",
      borderRadius: theme.shape.borderRadius,
    },
  },
  nav: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start",
    },
    minHeight: "100%",
    backgroundColor: theme.palette.blue.main,
  },
  logoContainer: {
    cursor: "pointer",
  },
  logo: {
    position: "relative",
    cssFloat: "left",
    height: "50px",
    padding: "5px",
  },
  searchBar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  userName: {
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
  },
  toolbarWrapper: {
    "& .MuiToolbar-gutters": {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

export function CohortHeader(props) {
  const {
    user,
    logOut,
    logoUrl,
  } = props;
  const classes = useStyles(props);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const { data: competitors = [] } = useQuery(
    ["Competitors", user?.valorId],
    async () => {
      if (!user?.valorId) return [];
      const response = await getCompetitors(user?.valorId);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
    },
  );
  return (
    <AppBar
      className={classes.appBar}
    >
      <Toolbar disableGutters>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Grid className={classes.gridItem} item xs={10} sm={2}>
            <Box className={classes.nav}>
              <Box className={classes.logoContainer} onClick={() => user && history.push("/")}>
                <img
                  className={classes.logo}
                  alt="Logo"
                  src={logoUrl}
                />
              </Box>
            </Box>
            <Box>
              {user && (
              <Link className={classes.navigationElement} to="/">
                <Typography variant="h6">DASHBOARD</Typography>
              </Link>
              )}
            </Box>
            {competitors?.map((co, idx) => (
              <Box
                key={co.valorId}
                id={`listed-competitor-${idx}`}
                className={classes.logoNav}
              >
                <Link to={`/org/${co.valorId}`}>
                  <CompanyAvatar src={co.logoUrl} name={co.name} size="48" />
                </Link>
              </Box>
            ))}
          </Grid>
          <Grid className={classes.gridItem} style={{ justifyContent: "flex-end" }} item xs={2}>
            {user && (
            <Box className={classes.userBox}>
              <Hidden mdDown>
                <Typography variant="subtitle1" className={classes.userName}>
                  {user?.name}
                </Typography>
              </Hidden>

              <IconButton onClick={handleClickUserMenu}>
                <UserAvatar user={user} />
              </IconButton>
              <UserMenu
                user={user}
                logOut={logOut}
                anchorEl={anchorEl}
                onClose={handleCloseUserMenu}
              />
            </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

CohortHeader.propTypes = {
  user: PropTypes.shape({
    valorId: PropTypes.string,
    name: PropTypes.string,
    profilePicture: PropTypes.string,
  }).isRequired,
  logOut: PropTypes.func.isRequired,
  logoUrl: PropTypes.string.isRequired,
};

export default CohortHeader;
