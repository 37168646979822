/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: theme.spacing(30),
    padding: theme.spacing(1),
    fontSize: ".875rem",
    backgroundColor: "#F8FDFF",
    border: `1px dashed ${theme.palette.neutrals["89"]}`,
    borderRadius: theme.shape.borderRadius,
  },
  iconBackground: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.secondary.highlight,
    borderRadius: "100%",
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "2.5rem",
  },
}));
export function UploadDropzone({ onDrop }) {
  const classes = useStyles();

  const onDropHandler = useCallback((acceptedFiles) => {
    onDrop(acceptedFiles);
  }, [onDrop]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHandler,
  });
  const { ref, ...rootProps } = getRootProps();

  return (
    <Box className={classes.dropZone} ref={ref} {...rootProps}>
      <input {...getInputProps()} />
      <Box className={classes.iconBackground}>
        <CloudUploadIcon className={classes.icon} />
      </Box>
      <Box>Drag and drop a file to upload, or click to browse</Box>
    </Box>
  );
}

UploadDropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
};
export default UploadDropzone;
