import React, { useState } from "react";
import {
  Box, Typography, Popover, Theme, createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CompanyAvatar from "../CompanyAvatar";

interface StyleProps {
  deEmphasize?: boolean;
}

interface Props {
  logoUrl?: string;
  id: string;
  onClick: (id: string)=> void;
  name: string;
  deEmphasize?: boolean;
  onMouseEnter?:(id: string)=> void;
  onMouseLeave?:(id: string)=> void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    justifySelf: "center",
    minWidth: "100%",
    overflow: "hidden",
    cursor: "pointer",
  },
  avatar: (props: StyleProps) => ({
    boxSizing: "border-box",
    margin: "8px",
    opacity: props.deEmphasize ? 0.7 : 1,
    filter: `blur(${props.deEmphasize ? 2 : 0}px)`,
    height: "100%",
    maxHeight: "100%",
    minWidth: "100%",
  }),
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(0.5),
  },
}));

export function MarketMapItem(props: Props) {
  const {
    logoUrl,
    name,
    id,
    onClick,
    deEmphasize = false,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;
  const classes = useStyles({ deEmphasize });
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);


  const handleKeyDown = (event) => {
    if (isHovered && event.shiftKey) {
      onMouseEnter(id);
    }
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };

  const handlePopoverClose = () => {
    onMouseLeave(id);
    setIsHovered(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      className={classes.root}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onKeyDown={handleKeyDown}
      onClick={() => id && onClick(id)}
    >
      <Box className={classes.avatar}>
        <CompanyAvatar src={logoUrl} name={name} loading="eager" />
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{name}</Typography>
      </Popover>
    </Box>
  );
}

MarketMapItem.defaultProps = {
  logoUrl: "",
  deEmphasize: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default MarketMapItem;
