import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  Box,
  ClickAwayListener,
  Typography,
  Chip,
  ButtonBase,
  Theme,
  createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import CollapseChecks from "../../atoms/CollapseChecks";
import useKeyPress from "../../../hooks/useKeyPress";

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    flexShrink: 0,
    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "95vw",
    },
  },
  drawerPaper: {
    display: "flex",
    flexDirection: "column",
    width: 400,
    [theme.breakpoints.down("xs")]: {
      width: "95vw",
    },
    padding: theme.spacing(10, 3, 3, 3),
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
  },
  drawerTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    color: theme.palette.neutrals["30"],
  },
  sectionTitle: {
    padding: theme.spacing(2, 0),
    color: theme.palette.neutrals["43"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
    textAlign: "left",
  },
  preset: {
    marginRight: theme.spacing(1),
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "14px",
    backgroundColor: theme.palette.neutrals["89"],
  },
}));

const presets = [
  {
    name: "Consumer",
    columns: ["employeeTotalYoyPct", "employeeTotalHistory"],
  },
  { name: "Defense", columns: ["employeeTotalHistory"] },
];

interface Props {
  open: boolean;
  allowPresets: boolean;
  onClose(): void;
  toggleColumn(id: string): void;
  toggleAll(toggle: boolean): void;
  columns: {
    parent?: string,
    children?: {
      id?: string,
      label?: string,
      value?: boolean
    }[]
  }[];
}
export function ColumnDrawer(props: Props) {
  const {
    open,
    onClose,
    columns,
    toggleColumn,
    toggleAll,
    allowPresets,
  } = props;
  const classes = useStyles();
  useKeyPress("Escape", () => onClose());

  const onCheckChange = (changedCol) => {
    const originalCol = columns.find((col) => col.parent === changedCol.parent);
    // Find the specific columns that were changed so that we can call toggleColumn on them
    const changedChildrenIds = originalCol.children
      .filter((originalChild) => changedCol.children.some(
        (changedChild) => changedChild.id === originalChild.id
            && changedChild.value !== originalChild.value,
      ))
      .map((children) => children.id);

    changedChildrenIds.forEach(async (id) => toggleColumn(id));
  };

  const onPresetClick = (preset) => {
    toggleAll(true);
    preset.columns.forEach(async (id) => toggleColumn(id));
  };
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={onClose}
    >
      <Drawer
        anchor="right"
        open={open}
        variant="persistent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box>
          <Box className={classes.drawerTitleContainer}>
            <Typography className={classes.title} variant="h6">
              Edit Columns
            </Typography>
            <ButtonBase onClick={onClose}>
              <Close />
            </ButtonBase>
          </Box>

          {allowPresets && (
            <Box>
              <Typography className={classes.sectionTitle}>
                COLUMN PRESETS
              </Typography>
              <Box>
                {presets.map((preset) => (
                  <Chip
                    className={classes.preset}
                    key={preset.name}
                    label={preset.name}
                    onClick={() => onPresetClick(preset)}
                  />
                ))}
              </Box>
            </Box>
          )}
          <Box>
            <Typography className={classes.sectionTitle}>
              ALL COLUMNS
            </Typography>
            <Box>
              {columns?.map((col, index) => (
                <CollapseChecks
                  key={col.parent}
                  checks={col}
                  onCheckChange={(data) => onCheckChange(data, index)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
}

ColumnDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  allowPresets: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toggleColumn: PropTypes.func.isRequired,
  toggleAll: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      parent: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.bool,
        }),
      ),
    }),
  ).isRequired,
};

export default ColumnDrawer;
