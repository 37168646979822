import * as firebase from "firebase/app";
import * as appCheck from "firebase/app-check";
import axios from "axios";
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import {
  setPersistence,
  signOut,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  browserLocalPersistence,
} from "firebase/auth";
import LogRocket from "logrocket";
import { getUserData } from "../api/Pofoco";
import config from "../config";

const app = firebase.initializeApp(config.firebase);

const { siteKey } = config.captcha;

if (siteKey) {
  appCheck.initializeAppCheck(app, {
    provider: new appCheck.ReCaptchaV3Provider(siteKey),
    isTokenAutoRefreshEnabled: true,
  });
}

const authContext = createContext();

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [loginError, setError] = useState(null);

  const auth = getAuth();

  const fetchUser = async () => {
    const response = await getUserData();
    setUser(response);
    return response;
  };

  const signIn = async (email, pass) => {
    setError(null);
    await setPersistence(auth, browserLocalPersistence);
    try {
      await signInWithEmailAndPassword(auth, email, pass);
      // Here fetch data from database. but for now
      await fetchUser();
      setIsAuthenticated(true);
      setLoading(false);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;

      switch (errorCode) {
        case "auth/invalid-email":
          setError("INVALID_EMAIL");
          break;
        default:
          setError("INVALID_INFORMATION");
          console.log(errorMessage);
      }
      throw error;
    }
  };

  const logOut = async () => {
    setLoading(true);
    setError(null);
    setUser(null);
    setIsAuthenticated(false);
    await signOut(auth);
    setLoading(false);
    return {};
  };

  useEffect(() => {
    axios.interceptors.request.use(async (requestConfig) => {
      if (
        requestConfig.url.startsWith("/api/")
      ) {
        const modifiedConfig = { ...requestConfig };
        modifiedConfig.baseURL = config.api.ingress;
        const idToken = await auth.currentUser.getIdToken();
        modifiedConfig.headers.common.Authorization = `Bearer ${idToken}`;
        return modifiedConfig;
      }
      return requestConfig;
    });
  }, [auth.currentUser]);

  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (savedUser) => {
      if (savedUser) {
        const res = await fetchUser();
        setIsAuthenticated(true);
        LogRocket.identify(res.id, {
          email: res?.email,
          valorId: res.valorId,
        });
      }
      setLoading(false);
    });

    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    loginError,
    signIn,
    logOut,
    isAuthenticated,
    isLoading,
  };
}

// eslint-disable-next-line react/prop-types
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  // eslint-disable-next-line react/jsx-filename-extension
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);
