/**
 * Capitalize firs letter of string
 * E.g. 'chicago' => 'Chicago'
 * @param  {String} str
 * @return {String}
 */
export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Parse string and concat first letters
 * E.g. 'Chicago Trading Company' => 'CTC'
 * @param  {String} value The string to be parsed and concated.
 * @return {String}       The concated first letters.
 */
export const concatFirstLetters = (value) => value
  .split(" ")
  .map((word) => word[0])
  .join("");

/**
 * Parse name and return initials
 * E.g. 'Clark Kent' => 'CK'
 * @param  {String} value
 * @return {String}
 */
export const getInitials = (name) => name
  ?.split(" ")
  .map((word) => word[0])
  .join("");

/**
 * Check if value is string or number
 * @param  {String} value
 * @return {Bool}
 */
export function isStringOrNumber(value) {
  return (
    typeof value === "string"
    || value instanceof String
    || typeof value === "number"
    || value instanceof Number
  );
}

const isObject = (obj) => {
  const type = typeof obj;
  return type === "function" || (type === "object" && !!obj);
};

export const getKey = (x) => {
  if (!x) {
    return x
  }

  return (isObject(x) && "key" in x ? x.key : x);
}

export const getLabel = (x) => {
  if (!x) {
    return x
  }
  if (isObject(x) && "label" in x) {
    return x.label;
  }
  return typeof x === "number" ? x : capitalizeFirstLetter(x);
};

export const searchParams = (params) => new URLSearchParams(
  Object.fromEntries(Object.entries(params).filter(([, v]) => v != null)),
).toString();

export default {
  capitalizeFirstLetter,
  concatFirstLetters,
  getInitials,
  isStringOrNumber,
  getKey,
  getLabel,
  searchParams,
};
