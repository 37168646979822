/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Card, Typography, Box } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import {
  ComposedChart,
  Bar,
  YAxis,
  Legend,
  XAxis,
  Line,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { titleCase, hexToRgb, formatTotal } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: theme.shadows[1],
  },
  card: {
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    color: theme.palette.neutrals["30"],
    fontSize: "1rem",
  },
  label: {
    paddingRight: theme.spacing(0.5),
    color: theme.palette.neutrals["30"],
    fontSize: ".875rem",
    textAlign: "left",
  },
  value: {
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: ".875rem",
  },
  employeeTotal: {
    color: theme.palette.neutrals["30"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1rem",
  },
}));

export function CustomToolTip(props) {
  const {
    active, payload, barNames, hoveredBar,
  } = props;
  const classes = useStyles();
  if (active && payload && payload.length) {
    const data = payload[0]?.payload;
    const { month, bars, total } = data;
    const counts = barNames
      .filter((barKey) => Boolean(bars[barKey]))
      .map((barKey) => (
        <Box key={barKey} className={classes.container}>
          <Box
            style={{
              width: 16,
              height: 16,
              marginRight: 8,
              backgroundColor:
                hoveredBar?.name === barKey ? hoveredBar.color : "transparent",
            }}
          />
          <Typography
            className={classes.label}
            style={{ fontWeight: barKey === hoveredBar?.name ? 700 : null }}
          >
            {titleCase(barKey)}
            : &nbsp;
          </Typography>
          <Typography className={classes.value}>
            {formatTotal(bars[barKey] || 0)}
          </Typography>
        </Box>
      ));

    const orderedCounts = [...counts].map(counts.pop, counts);
    if (orderedCounts.length === 0 && !Number.isInteger(total)) {
      return null;
    }
    return (
      <Card className={classes.card}>
        <Typography className={classes.title}>{month}</Typography>
        <Box display="flex" flexDirection="column">
          {orderedCounts}
          {Number.isInteger(total) && (
            <Box
              className={classes.container}
              style={{ borderTop: "1px solid #4D4D4D" }}
            >
              <Typography className={classes.employeeTotal}>Total: </Typography>
              <Typography className={classes.employeeTotal}>
                {formatTotal(total)}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    );
  }
  return null;
}

export function StackedBarWithTrendLine(props) {
  const {
    data, showTrendLine, barNames, showLegend, color,
  } = props;
  // TODO prop Types
  const theme = useTheme();

  const [hoveredBar, setHoveredBar] = useState(null);

  const handleBarHover = (bar, barColor) => {
    setHoveredBar({ name: bar, color: barColor });
  };

  const handleBarHoverLeave = () => {
    setHoveredBar(null);
  };

  let chartColors = [];
  if (color === "brand") {
    chartColors = theme.palette.brandOrder.map(
      (colorName) => theme.palette[colorName].main,
    );
  } else {
    const hex = theme.palette[color].main;
    const rgbString = hexToRgb(hex);
    chartColors = barNames.map(
      (_, idx) => `rgba(${rgbString}, ${1 - (1 / barNames.length) * idx})`,
    );
  }

  const renderLegendText = (value) => (
    <span
      style={{
        color: theme.palette.neutrals["56"],
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: "1rem",
      }}
    >
      {value}
    </span>
  );

  const preppedData = data.map((x) => ({
    ...x,
    month: dayjs(x.month).format("MMM 'YY"),
  }));

  const getVal = (dataPoint, key) => dataPoint.bars[key] || 0;

  const bars = barNames.map((bar, idx) => {
    const barColor = chartColors[idx % chartColors.length];
    return (
      <Bar
        key={bar}
        name={titleCase(bar)}
        dataKey={(x) => getVal(x, bar)}
        stackId="a"
        fill={barColor}
        onMouseEnter={() => handleBarHover(bar, barColor)}
        onMouseLeave={handleBarHoverLeave}
      />
    );
  });

  return (
    <ResponsiveContainer height={600} width="100%">
      <ComposedChart
        throttleDelay={400}
        data={preppedData}
        margin={{
          top: 5, right: 5, bottom: 0, left: 35,
        }}
      >
        <CartesianGrid stroke={theme.palette.neutrals["96"]} vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          axisLine={false}
          tick={{ fill: theme.palette.neutrals["56"], fontSize: 14 }}
        />
        <YAxis
          orientation="right"
          type="number"
          allowDecimals={false}
          domain={["dataMin", "dataMax"]}
          tickLine={false}
          axisLine={false}
          tick={{ fill: theme.palette.neutrals["56"], fontSize: 14 }}
        />
        <Tooltip
          content={
            <CustomToolTip barNames={barNames} hoveredBar={hoveredBar} />
          }
        />
        {showLegend && <Legend formatter={renderLegendText} />}
        {bars}
        {showTrendLine && (
          <Line
            name="Total"
            dataKey="total"
            type="linear"
            stroke={theme.palette.purple.main}
            dot={false}
            strokeDasharray="4 4"
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

StackedBarWithTrendLine.defaultProps = {
  showTrendLine: true,
  showLegend: true,
  color: "brand",
};

export default StackedBarWithTrendLine;
