import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

interface Props {
  filled: number;
  height?: number;
  color: string;
}

const useStyles = makeStyles(() => createStyles({
  container: (props: Props) => ({
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: "100px",
    width: "100%",
    height: props.height,
  }),
  filler: (props: Props) => ({
    borderRadius: "100px",
    height: props.height,
    width: `${props.filled}%`,
    background: props.color,
  }),
}));

export function FillingBar({ filled, height = 10, color }: Props) {
  const classes = useStyles({ filled, height, color });
  return (
    <div className={classes.container}>
      <div className={classes.filler} />
    </div>
  );
}
export default FillingBar;
