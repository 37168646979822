import React from "react";
import {
  makeStyles, useTheme, Theme, createStyles,
} from "@material-ui/core/styles";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import Signal from "../../atoms/Icons/Signal";

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    overflow: "hidden",
    border: `1px solid ${theme.palette.neutrals["56"]}`,
    borderRadius: theme.shape.borderRadius,
    "& > button:not(:last-child)": {
      borderRight: `1px solid ${theme.palette.neutrals["56"]}`,
    },
  },
  button: {
    width: "33%",
    padding: theme.spacing(1, 2),
  },
  title: {
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.neutrals["56"],
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
  },
}));

interface Props {
  value: number[];
  disabled: boolean
  onChange: (value: number[]) => void;
}

export function SignalFilter(props: Props) {
  const { onChange, disabled = false, value = [] } = props;
  const theme = useTheme();
  const classes = useStyles();
  const choices = [1, 2, 3];
  const handleClick = (bars) => {
    let newValue = [];
    if (value.indexOf(bars) === -1) {
      newValue = [...value, bars];
    } else {
      newValue = value.filter((x) => x !== bars);
    }
    onChange(newValue);
  };
  return (
    <Box>
      <Typography className={classes.title}>SIGNAL SCORE</Typography>
      <Box className={classes.buttonContainer}>
        {choices.map((x) => (
          <ButtonBase
            key={x}
            className={classes.button}
            disabled={disabled}
            style={{
              backgroundColor:
                value.indexOf(x) !== -1 && theme.palette.green.highlight,
            }}
            onClick={() => handleClick(x)}
          >
            <Signal bars={x} />
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
}

export default SignalFilter;
