/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { useDropzone } from "react-dropzone";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: ({ color }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1.5),
    backgroundColor: theme.palette[color].main,
    color: theme.palette.getContrastText(theme.palette[color].main),
    height: theme.spacing(5),
    width: theme.spacing(14),
  }),
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

export function UploadFileButton({ onSubmit, color, multiple }) {
  const classes = useStyles({ color });

  const onDrop = useCallback((acceptedFiles) => {
    onSubmit(acceptedFiles);
  }, [onSubmit]);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
  });

  const { ref, ...rootProps } = getRootProps();
  return (
    <div ref={ref} {...rootProps} onClick={(e) => e.stopPropagation()}>
      <ButtonBase
        className={classes.root}
        onClick={() => inputRef.current.click()}
      >
        <CloudUploadOutlinedIcon className={classes.icon} />
        UPLOAD
      </ButtonBase>
      <input {...getInputProps()} multiple={multiple} />
    </div>
  );
}

UploadFileButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};
UploadFileButton.defaultProps = {
  multiple: false,
};

export default UploadFileButton;
