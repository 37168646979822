/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useField } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Theme,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  select: {
    width: theme.spacing(32),
  },
}));

interface Props {
  questionId: string,
}

export function FutureQuarter(props: Props) {
  const { questionId } = props;
  const classes = useStyles();

  const required = (v: string) => (v ? undefined : "Required");
  const { input, meta } = useField(`${questionId}.commentary`, { validate: required });

  const currYear = new Date().getFullYear();
  const getRange = (min: number, max: number) => (
    Array.from({ length: max - min + 1 }, (_, i) => min + i)
  );
  const years = getRange(currYear, currYear + 3);
  const quarters = ["Q1", "Q2", "Q3", "Q4"];

  return (
    <Grid item xs={12}>
      <FormControl variant="filled" className={classes.select} error={meta?.submitFailed && meta?.error}>
        <InputLabel data-formstate={(meta?.error) ? "error" : ""}>{(meta?.submitFailed && meta?.error) ? meta?.error : "Fiscal Quarter"}</InputLabel>
        <Select
          {...input}
        >
          {/* Currently this QUARTER question type is only used to ask about rounds */}
          <MenuItem value="No New Round Required/Expected">
            No New Round Required/Expected
          </MenuItem>
          {years.flatMap((year) => (
            quarters.map((quarter) => (
              <MenuItem key={`${year}-${quarter}`} value={`${year}-${quarter}`}>
                {`${year}-${quarter}`}
              </MenuItem>
            ))
          ))}
        </Select>

      </FormControl>

    </Grid>
  );
}

export default FutureQuarter;
